import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { ExchangeRateAlert, ExchangeRateAlertPropTypes } from '../../dataTypes';
import redAlertImg from './images/alert-red.svg';
import blueAlertImg from './images/alert-blue.svg';
import notificationImg from './images/notification.svg';
import deleteImg from './images/delete.svg';
import useDeleteExchangeRateAlertAPI from '../../hooks/api/exchange_rates/useDeleteExchangeRateAlertAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';

type Props = {
  className?: string;
  item: ExchangeRateAlert;
  showDeleteButton: boolean;
  alertColor: string;
};

const Container = styled.div<{ isAlerted: boolean, alertColor: string }>`
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 16px;
  background: #fff;
  border: 1px solid ${(props) => ((props.isAlerted && props.alertColor === 'red') ? '#EB5757' : '#01AAC1')};
  border-radius: 10px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 20px;
`;

const NotificationIcon = styled(Icon)`
  background: center/contain no-repeat url(${notificationImg});
`;

const AlertIcon = styled(Icon)<{ alertColor: string }>`
  background: center/contain no-repeat url(${(props) => (props.alertColor === 'red' ? redAlertImg : blueAlertImg)});
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Amount = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
`;

const Rate = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
  opacity: 0.4;
`;

const DeleteButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background: center/contain no-repeat url(${deleteImg});
  cursor: pointer;
`;

const ExchangeRateAlertItem: React.FC<Props> = ({
  className,
  item,
  showDeleteButton,
  alertColor,
}) => {
  const {
    base,
    base_amount: baseAmount,
    is_alerted: isAlerted,
    rate,
    target,
    target_amount: targetAmount,
    uid,
  } = item;

  const { mutateAsync: deleteExchangeRateAlertAPI } = useDeleteExchangeRateAlertAPI(uid);
  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  const onDeleteClick = async () => {
    try {
      await deleteExchangeRateAlertAPI();
      enqueueGeneralSuccessSnackbar();
    } catch (err) {
      enqueueGeneralErrorSnackbar();
      throw err;
    }
  };

  return (
    <Container className={className} isAlerted={isAlerted} alertColor={alertColor}>
      {isAlerted ? <AlertIcon alertColor={alertColor} /> : <NotificationIcon />}
      <TextContainer>
        <Amount>
          {baseAmount}
          {' '}
          {base}
          {' '}
          =
          {' '}
          {targetAmount}
          {' '}
          {target}
        </Amount>
        <Rate>
          1
          {' '}
          {base}
          {' '}
          =
          {' '}
          {rate.toFixed(4)}
          {' '}
          {target}
        </Rate>
      </TextContainer>
      {showDeleteButton && <DeleteButton onClick={onDeleteClick} />}
    </Container>
  );
};

ExchangeRateAlertItem.defaultProps = {
  className: null,
};

ExchangeRateAlertItem.propTypes = {
  className: PropTypes.string,
  item: ExchangeRateAlertPropTypes.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  alertColor: PropTypes.string.isRequired,
};

export default ExchangeRateAlertItem;
