import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import useGetCallingNotesAPI, { FilterType, RankByType } from '../../hooks/api/calling-notes/useGetCallingNotesAPI';
import InfiniteScroll from '../common/InfiniteScroll';
import NoteCard from './NoteCard';
import { Note } from '../../dataTypes';
import NoteFilter from './NoteFilter';
import NoteRankBy from './NoteRankBy';
import AddButton from '../common/AddButton';
import AddOrEditNoteModal from './AddOrEditNoteModal';
import { getArrayFromInfiniteData } from '../../util/api';
import { HEADER_BAR_HEIGHT } from '../layouts/header-bar/HeaderBar';

const Header = styled.div`
  position: sticky;
  top: ${HEADER_BAR_HEIGHT}px;
  z-index: 1;
  background: #f1f1f1;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const RankByContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DateContainer = styled.div`
  & > * {
    margin-bottom: 12px;
  }
`;

const Date = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
  margin: 12px 0;
`;

const StyledNoteCard = styled(NoteCard)`
  margin: 12px 0;
`;

interface IProps {
  className?: string;
  clientUid?: string;
  contactUid?: string;
  initialFilter?: FilterType;
  initialRankBy?: RankByType;
  showHeader?: boolean;
  groupByDay?: boolean;
  observeBrowserViewport?: boolean;
}

const NoteList: React.FC<IProps> = ({
  className,
  clientUid,
  contactUid,
  initialFilter,
  initialRankBy,
  showHeader,
  groupByDay,
  observeBrowserViewport,
}) => {
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = React.useState(false);

  const [filter, setFilter] = React.useState<FilterType>(initialFilter);
  const [rankBy, setRankBy] = React.useState<RankByType>(initialRankBy);

  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useGetCallingNotesAPI({
    filter,
    rankBy,
    client: clientUid,
    contact: contactUid,
  });

  const notes = getArrayFromInfiniteData(data);

  const notesByDay: {[key: string]: Note[]} = {};
  notes.forEach((note) => {
    const date = dayjs(note.due_dt);
    const startOfDayISOString = date.startOf('day').toISOString();
    if (notesByDay[startOfDayISOString]) {
      notesByDay[startOfDayISOString].push(note);
    } else {
      notesByDay[startOfDayISOString] = [note];
    }
  });

  return (
    <>
      {
        showHeader && (
          <Header>
            <FilterContainer>
              <NoteFilter value={filter} onChange={setFilter} />
              <AddButton onClick={() => setIsAddNoteModalOpen(true)} />
            </FilterContainer>
            <RankByContainer>
              <NoteRankBy value={rankBy} onChange={setRankBy} />
            </RankByContainer>
          </Header>
        )
      }
      <InfiniteScroll
        className={className}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        status={status}
        isEmpty={groupByDay ? Object.keys(notesByDay).length === 0 : notes.length === 0}
        observeBrowserViewport={observeBrowserViewport}
      >
        { groupByDay ? (
          Object.keys(notesByDay).map((dayISOString) => {
            return (
              <DateContainer key={dayISOString}>
                {/* TODO: i18n */}
                <Date>{dayjs(dayISOString).format('ddd, MMM D')}</Date>
                {
                  notesByDay[dayISOString].map((note) => {
                    return (
                      <StyledNoteCard key={note.uid} note={note} />
                    );
                  })
                }
              </DateContainer>
            );
          })
        ) : (
          notes.map((note) => {
            return (
              <StyledNoteCard key={note.uid} note={note} />
            );
          })
        )}
      </InfiniteScroll>
      <AddOrEditNoteModal
        isOpen={isAddNoteModalOpen}
        onRequestClose={() => setIsAddNoteModalOpen(false)}
      />
    </>
  );
};

NoteList.defaultProps = {
  className: null,
  clientUid: null,
  contactUid: null,
  initialFilter: 'my',
  initialRankBy: 'create_dt_dsc',
  showHeader: true,
  groupByDay: true,
  observeBrowserViewport: false,
};

NoteList.propTypes = {
  className: PropTypes.string,
  clientUid: PropTypes.string,
  contactUid: PropTypes.string,
  initialFilter: PropTypes.oneOf(['my', 'all', 'ai']),
  initialRankBy: PropTypes.oneOf(['create_dt_asc', 'create_dt_dsc']),
  showHeader: PropTypes.bool,
  groupByDay: PropTypes.bool,
  observeBrowserViewport: PropTypes.bool,
};

export default NoteList;
