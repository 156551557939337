import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import useGetLatestExchangeRateAPI from '../../hooks/api/exchange_rates/useGetLatestExchangeRatesAPI';
import Loading from '../common/status/Loading';
import GeneralError from '../common/status/GeneralError';
import ExchangeRateInput from './ExchangeRateInput';

type Props = {
  className?: string;
};

const Container = styled.div``;

const LatestExchangeRate: React.FC<Props> = ({
  className,
}) => {
  const { data, status, refetch } = useGetLatestExchangeRateAPI();

  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'error') {
    return <GeneralError onButtonClick={() => refetch()} />;
  }

  return (
    <Container className={className}>
      <ExchangeRateInput USD="1" JPY={parseFloat(data?.rates?.JPY).toFixed(3)} readOnly />
    </Container>
  );
};

LatestExchangeRate.defaultProps = {
  className: null,
};

LatestExchangeRate.propTypes = {
  className: PropTypes.string,
};

export default LatestExchangeRate;
