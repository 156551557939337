import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';
import Label from '../common/form-modal/Label';
import Input from '../common/form-modal/Input';
import useResetPasswordAPI from '../../hooks/api/account/useResetPasswordAPI';
import Loading from '../common/status/Loading';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';

const StyledModal = styled(Modal)`
  width: 500px;
`;

const Body = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 48px 40px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 30px;
  line-height: 43px;
  color: #1B262C;
  margin: 0 0 4px;
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  opacity: 0.6;
  margin: 0 0 20px;
`;

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: 4px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 100%;
  height: 56px;
  background: linear-gradient(90deg, #01AAC1 0%, #02E1FF 100%);
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

type ResetPageProps = {
  onSuccess: () => void;
}

const ResetPage: React.FC<ResetPageProps> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const { mutateAsync: resetPasswordAPI, status } = useResetPasswordAPI();
  const { enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  if (status === 'loading') {
    return <Loading />;
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!email) return;
      await resetPasswordAPI({ email });
      onSuccess();
    } catch (err) {
      enqueueGeneralErrorSnackbar();
      throw err;
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Title>
        {t('forget_password_modal.reset_password_title')}
      </Title>
      <Desc>
        {t('forget_password_modal.reset_password_desc')}
      </Desc>
      <StyledLabel required>
        {t('common.email')}
      </StyledLabel>
      <StyledInput
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button type="submit" disabled={!email}>
        {t('common.send')}
      </Button>
    </form>
  );
};

ResetPage.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

type SuccessPageProps = {
  onButtonClick: () => void;
}

const SuccessPage: React.FC<SuccessPageProps> = ({
  onButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Title>
        {t('forget_password_modal.sent_reset_email_title')}
      </Title>
      <Desc>
        {t('forget_password_modal.sent_reset_email_desc')}
      </Desc>
      <Button type="button" onClick={onButtonClick}>
        {t('common.done')}
      </Button>
    </>
  );
};

SuccessPage.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
};

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ForgetPasswordModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  const [page, setPage] = React.useState<'reset'|'success'>('reset');

  React.useEffect(() => {
    if (!isOpen) setPage('reset');
  }, [isOpen]);

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Body>
        { page === 'reset' && <ResetPage onSuccess={() => setPage('success')} /> }
        { page === 'success' && <SuccessPage onButtonClick={onRequestClose} /> }
      </Body>
    </StyledModal>
  );
};

ForgetPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ForgetPasswordModal;
