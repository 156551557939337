import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { Contact, LifeCycleStage } from '../../../dataTypes';

export type FilterType = 'my' | 'all' | 'ai';
export type RankByType = 'company_name' | 'first_name' | 'last_name' | 'last_touch_dt' | 'create_dt_dsc' | 'ai';
export type LifeCycleOwnerType = 'all' | 'my';

interface Query {
  isPersonal: boolean;
  filter: FilterType;
  rankBy: RankByType;
  lifeCycleStages?: LifeCycleStage[];
  lifeCycleOwner?: LifeCycleOwnerType;
  client?: string;
}

const useGetContactsAPI = (query: Query) => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q: any = {
      is_personal: query.isPersonal,
      filter: query.filter,
      rankby: query.rankBy,
      per_page: 10,
      page: pageParam,
    };

    if (query.lifeCycleStages?.length > 0) {
      q.life_cycle_stages = query.lifeCycleStages.join(',');
    }

    if (query.lifeCycleOwner) {
      q.life_cycle_owner = query.lifeCycleOwner;
    }

    if (query.client) {
      q.client = query.client;
    }

    const queryStr = QueryString.stringify(q);

    const res = await api.get(`/contacts?${queryStr}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<Contact>>(
    ['contacts', query.isPersonal, query.filter, query.rankBy, query.lifeCycleStages, query.lifeCycleOwner, query.client],
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
    },
  );
};

export default useGetContactsAPI;
