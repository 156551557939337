import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { debounce } from 'lodash';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Loading from './status/Loading';
import GeneralError from './status/GeneralError';
import NoData from './status/NoData';

type Props = {
  className?: string;
  children: React.ReactNode;
  fetchNextPage: () => any,
  hasNextPage: boolean,
  isFetchingNextPage: boolean;
  status: string;
  isEmpty: boolean;
  showLoading?: boolean;
  showNoData?: boolean;
  observeBrowserViewport?: boolean;
}

const Container = styled.div`
  overflow: auto;
  overscroll-behavior: contain;
  // Set default width and height to make this component scrollable
  max-width: 100%;
  max-height: 100%;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const ListEnd = styled.div`
  height: 1px;
  margin: 0 !important;
`;

const InfiniteScroll: React.FC<Props> = ({
  className,
  children,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  status,
  isEmpty,
  showLoading,
  showNoData,
  observeBrowserViewport,
}) => {
  const listRef = React.createRef<HTMLDivElement>();
  const listEndRef = React.createRef<HTMLDivElement>();

  useIntersectionObserver({
    root: observeBrowserViewport ? null : listRef,
    target: listEndRef,
    rootMargin: '200%',
    onIntersect: debounce(fetchNextPage, 500),
    enabled: hasNextPage,
  });

  if (status === 'loading' && showLoading) {
    return (
      <Container className={className}>
        <StatusContainer>
          <Loading />
        </StatusContainer>
      </Container>
    );
  }

  if (status !== 'error' && !isFetchingNextPage && isEmpty && showNoData) {
    return (
      <Container className={className}>
        <StatusContainer>
          <NoData />
        </StatusContainer>
      </Container>
    );
  }

  return (
    <Container className={className} ref={listRef}>
      { children }
      { isFetchingNextPage && <Loading /> }
      { status === 'error' && <GeneralError onButtonClick={fetchNextPage} /> }
      <ListEnd ref={listEndRef} />
    </Container>
  );
};

InfiniteScroll.defaultProps = {
  className: null,
  hasNextPage: false,
  showLoading: true,
  showNoData: true,
  observeBrowserViewport: false,
};

InfiniteScroll.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool,
  showNoData: PropTypes.bool,
  observeBrowserViewport: PropTypes.bool,
};

export default InfiniteScroll;
