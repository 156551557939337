import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import stockArrowUp from '../../images/stock-arrow-up.svg';
import stockArrowDown from '../../images/stock-arrow-down.svg';

type Param = {
  className?: string;
  priceChange: number;
  secCode: string;
  price: number;
  priceChangePercent: number,
}

const StockPriceContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
`;

const StockPriceArrowUp = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  background: center/contain no-repeat url(${stockArrowUp});
`;

const StockPriceArrowDown = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  background: center/contain no-repeat url(${stockArrowDown});
`;

interface StockPriceProps {
  plus?: boolean;
  minus?: boolean;
}

const PriceChange = styled.span<StockPriceProps>`
  margin-left: 0.3em;
  ${(props) => props.plus && 'color: #6FCF97;'}
  ${(props) => props.minus && 'color: #EB5757;'}
`;

const StockPrice: React.FC<Param> = ({
  className,
  price,
  priceChange,
  priceChangePercent,
  secCode,
}) => {
  return (
    <StockPriceContainer className={className}>
      {priceChange > 0 && <StockPriceArrowUp />}
      {priceChange < 0 && <StockPriceArrowDown />}
      [
      {secCode}
      ]
      {' '}
      {price}
      <PriceChange plus={priceChange > 0} minus={priceChange < 0}>
        {priceChange >= 0 && '+'}
        {priceChange.toFixed(2)}
        {' '}
        (
        {priceChangePercent >= 0 && '+'}
        {priceChangePercent.toFixed(2)}
        %)
      </PriceChange>
    </StockPriceContainer>
  );
};

StockPrice.defaultProps = {
  className: null,
};

StockPrice.propTypes = {
  className: PropTypes.string,
  price: PropTypes.number.isRequired,
  priceChange: PropTypes.number.isRequired,
  priceChangePercent: PropTypes.number.isRequired,
  secCode: PropTypes.string.isRequired,
};

export default StockPrice;
