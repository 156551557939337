import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TitledModal from '../common/TitledModal';
import { Client, CompanyPropTypes } from '../../dataTypes';
import noteImg from './images/modal-note.svg';
import insightImg from './images/modal-insight.svg';
import contactImg from './images/modal-contact.svg';
import phoneImg from './images/modal-phone.svg';
import locationImg from './images/modal-location.svg';
import websiteImg from './images/modal-website.svg';
import chevronLeftImg from './images/modal-chevron-left.svg';
import useGetNewsfeedsAPI from '../../hooks/api/clients/useGetNewsfeedsAPI';
import NewsfeedList from './NewsfeedList';
import editImg from './images/edit.svg';
import AddOrEditCompanyModal from './AddOrEditCompanyModal';
import ContactList from '../contacts/ContactList';
import NoteList from '../notes/NoteList';
import useGetCallingNotesAPI from '../../hooks/api/calling-notes/useGetCallingNotesAPI';
import Card from '../common/Card';
import { getCardHoverStyles } from '../../util/styles';
import Loading from '../common/status/Loading';
import GeneralError from '../common/status/GeneralError';

const TitleCard = styled(Card)`
  position: relative;
  padding: 12px;
  margin-bottom: 12px;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 12px;
  object-fit: contain;
`;

const Name = styled.h1`
  line-height: 24px;
  font-size: 18px;
  color: #1B262C;
  margin: 0;
`;

const IndustryContainer = styled.div`
  font-size: 12px;
  color: #1B262C;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div`
  color: #01AAC1;
`;

const EditButton = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
  background: center/contain no-repeat url(${editImg});
  cursor: pointer;
`;

const TabRow = styled.div`
  display: flex;
  margin-bottom: 12px;
  filter: drop-shadow(0px 3px 5px rgba(0, 100, 159, 0.08));
`;

const TabContainer = styled.div`
  flex: 1;
  text-align: center;
  padding: 12px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 12px;
  }
  &:hover {
    background: rgba(1, 170, 193, 0.1);
  }
`;

const TabIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
`;

const TabTitle = styled.div`
  font-size: 12px;
  color: #1B262C;
  opacity: 0.6;
  margin-bottom: 4px;
`;

const TabNumber = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #1B262C;
`;

type TabProps = {
  iconSrc: string;
  title: string;
  number: number;
  onClick?: () => void;
}

const TabCard: React.FC<TabProps> = ({
  iconSrc,
  title,
  number,
  onClick,
}) => {
  return (
    <TabContainer onClick={onClick}>
      <TabIcon src={iconSrc} />
      <TabTitle>{title}</TabTitle>
      <TabNumber>{number}</TabNumber>
    </TabContainer>
  );
};

TabCard.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const DescCard = styled(Card)`
  padding: 12px 20px;
`;

const DescIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const DescText = styled.div`
  margin-left: 8px;
`;

const DescItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  color: #1B262C;
  margin-bottom: 8px;
`;

type DescItemProps = {
  iconSrc: string;
  text?: string;
  url?: string;
  phone?: string;
}

const DescItem: React.FC<DescItemProps> = ({
  iconSrc,
  text,
  url,
  phone,
}) => {
  let desc;
  if (url) {
    desc = <a href={url} target="_blank" rel="noreferrer"><DescText>{url}</DescText></a>;
  } else if (phone) {
    desc = <a href={`tel://${phone}`}><DescText>{phone}</DescText></a>;
  } else {
    desc = <DescText>{text}</DescText>;
  }

  return (
    <DescItemContainer>
      <DescIcon src={iconSrc} />
      {desc}
    </DescItemContainer>
  );
};

DescItem.defaultProps = {
  text: null,
  url: null,
  phone: null,
};

DescItem.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  text: PropTypes.string,
  url: PropTypes.string,
  phone: PropTypes.string,
};

const DescTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #1B262C;
  margin-bottom: 12px;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
`;

const BackButton = styled.div`
  display: inline-flex;
  align-items: center;
  height: 48px;
  background: #FFFFFF;
  border-radius: 10px;
  font-size: 12px;
  color: #1B262C;
  margin-bottom: 8px;
  padding: 0 16px 0 8px;
  cursor: pointer;

  &:hover {
    ${getCardHoverStyles()}
  }

  &::before {
    width: 24px;
    height: 24px;
    display: block;
    content: '';
    background-image: url(${chevronLeftImg});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const StyledNoteList = styled(NoteList)`
  max-height: 75vh; /* a workaround to make infinite scroll work */
`;

const StyledNewsfeedList = styled(NewsfeedList)`
  max-height: 75vh; /* a workaround to make infinite scroll work */
`;

const StyledContactList = styled(ContactList)`
  max-height: 75vh; /* a workaround to make infinite scroll work */
`;

type Props = {
  isOpen: boolean;
  onRequestClose: () => any;
  client: Client;
  status?: string;
}

const CompanyModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  client,
  status,
}) => {
  const { t } = useTranslation();

  const { data: newsfeedsData, status: newsfeedsStatus } = useGetNewsfeedsAPI(client?.uid, { enabled: Boolean(isOpen && client?.uid) });
  const newsfeedCount = newsfeedsStatus === 'success' ? newsfeedsData.pages[0].count : 0;

  const { data: noteData, status: noteStatus } = useGetCallingNotesAPI({
    filter: 'all',
    rankBy: 'create_dt_dsc',
    client: client?.uid,
  }, {
    enabled: Boolean(isOpen && client?.uid),
  });
  const noteCount = noteStatus === 'success' ? noteData.pages[0].count : 0;
  const [tab, setTab] = React.useState<'main'|'newsfeed'|'contacts'|'notes'>('main');
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  const onClose = () => {
    setTab('main');
    onRequestClose();
  };

  if (!client) {
    let body;
    if (status === 'loading') {
      body = <Loading />;
    } else if (status === 'error') {
      body = <GeneralError />;
    }

    if (!body) return null;

    return (
      <TitledModal
        isOpen={isOpen}
        onRequestClose={onClose}
        title=""
      >
        {body}
      </TitledModal>
    );
  }

  const {
    name,
    photo_url: photoUrl,
    industry,
    sub_industry: subIndustry,
    calling_note_count: callingNoteCount,
    contact_count: contactCount,
    phone,
    address,
    website,
    description,
    uid,
  } = client;

  let body;
  if (tab === 'main') {
    body = (
      <>
        <TitleCard>
          <EditButton onClick={() => setIsEditModalOpen(true)} />
          <NameContainer>
            {photoUrl && <Logo src={photoUrl} />}
            <Name>{name}</Name>
          </NameContainer>
          {industry && (
            <IndustryContainer>
              {industry}
              {subIndustry && <Dot>・</Dot>}
              {subIndustry}
            </IndustryContainer>
          )}
        </TitleCard>

        <TabRow>
          <TabCard
            iconSrc={noteImg}
            title={t('common.notes')}
            number={callingNoteCount}
            onClick={() => {
              if (noteCount > 0) {
                setTab('notes');
              }
            }}
          />
          <TabCard
            iconSrc={insightImg}
            title={t('common.newsfeeds')}
            number={newsfeedCount}
            onClick={() => {
              if (newsfeedCount > 0) {
                setTab('newsfeed');
              }
            }}
          />
          <TabCard
            iconSrc={contactImg}
            title={t('common.contacts')}
            number={contactCount}
            onClick={() => {
              if (contactCount > 0) {
                setTab('contacts');
              }
            }}
          />
        </TabRow>

        <DescCard>
          { phone && <DescItem iconSrc={phoneImg} phone={phone} /> }
          { address && <DescItem iconSrc={locationImg} text={address} /> }
          { website && <DescItem iconSrc={websiteImg} url={website} /> }
          <DescTitle>{t('common.description')}</DescTitle>
          <Desc>{description}</Desc>
        </DescCard>
      </>
    );
  } else if (tab === 'notes') {
    body = (
      <StyledNoteList
        clientUid={client?.uid}
        initialFilter="all"
        initialRankBy="create_dt_dsc"
        showHeader={false}
        groupByDay={false}
      />
    );
  } else if (tab === 'newsfeed') {
    body = (
      <StyledNewsfeedList uid={uid} />
    );
  } else if (tab === 'contacts') {
    body = (
      <StyledContactList
        clientUid={uid}
        initialFilter="all"
        showHeader={false}
        observeBrowserViewport={false}
      />
    );
  }

  return (
    <>
      <TitledModal
        isOpen={isOpen}
        onRequestClose={onClose}
        title={name}
      >
        {tab !== 'main' && <BackButton onClick={() => setTab('main')}>{name}</BackButton>}
        {body}
      </TitledModal>
      <AddOrEditCompanyModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        client={client}
      />
    </>
  );
};

CompanyModal.defaultProps = {
  client: null,
  status: 'idle',
};

CompanyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  client: CompanyPropTypes,
  status: PropTypes.string,
};

export default CompanyModal;
