import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '../common/Card';
import LatestNoteCard from './LatestNoteCard';
import TaskDueList from './TaskDueList';

const Container = styled(Card)`
  padding: 12px;
  display: flex;
  flex-direction: row;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #1B262C;
  opacity: 0.4;
  margin-bottom: 4px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > * {
    box-shadow: none;
  }
`;

const StyledTaskDueList = styled(TaskDueList)`
  max-height: 252px;
  flex: 1;
`;

const NoteGrid: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Column>
        <Title>
          {t('home.latest_note.title')}
        </Title>
        <LatestNoteCard />
      </Column>
      <Column>
        <Title>
          {t('home.tasks_due.title')}
        </Title>
        <StyledTaskDueList />
      </Column>
    </Container>
  );
};

NoteGrid.defaultProps = {};

NoteGrid.propTypes = {};

export default NoteGrid;
