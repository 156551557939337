import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import searchImg from './images/search-green.svg';

const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  height: 36px;
  padding-left: 44px;
  background: #FCFCFC;
  border-radius: 50px;
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: #333;

  &::placeholder {
    color: #01AAC1;
    opacity: 0.6;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: center/contain no-repeat url(${searchImg});
`;

interface IProps {
  className?: string;
}

const SearchInput: React.FC<IProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [searchInput, setSearchInput] = React.useState('');
  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    let src;
    switch (location.pathname) {
      case '/insight':
        src = 'news';
        break;
      case '/notes':
        src = 'calling_note';
        break;
      case '/companies':
        src = 'client';
        break;
      case '/contacts':
        src = 'contact';
        break;
      default:
        src = 'all';
        break;
    }

    const searchParams = new URLSearchParams();
    searchParams.set('q', searchInput);
    searchParams.set('src', src);

    history.push({
      pathname: '/search',
      search: searchParams.toString(),
    });
  };

  if (!['/insight', '/notes', '/companies', '/contacts'].includes(location.pathname)) {
    return <Container className={className} />;
  }

  let placeholder;
  switch (location.pathname) {
    case '/insight':
      placeholder = t('search.search_insight');
      break;
    case '/notes':
      placeholder = t('search.search_notes');
      break;
    case '/companies':
      placeholder = t('search.search_companies');
      break;
    case '/contacts':
      placeholder = t('search.search_contacts');
      break;
    default:
      break;
  }

  return (
    <Container className={className}>
      <SearchIcon />
      <form onSubmit={onSubmit}>
        <Input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder={placeholder}
        />
      </form>
    </Container>
  );
};

SearchInput.defaultProps = {
  className: null,
};

SearchInput.propTypes = {
  className: PropTypes.string,
};

export default SearchInput;
