import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import companyImg from './images/rankby-company-name.svg';
import clockImg from './images/rankby-last-touch-dt.svg';
import RankBy, { RankByItem } from '../common/RankBy';

interface IProps {
  className?: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => any
}

const ItemIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const CompanyRankBy: React.FC<IProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <RankBy
      value={value}
      onChange={onChange}
    >
      <RankByItem
        value="company_name"
        name={t('company.rankby.company_name')}
      >
        <ItemIcon src={companyImg} role="presentation" />
        {t('company.rankby.company_name')}
      </RankByItem>
      <RankByItem
        value="last_touch_dt"
        name={t('company.rankby.last_touch_dt')}
      >
        <ItemIcon src={clockImg} role="presentation" />
        {t('company.rankby.last_touch_dt')}
      </RankByItem>
    </RankBy>
  );
};

CompanyRankBy.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CompanyRankBy;
