import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Card from '../common/Card';
import ExchangeRateModal from './ExchangeRateModal';
import ExchangeRateAlertList from './ExchangeRateAlertList';
import LatestExchangeRate from './LatestExchangeRate';

const Container = styled(Card)``;

const Title = styled.div`
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #1B262C;
  border-bottom: 1px solid rgba(1, 170, 193, 0.2);
`;

const Body = styled.div`
  padding: 20px;
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const EditButton = styled.button`
  border: none;
  width: 100%;
  height: 48px;
  background: #01AAC1;
  border-radius: 100px;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
`;

const ExchangeRateWidget: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <Container>
        <Title>
          {t('exchange_rate.widget.title')}
        </Title>
        <Body>
          <LatestExchangeRate />
          <EditButton onClick={() => setIsModalOpen(true)}>
            {t('exchange_rate.widget.edit_button')}
          </EditButton>
          <ExchangeRateAlertList showDeleteButton={false} showIsAlertedOnly alertColor="blue" />
        </Body>
      </Container>
      <ExchangeRateModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

ExchangeRateWidget.defaultProps = {};

ExchangeRateWidget.propTypes = {};

export default ExchangeRateWidget;
