import { useEffect, useRef } from 'react';

// eslint-disable-next-line no-unused-vars
const useClickOutsideHandler = (onClickOutside: (e: MouseEvent) => any) => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    const evenListener = (e: MouseEvent) => {
      if (rootElemRef.current && !rootElemRef.current.contains(e.target)) {
        onClickOutside(e);
      }
    };

    window.addEventListener('click', evenListener);
    return () => window.removeEventListener('click', evenListener);
  }, [onClickOutside]);

  return rootElemRef;
};

export default useClickOutsideHandler;
