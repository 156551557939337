import * as React from 'react';
import styled from 'styled-components';
import SummaryCard from '../notes/SummaryCard';
import useGetLatestCallingNoteAPI from '../../hooks/api/calling-notes/useGetLatestCallingNoteAPI';
import Loading from '../common/status/Loading';
import GeneralError from '../common/status/GeneralError';
import NoteModal from '../notes/NoteModal';
import { getCardHoverStyles } from '../../util/styles';
import NoData from '../common/status/NoData';

const StyledSummaryCard = styled(SummaryCard)`
  cursor: pointer;
  &:hover {
    ${getCardHoverStyles()}
  }
`;

const LatestNoteCard: React.FC = () => {
  const { data: note, status, refetch } = useGetLatestCallingNoteAPI();
  const [isNoteModalOpen, setIsNoteModalOpen] = React.useState(false);

  if (status === 'loading') return <Loading />;
  if (status === 'error') return <GeneralError onButtonClick={() => refetch()} />;
  if (status === 'success' && !note?.item) return <NoData />;

  return (
    <>
      <StyledSummaryCard
        note={note.item}
        onClick={() => setIsNoteModalOpen(true)}
        maxLines={6}
        showEditButton={false}
        showDeleteButton={false}
        showOwner={false}
        showTags={false}
      />
      <NoteModal
        isOpen={isNoteModalOpen}
        onRequestClose={() => setIsNoteModalOpen(false)}
        note={note.item}
      />
    </>
  );
};

LatestNoteCard.defaultProps = {};

LatestNoteCard.propTypes = {};

export default LatestNoteCard;
