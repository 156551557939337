import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetLeadsAPI from '../../hooks/api/clients/useGetLeadsAPI';
import InfiniteScroll from '../common/InfiniteScroll';
import { getArrayFromInfiniteData } from '../../util/api';
import { Lead } from '../../dataTypes';
import LeadCard from '../company/LeadCard';

const Container = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  margin-bottom: 8px;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
`;

const StyledLeadCard = styled(LeadCard)`
  margin-right: 12px;
`;

interface IProps {
  className?: string;
}

const LeadList: React.FC<IProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useGetLeadsAPI();

  const leads = getArrayFromInfiniteData(data);

  return (
    <Container className={className}>
      <Title>{t('home.lead_list.title')}</Title>
      <StyledInfiniteScroll
        className={className}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        status={status}
        isEmpty={leads.length === 0}
      >
        {
          leads.map((lead: Lead) => {
            return (
              <StyledLeadCard key={lead.uid} lead={lead} />
            );
          })
        }
      </StyledInfiniteScroll>
    </Container>
  );
};

LeadList.defaultProps = {
  className: null,
};

LeadList.propTypes = {
  className: PropTypes.string,
};

export default LeadList;
