import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import * as d3 from 'd3';
import { FeatureCollection } from 'geojson';
import plusImg from './images/plus.svg';
import minusImg from './images/minus.svg';
import { ContactGeoDist, ContactGeoDistPropTypes } from '../../dataTypes';

type Props = {
  className?: string;
  data?: ContactGeoDist[];
  id: string;
  width: number;
  height: number;
}

const Svg = styled.svg`
  cursor: grab;
  &.dragging {
    cursor: grabbing;
  }

  .button {
    cursor: pointer;
  }
`;

const ContactGeoDistMap: React.FC<Props> = ({
  className,
  data,
  id,
  width,
  height,
}) => {
  const svgRef = React.useRef(null);

  const drawMap = React.useCallback(async () => {
    const svg = d3.select(`svg#${id}`);
    svg.style('background', '#BDBDBD');

    // Map and projection
    const projection = d3.geoMercator()
      .center([139.691648, 35.689185]) // Tokyo Capital
      .scale(1500)
      .translate([width / 2, height / 2]);

    // FIXME: scale is not relative to the center
    const getTransformation = (scale: number, translation: number[]) => {
      return `scale(${scale}) translate(${translation[0] / scale}, ${translation[1] / scale})`;
    };

    // Load external data and boot
    d3.json('https://raw.githubusercontent.com/dataofjapan/land/master/japan.geojson')
      .then((japanGeoData: FeatureCollection) => {
        let isDragging = false;
        let dragStartPosition = [0, 0];
        const translation = [0, 0];
        let scale = 1;

        // Reset the map
        svg.selectAll('svg > *').remove();

        // Draw the outline
        svg
          .append('g')
          .selectAll('path')
          .data(japanGeoData.features)
          .join('path')
          .attr('fill', '#f2f2f2')
          .attr('d', d3.geoPath()
            .projection(projection))
          .attr('stroke', '#1B262C')
          .style('stroke-width', 1);

        // Draw the map
        svg
          .append('g')
          .selectAll('path')
          .data(japanGeoData.features)
          .join('path')
          .attr('fill', '#f2f2f2')
          .attr('d', d3.geoPath()
            .projection(projection))
          .attr('stroke', '#1B262C')
          .style('stroke-width', 0.5)
          .style('stroke-opacity', 0.2);

        // Draw circles
        const MAX_COUNT_RADIUS = 28;
        const maxClientCount = (data?.length > 0) ? Math.max(...data.map((item) => item.count), 1) : 0;
        svg
          .append('g')
          .selectAll('myCircles')
          .data(data)
          .join('circle')
          .attr('cx', (d) => projection([d.lng, d.lat])[0])
          .attr('cy', (d) => projection([d.lng, d.lat])[1])
          .attr('r', (d) => Math.sqrt(d.count / maxClientCount) * MAX_COUNT_RADIUS) // Normalization
          .attr('fill', '#2F80ED')
          .attr('stroke', '#2F80ED')
          .attr('stroke-width', 1)
          .attr('fill-opacity', 0.2);

        const createButton = (x: number, y: number, img: string) => {
          const button = svg
            .append('g')
            .attr('class', 'button');

          button.append('rect')
            .attr('x', x)
            .attr('y', y)
            .attr('width', 24)
            .attr('height', 24)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('fill', '#fff');

          button.append('image')
            .attr('xlink:href', img)
            .attr('x', x)
            .attr('y', y)
            .attr('width', 24)
            .attr('height', 24);

          return button;
        };

        // Create zoom buttons
        const zoomInButton = createButton(20, 20, plusImg);
        zoomInButton.on('click', () => {
          scale *= 1.1;
          svg.selectAll('g:not(.button)').attr('transform', getTransformation(scale, translation));
        });

        const zoomOutButton = createButton(20, 51, minusImg);
        zoomOutButton.on('click', () => {
          scale /= 1.1;
          svg.selectAll('g:not(.button)').attr('transform', getTransformation(scale, translation));
        });

        const getMousePosition = (evt: any) => {
          const CTM = svgRef.current.getScreenCTM();
          return {
            x: (evt.clientX - CTM.e) / CTM.a,
            y: (evt.clientY - CTM.f) / CTM.d,
          };
        };

        // Use mouse events to simulate dragging, since svg does not support dragging events
        const startDrag = (e: any) => {
          // disable dragging when the target is the button
          if (e.target.tagName === 'image') return;

          isDragging = true;
          const { x, y } = getMousePosition(e);
          dragStartPosition = [x, y];
          svg.classed('dragging', true);
        };

        const drag = (e: any) => {
          if (isDragging) {
            const { x, y } = getMousePosition(e);
            const dx = x - dragStartPosition[0];
            const dy = y - dragStartPosition[1];
            svg
              .selectAll('g:not(.button)')
              .attr('transform', getTransformation(scale, [translation[0] + dx, translation[1] + dy]));
          }
        };

        const stopDrag = (e: any) => {
          // disable dragging when the target is the button
          if (e.target.tagName === 'image') return;
          if (isDragging) {
            isDragging = false;
            const { x, y } = getMousePosition(e);
            const dx = x - dragStartPosition[0];
            const dy = y - dragStartPosition[1];
            translation[0] += dx;
            translation[1] += dy;
            svg.classed('dragging', false);
          }
        };

        svg
          .on('mousedown', startDrag)
          .on('mousemove', drag)
          .on('mouseup', stopDrag)
          .on('mouseleave', stopDrag);
      });
  }, [data, id, width, height]);

  React.useEffect(() => {
    drawMap();
  }, [drawMap]);

  return (
    <Svg
      ref={svgRef}
      id={id}
      className={className}
      width={width}
      height={height}
    />
  );
};

ContactGeoDistMap.defaultProps = {
  className: null,
  data: [],
};

ContactGeoDistMap.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(ContactGeoDistPropTypes),
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default ContactGeoDistMap;
