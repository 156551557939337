/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import defaultThumbImg from './images/company-thumbnail.svg';
import useCreateClientAPI from '../../hooks/api/clients/useCreateClientAPI';
import useCreateClientPhotoAPI from '../../hooks/api/clients/useCreateClientPhotoAPI';
import { Client, CompanyPropTypes, CompanySuggestion } from '../../dataTypes';
import useUpdateClientAPI from '../../hooks/api/clients/useUpdateClientAPI';
import AutoCompleteCompanyInput from '../common/form-modal/AutoCompleteCompanyInput';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import FormModal from '../common/form-modal/FormModal';

type FormData = {
  name: string;
  description?: string;
  ent_uid?: string;
  industry?: string;
  sub_industry?: string;
  address?: string;
  phone?: string;
  website: string;
  photos?: FileList;
  photo_uid?: string;
  photo_url?: string;
};

const Thumb = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 8px;
  object-fit: contain;
`;

const ThumbLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ChangeThumbButton = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #01AAC1;
  cursor: pointer;
  margin-bottom: 20px;
`;

const FileInput = styled.input`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`;

const Info = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  color: #1B262C;
  margin-bottom: 12px;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

interface LabelProps {
  required?: boolean;
}

const Label = styled.label<LabelProps>`
  font-size: 14px;
  line-height: 24px;
  color: #444150;
  margin-bottom: 8px;
  &::after {
    ${(props) => props.required && "content: ' *';"}
    color: #01AAC1;
  }
`;

const Input = styled.input`
  display: flex;
  align-items: center;
  height: 48px;
  background: #E5EFF5;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  font-size: 14px;
  padding: 0 16px;
`;

const Textarea = styled.textarea`
  height: 104px;
  background: #E5EFF5;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  font-size: 14px;
  padding: 16px;
  resize: none;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
`;

type Props = {
  isOpen: boolean;
  onRequestClose: () => any;
  client?: Client;
}

const AddOrEditCompanyModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  client,
}) => {
  const { t } = useTranslation();
  const {
    register, handleSubmit, formState: { errors, isSubmitSuccessful }, setError, reset, setValue,
  } = useForm<FormData>();

  const { mutateAsync: createClientAPI } = useCreateClientAPI();
  const { mutateAsync: createClientPhotoAPI } = useCreateClientPhotoAPI();
  const { mutateAsync: updateClientAPI } = useUpdateClientAPI(client?.uid);

  const [companyName, setCompanyName] = React.useState(client?.name || '');
  const [thumbPreviewURL, setThumbPreviewURL] = React.useState(null);

  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  // Reset form after adding client or closing modal
  React.useEffect(() => {
    if (isSubmitSuccessful && !client) {
      reset();
      setCompanyName('');
    }
  }, [client, isSubmitSuccessful, reset]);

  // reset default value whenever company changes
  React.useEffect(() => {
    if (!isOpen) {
      reset({
        name: client?.name,
        industry: client?.industry,
        sub_industry: client?.sub_industry,
        description: client?.description,
        phone: client?.phone,
        address: client?.address,
        website: client?.website,
      });
      setCompanyName(client?.name || '');
    }
  }, [client, reset, isOpen]);

  const onClose = () => {
    // TODO: ask if you really want to quit if the form is dirty
    setThumbPreviewURL(null);
    onRequestClose();
  };

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      const clientData = { ...formData };

      if (!companyName) {
        setError('name', { type: 'required' });
        return;
      }
      clientData.name = companyName;

      delete clientData.photos;
      if (formData.photos.length > 0) {
        const res = await createClientPhotoAPI(formData.photos[0]);
        clientData.photo_uid = res.data.photo_uid;
        clientData.photo_url = res.data.photo_url;
      }

      if (client?.uid) {
        await updateClientAPI(clientData);
      } else {
        await createClientAPI(clientData);
      }

      onClose();
      enqueueGeneralSuccessSnackbar();
    } catch (err) {
      switch (err?.response?.data?.code) {
        case 'client_already_exists':
          setError('name', {
            type: 'client_already_exists',
            message: err.response.data.message,
          });
          break;
        case 'client_website_already_exists':
          setError('website', {
            type: 'client_website_already_exists',
            message: err.response.data.message,
          });
          break;
        default:
          enqueueGeneralErrorSnackbar();
          throw err;
      }
    }
  };

  // Make onChange work on Safari
  const { onChange, ...rest } = register('photos');

  return (
    <FormModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={client ? t('company.edit_company_modal.title') : t('company.add_company_modal.title')}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ThumbLabel>
        <Thumb
          src={thumbPreviewURL || client?.photo_url || defaultThumbImg}
          onLoad={() => URL.revokeObjectURL(thumbPreviewURL)}
        />
        <ChangeThumbButton>
          {t('company.add_company_modal.change_photo_button')}
        </ChangeThumbButton>
        <FileInput
          type="file"
          multiple={false}
          accept="image/jpeg,image/png"
          onChange={(e) => {
            if (e.target.files.length > 0) {
              const file = e.target.files[0];
              const previewURL = URL.createObjectURL(file);
              setThumbPreviewURL(previewURL);
            }
            onChange(e);
          }}
          {...rest}
        />
      </ThumbLabel>

      <Info>{t('company.add_company_modal.info')}</Info>

      <Field>
        <Label required>{t('company.add_company_modal.company_name')}</Label>
        <AutoCompleteCompanyInput
          src="client"
          searchInput={companyName}
          onInputChange={(e) => {
            setCompanyName(e.target.value);
            setValue('ent_uid', null);
          }}
          onSelect={(selected: CompanySuggestion) => {
            setCompanyName(selected.name);
            setValue('ent_uid', selected.ent_uid);
          }}
        />
        { errors?.name?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
        { errors?.name?.type === 'client_already_exists' && (
        <ErrorMessage>{t('company.add_company_modal.client_already_exists', { company: errors.name.message })}</ErrorMessage>
        )}
      </Field>

      <Field>
        <Label>{t('company.add_company_modal.industry')}</Label>
        <Input type="text" {...register('industry')} />
      </Field>

      <Field>
        <Label>{t('company.add_company_modal.sub_industry')}</Label>
        <Input type="text" {...register('sub_industry')} />
      </Field>

      <Field>
        <Label>{t('company.add_company_modal.description')}</Label>
        <Textarea cols={3} {...register('description')} maxLength={1000} />
      </Field>

      <Info>{t('company.add_company_modal.company_contact_detail')}</Info>

      <Field>
        <Label>{t('company.add_company_modal.phone')}</Label>
        <Input type="tel" {...register('phone')} />
      </Field>

      <Field>
        <Label>{t('company.add_company_modal.address')}</Label>
        <Input type="text" {...register('address')} />
      </Field>

      <Field>
        <Label required>{t('company.add_company_modal.website')}</Label>
        <Input type="url" {...register('website', { required: true })} placeholder="https://www.example.com" />
        { errors?.website?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
        { errors?.website?.type === 'client_website_already_exists' && (
        <ErrorMessage>
          {t('company.add_company_modal.client_website_already_exists', { company: errors.website.message, newCompany: companyName })}
        </ErrorMessage>
        )}
      </Field>
    </FormModal>
  );
};

AddOrEditCompanyModal.defaultProps = {
  client: null,
};

AddOrEditCompanyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  client: CompanyPropTypes,
};

export default AddOrEditCompanyModal;
