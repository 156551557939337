/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import chevronImg from './images/chevron-down.svg';

const SelectContainer = styled.div`
  position: relative;
  /* chevron img */
  &::after {
    content: '';
    background: center/contain no-repeat url(${chevronImg});
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const SelectInner = styled.select`
  /* reset */
  position: relative;
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;

  display: flex;
  align-items: center;
  height: 48px;
  background: #E5EFF5;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  font-size: 14px;
  padding: 0 16px;
`;

const Select = <IFormValues extends unknown>({
  children,
  label,
  register,
  required,
}: {
  children: React.ReactNode;
  label: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  required?: boolean;
}) => {
  return (
    <SelectContainer>
      <SelectInner {...register(label, { required })}>
        {children}
      </SelectInner>
    </SelectContainer>
  );
};

Select.defaultProps = {
  required: false,
};

export default Select;
