import { useQuery } from 'react-query';
import api from '../../../util/api';
import { Note } from '../../../dataTypes';

type CallingNoteItem = {
  item?: Note;
}

const useGetLatestCallingNoteAPI = () => {
  const fetchFunction = async () => {
    const res = await api.get('/calling_notes/latest');
    return res.data;
  };

  return useQuery<CallingNoteItem>(
    ['calling_notes', 'latest'],
    fetchFunction,
  );
};

export default useGetLatestCallingNoteAPI;
