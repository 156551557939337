import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import filterAllImg from './images/filter-all.svg';
import filterMyImg from './images/filter-my.svg';
import Filter, { FilterItem } from '../common/Filter';

interface IProps {
  className?: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => any
}

const ItemIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const LifeCycleOwnerFilter: React.FC<IProps> = ({
  className,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <Filter
      className={className}
      value={value}
      onChange={onChange}
      title={t('contact.lifecycle_owner_filter_title')}
    >
      <FilterItem value="all" name={t('contact.lifecycle_owner.all')}>
        <ItemIcon src={filterAllImg} role="presentation" />
        {t('contact.lifecycle_owner.all')}
      </FilterItem>
      <FilterItem
        value="my"
        name={t('contact.lifecycle_owner.my')}
      >
        <ItemIcon src={filterMyImg} role="presentation" />
        {t('contact.lifecycle_owner.my')}
      </FilterItem>
    </Filter>
  );
};

LifeCycleOwnerFilter.defaultProps = {
  className: null,
};

LifeCycleOwnerFilter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LifeCycleOwnerFilter;
