import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 16px 0;
`;

const Icon = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 4px;
`;

const Message = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #01AAC1;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Button = styled.button`
  background: #01AAC1;
  border-radius: 60px;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
`;

interface IProps {
  className?: string;
  iconSrc: string;
  message: string;
  buttonText?: string;
  // eslint-disable-next-line no-unused-vars
  onButtonClick?: (e: React.MouseEvent<HTMLElement>) => any;
}

const BaseErrorView: React.FC<IProps> = ({
  className,
  iconSrc,
  message,
  buttonText,
  onButtonClick,
}) => {
  return (
    <Container className={className}>
      <Icon src={iconSrc} role="presentation" />
      <Message>{message}</Message>
      {buttonText && <Button onClick={onButtonClick}>{buttonText}</Button>}
    </Container>
  );
};

BaseErrorView.defaultProps = {
  className: null,
  buttonText: null,
  onButtonClick: () => {},
};

BaseErrorView.propTypes = {
  className: PropTypes.string,
  iconSrc: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default BaseErrorView;
