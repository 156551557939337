import * as React from 'react';
import useLoginRequired from '../../hooks/auth/useLoginRequired';
import HeaderLayout from '../layouts/HeaderLayout';
import ContactList from './ContactList';

const Main = () => (
  <ContactList observeBrowserViewport />
);

const ContactScreen: React.FC = () => {
  useLoginRequired();

  return (
    <HeaderLayout
      mainComponent={Main}
    />
  );
};

export default ContactScreen;
