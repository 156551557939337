/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  CompanySuggestion, ProfilePropTypes, Profile,
} from '../../dataTypes';
import chevronImg from './images/chevron.svg';
import AutoCompleteCompanyInput from '../common/form-modal/AutoCompleteCompanyInput';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import Checkbox from '../common/Checkbox';
import useUpdateMyProfileAPI from '../../hooks/api/profile/useUpdateMyProfileAPI';
import defaultThumbImg from '../../images/user-thumbnail.svg';
import useCreateMyProfilePhotoAPI from '../../hooks/api/profile/useCreateMyProfilePhotoAPI';
import BizcardList from '../common/BizcardList';
import FormModal from '../common/form-modal/FormModal';

interface FormData {
  first_name: string;
  last_name: string;
  phonetic_first_name?: string;
  phonetic_last_name?: string;
  local_first_name?: string;
  local_last_name?: string;
  share_reg_email: boolean;
  company_name: string;
  department?: string;
  job_title: string;
  work_email: string;
  tel?: string;
  mobile?: string;
  address?: string;
  photo_uid?: string;
  photo_url?: string;
  photos?: FileList;
  // bizcard_front_uid
  // bizcard_front_url
  // bizcard_back_uid
  // bizcard_back_url
  is_setup_profile: boolean;
  username: string;
  profile_url?: string;
  reg_email: string;
}

interface IProps {
  isOpen: boolean;
  onRequestClose: () => any;
  profile: Profile;
}

const ThumbLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Thumb = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 8px;
  object-fit: cover;
`;

const ChangeThumbButton = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #01AAC1;
  cursor: pointer;
`;

const FileInput = styled.input`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`;

const SubTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  color: #1B262C;
  margin: 12px 0;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

interface LabelProps {
  required?: boolean;
}

const Label = styled.label<LabelProps>`
  font-size: 14px;
  line-height: 24px;
  color: #444150;
  margin-bottom: 8px;
  &::after {
    ${(props) => props.required && "content: ' *';"}
    color: #01AAC1;
  }
`;

const Input = styled.input`
  display: flex;
  align-items: center;
  height: 48px;
  background: #E5EFF5;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  font-size: 14px;
  padding: 0 16px;

  &:read-only {
    color: rgba(1,170,193);
  }
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: 14px;
  margin-bottom: 12px;
`;

const DisplayPhoneticNamesButton = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #01AAC1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Chevron = styled.div`
  width: 24px;
  height: 24px;
  background: center/contain no-repeat url(${chevronImg});
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
`;

const EditProfileModal: React.FC<IProps> = ({
  isOpen,
  onRequestClose,
  profile,
}) => {
  const { t } = useTranslation();

  const [companyName, setCompanyName] = React.useState(profile?.company_name || '');
  const [thumbPreviewURL, setThumbPreviewURL] = React.useState(null);
  const [showPhoneticName, setShowPhoneticName] = React.useState(false);

  const {
    register, handleSubmit, formState: { errors }, reset, control,
  } = useForm<FormData>();
  // Make onChange work on Safari
  const { onChange, ...rest } = register('photos');

  const { mutateAsync: updateMyProfileAPI } = useUpdateMyProfileAPI();
  const { mutateAsync: createMyProfilePhotoAPI } = useCreateMyProfilePhotoAPI();

  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  // reset default value after profile changes or closing modal
  React.useEffect(() => {
    if (!isOpen) {
      reset({
        first_name: profile.first_name,
        last_name: profile.last_name,
        phonetic_first_name: profile.phonetic_first_name,
        phonetic_last_name: profile.phonetic_last_name,
        local_first_name: profile.local_first_name,
        local_last_name: profile.local_last_name,
        share_reg_email: profile.share_reg_email,
        company_name: profile.company_name,
        department: profile.department,
        job_title: profile.job_title,
        work_email: profile.work_email,
        tel: profile.tel,
        mobile: profile.mobile,
        address: profile.address,
        // TODO: bizcard photo
        photo_uid: profile.photo_uid,
        photo_url: profile.photo_url,
        // bizcard_front_uid
        // bizcard_front_url
        // bizcard_back_uid
        // bizcard_back_url
        is_setup_profile: profile.is_setup_profile,
        username: profile.username,
        profile_url: profile.profile_url,
        reg_email: profile.reg_email,
      });
      setCompanyName(profile.company_name || '');
      setThumbPreviewURL(null);
    }
  }, [profile.address, profile.company_name, profile.department, profile.first_name, profile.is_setup_profile, profile.job_title, profile.last_name, profile.local_first_name, profile.local_last_name, profile.mobile, profile.phonetic_first_name, profile.phonetic_last_name, profile.photo_uid, profile.photo_url, profile.profile_url, profile.reg_email, profile.share_reg_email, profile.tel, profile.username, profile.work_email, reset, isOpen]);

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      const myProfileData = { ...formData };
      myProfileData.company_name = companyName;
      myProfileData.is_setup_profile = true;

      delete myProfileData.photos;
      if (formData.photos.length > 0) {
        const res = await createMyProfilePhotoAPI(formData.photos[0]);
        myProfileData.photo_uid = res.data.photo_uid;
        myProfileData.photo_url = res.data.photo_url;
      }

      await updateMyProfileAPI(myProfileData);

      onRequestClose();
      enqueueGeneralSuccessSnackbar();
    } catch (err) {
      enqueueGeneralErrorSnackbar();
      throw err;
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t('profile.edit_modal.title')}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ThumbLabel>
        <Thumb
          src={thumbPreviewURL || profile.photo_url || defaultThumbImg}
          onLoad={() => URL.revokeObjectURL(thumbPreviewURL)}
        />
        <ChangeThumbButton>
          {t('profile.edit_modal.change_profile_photo')}
        </ChangeThumbButton>
        <FileInput
          type="file"
          multiple={false}
          accept="image/jpeg,image/png"
          onChange={(e) => {
            if (e.target.files.length > 0) {
              const file = e.target.files[0];
              const previewURL = URL.createObjectURL(file);
              setThumbPreviewURL(previewURL);
            }
            onChange(e);
          }}
          {...rest}
        />
      </ThumbLabel>

      <SubTitle>
        {t('profile.edit_modal.profile_info')}
      </SubTitle>

      <Field>
        <Label required>{t('contact.add_contact_modal.first_name')}</Label>
        <Input type="text" {...register('first_name', { required: true })} />
        { errors?.first_name?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      <Field>
        <Label required>{t('contact.add_contact_modal.last_name')}</Label>
        <Input type="text" {...register('last_name', { required: true })} />
        { errors?.last_name?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      {!showPhoneticName && (
      <DisplayPhoneticNamesButton onClick={() => setShowPhoneticName(true)}>
        {t('contact.add_contact_modal.display_phonetic_names')}
        <Chevron />
      </DisplayPhoneticNamesButton>
      )}

      {showPhoneticName && (
      <>
        <Field>
          <Label>{t('contact.add_contact_modal.phonetic_first_name')}</Label>
          <Input type="text" {...register('phonetic_first_name')} />
        </Field>

        <Field>
          <Label>{t('contact.add_contact_modal.phonetic_last_name')}</Label>
          <Input type="text" {...register('phonetic_last_name')} />
        </Field>

        <Field>
          <Label>{t('contact.add_contact_modal.local_first_name')}</Label>
          <Input type="text" {...register('local_first_name')} />
        </Field>

        <Field>
          <Label>{t('contact.add_contact_modal.local_last_name')}</Label>
          <Input type="text" {...register('local_last_name')} />
        </Field>
      </>
      )}

      <SubTitle>
        {t('profile.edit_modal.profile_details')}
      </SubTitle>

      <Field>
        <Label>{t('contact.add_contact_modal.company_name')}</Label>
        <AutoCompleteCompanyInput
          src="contact"
          searchInput={companyName}
          onInputChange={(e) => {
            setCompanyName(e.target.value);
          }}
          onSelect={(selected: CompanySuggestion) => {
            setCompanyName(selected.name);
          }}
        />
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.department')}</Label>
        <Input type="text" {...register('department')} />
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.job_title')}</Label>
        <Input type="text" {...register('job_title')} />
      </Field>

      <Controller
        name="share_reg_email"
        control={control}
        render={({ field }) => (
          <StyledCheckbox {...field}>
            {t('profile.edit_modal.share_reg_email')}
          </StyledCheckbox>
        )}
      />

      <Field>
        <Input type="text" {...register('reg_email')} readOnly />
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.email')}</Label>
        <Input type="email" {...register('work_email')} />
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.tel')}</Label>
        <Input type="tel" {...register('tel')} />
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.mobile')}</Label>
        <Input type="tel" {...register('mobile')} />
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.address')}</Label>
        <Input type="text" {...register('address')} />
      </Field>

      {(profile.bizcard_front_url || profile.bizcard_back_url) && (
      <>
        <SubTitle>
          {t('profile.edit_modal.my_business_card')}
        </SubTitle>
        <BizcardList bizcardFrontUrl={profile.bizcard_front_url} bizcardBackUrl={profile.bizcard_back_url} />
      </>
      )}
    </FormModal>
  );
};

EditProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  profile: ProfilePropTypes.isRequired,
};

export default EditProfileModal;
