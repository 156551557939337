/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ingentaImg from '../../images/ingenta.svg';
import logoImg from '../../images/logo.svg';
import aisaImg from '../../images/aisa.svg';
import backgroundImg from './images/login-background.jpg';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getLogin, login } from '../../slices/authSlice';
import api from '../../util/api';
import ForgetPasswordModal from './ForgetPasswordModal';

type FormValues = {
  user: string;
  password: string;
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background: #fff;
  background-image:
    url(${backgroundImg});
    background-repeat: no-repeat;
    background-position: center 176px;
    background-size: cover;
`;

const Column = styled.div`
  flex: 1;
  padding: 16px;
`;

const LeftContainer = styled.div`
  max-width: 360px;
  margin: 200px 0 0 auto;
  text-align: center;
`;

const Logo = styled.img`
  width: 52px;
  height: 56px;
  margin-right: 10px;
  vertical-align: middle;
`;

const AiSA = styled.img`
  width: 82px;
  height: 26px;
  vertical-align: middle;
`;

const Description = styled.p`
  font-size: 20px;
  line-height: 32px;
  color: #01AAC1;
`;

const RightContainer = styled.div`
  max-width: 500px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 100px 10px;
  padding: 50px 40px;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04);
  margin-top: 64px;
`;

const FormTitle = styled.p`
  font-size: 14px;
  color: #1B262C;
  margin-bottom: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #444150;
  margin-bottom: 8px;
  &::after {
    content: '*';
    color: #01AAC1;
  }
`;

const Input = styled.input`
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  margin: 0;
`;

const Sumbit = styled.input`
  height: 56px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #01AAC1 0%, #02E1FF 100%);
  border-radius: 8px;
  border: none;
  color: #fff;
  cursor: pointer;
`;

const FormFooter = styled.p`
  color: #fff;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  max-width: 500px;
  margin: 20px auto;
  text-align: right;
  & > * {
    vertical-align: middle;
  }
  img {
    margin-left: 0.3em;
  }
`;

const ForgetPassword = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #01AAC1;
  cursor: pointer;
  margin-bottom: 20px;
`;

const LoginScreen = () => {
  const { t } = useTranslation();
  const {
    register, handleSubmit, formState: { errors }, setError,
  } = useForm<FormValues>();
  const history = useHistory();
  const isLogin = useAppSelector(getLogin);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (isLogin) {
      history.replace({ pathname: '/' });
    }
  }, [history, isLogin]);

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const res = await api.post('/login', data);
      const body = res.data;

      dispatch(login({
        accessToken: body.access_token,
        email: body.email,
        refreshToken: body.refresh_token,
        status: body.status,
      }));

      history.push('/');
    } catch (err) {
      if (err.response.data.status === 400) {
        setError('user', { type: 'validate', message: 'Invalid username/email or password. Please try again.' });
        return;
      }

      // TODO: 403 -> verify email

      throw err;
    }
  };

  return (
    <>
      <Container>
        <Column>
          <LeftContainer>
            <Logo src={logoImg} alt="logo" />
            <AiSA src={aisaImg} alt="AiSA" />
            <Description>
              {t('login.title')}
            </Description>
          </LeftContainer>
        </Column>
        <Column>
          <RightContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormTitle>{t('login.description')}</FormTitle>
              <FieldContainer>
                <Label>{t('login.username')}</Label>
                <Input type="text" {...register('user', { required: true })} />
              </FieldContainer>
              <FieldContainer>
                <Label>{t('login.password')}</Label>
                <Input type="password" {...register('password', { required: true })} />
                { Object.keys(errors).length > 0
                  && <ErrorMessage>{t('login.invalid_username_password')}</ErrorMessage>}
              </FieldContainer>
              <ForgetPassword onClick={() => setIsResetPasswordModalOpen(true)}>
                {t('login.forgot_password')}
              </ForgetPassword>
              <Sumbit type="submit" value={t('common.login') as string} />
            </Form>
            <FormFooter>
              <Trans i18nKey="login.powered_by">
                Powered by
                <img src={ingentaImg} alt="Ingenta" />
              </Trans>
            </FormFooter>
          </RightContainer>
        </Column>
      </Container>
      <ForgetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onRequestClose={() => setIsResetPasswordModalOpen(false)}
      />
    </>
  );
};

export default LoginScreen;
