/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import settingsImg from './images/settings.svg';
import logoutImg from './images/logout.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { logout } from '../../../slices/authSlice';
import useClickOutsideHandler from '../../../hooks/useClickOutsideHandler';
import UserThumbnail from '../../common/UserThumbnail';

const Container = styled.div`
  padding: 14px 12px;
  width: 250px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  &:hover {
    background: rgba(1, 170, 193, 0.12);
    border-radius: 60px;
  }
`;

const StyledUserThumbnail = styled(UserThumbnail)`
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

const Username = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
`;

const UserDescription = styled.div`
  font-size: 12px;
  color: #1B262C;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 16px;
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
`;

const ProfileItem: React.FC<{ profilePhotoUrl?: string }> = ({
  profilePhotoUrl,
}) => {
  const { t } = useTranslation();
  const username = useAppSelector((state) => state.me.username);

  return (
    <ItemContainer>
      <StyledUserThumbnail url={profilePhotoUrl} />
      <div>
        <Username>{username}</Username>
        <UserDescription>{t('header.menu.see_your_profile')}</UserDescription>
      </div>
    </ItemContainer>
  );
};

ProfileItem.defaultProps = {
  profilePhotoUrl: null,
};

ProfileItem.propTypes = {
  profilePhotoUrl: PropTypes.string,
};

const Separator = styled.hr`
  margin: 12px 0;
  border-color: #1B262C;
  opacity: 0.1;
`;

const SettingsItem = () => {
  const { t } = useTranslation();
  return (
    <ItemContainer>
      <Icon src={settingsImg} role="presentation" />
      <Title>{t('header.menu.detailed_settings')}</Title>
    </ItemContainer>
  );
};

const LogoutItem = () => {
  const { t } = useTranslation();
  return (
    <ItemContainer>
      <Icon src={logoutImg} role="presentation" />
      <Title>{t('common.logout')}</Title>
    </ItemContainer>
  );
};

type Props = {
  className?: string;
  onClose: () => void;
  profilePhotoUrl?: string;
}

const Menu: React.FC<Props> = ({ className, onClose, profilePhotoUrl }) => {
  const dispatch = useAppDispatch();
  const onLogout = () => dispatch(logout());
  const menuRootRef = useClickOutsideHandler(onClose);

  return (
    <Container className={className} ref={menuRootRef}>
      <Link to="/profile" onClick={onClose}>
        <ProfileItem profilePhotoUrl={profilePhotoUrl} />
      </Link>
      <Separator />
      {/* <Link to="/settings" onClick={onClose}>
        <SettingsItem />
      </Link> */}
      <Link to="#" onClick={onLogout}>
        <LogoutItem />
      </Link>
    </Container>
  );
};

Menu.defaultProps = {
  profilePhotoUrl: null,
};

Menu.propTypes = {
  className: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  profilePhotoUrl: PropTypes.string,
};

export default Menu;
