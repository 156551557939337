import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as d3 from 'd3';
import doneImg from './images/done.svg';
import notDoneImg from './images/not-done.svg';
import { SIDE_COLUMN_WIDTH } from '../layouts/HeaderLayout';

type TaskStatus = {
  name: string;
  value: number;
}

type Props = {
  data: TaskStatus[];
}

const TaskStatusPieChart: React.FC<Props> = ({
  data,
}) => {
  const draw = React.useCallback(() => {
    // set the dimensions and margins of the graph
    const width = SIDE_COLUMN_WIDTH;
    const height = 228;
    const margin = 32;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(width, height) / 2 - margin;

    const svg = d3.select('svg#task-status-pie-chart')
      .attr('width', width)
      .attr('height', height)
      .style('background', '#F2F2F2');

    if (!data) return;

    // Reset the graph
    svg.selectAll('svg > *').remove();

    // Center
    const g = svg
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Compute the position of each group on the pie:
    const pieGenerator = d3.pie<TaskStatus>()
      .value((d) => { return d.value; });
    const arcData = pieGenerator(data);

    // For drawing the pie chart
    const arcGenerator = d3.arc<d3.PieArcDatum<TaskStatus>>()
      .innerRadius(0)
      .outerRadius(radius);

    const color = d3.scaleOrdinal<string, string>()
      .domain(['done', 'not_done'])
      .range(['#01AAC1', '#BDBDBD']);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    g.selectAll('path')
      .data(arcData)
      .join('path')
      .attr('d', arcGenerator)
      .attr('fill', (d) => { return color(d.data.name); });

    // Text label
    g.append('g')
      .attr('class', 'labels');

    g.select('.labels')
      .selectAll('text')
      .data(arcData)
      .join('text')
      .attr('dy', '.35em')
      .text((d) => `${Math.round(d.data.value * 100)}%`)
      .attr('transform', (d) => {
        const pos = arcGenerator.centroid(d);
        return `translate(${pos})`;
      })
      .style('text-anchor', 'middle')
      .style('font-size', '13px')
      .style('fill', '#fff');

    // Icons
    g.append('g')
      .attr('class', 'icons');

    g.select('.icons')
      .selectAll('image')
      .data(arcData)
      .join('image')
      .attr('width', '18px')
      .attr('height', '18px')
      .attr('x', -10)
      .attr('y', -25)
      .attr('href', (d) => (d.data.name === 'done' ? doneImg : notDoneImg))
      .attr('transform', (d) => {
        const pos = arcGenerator.centroid(d);
        return `translate(${pos})`;
      });
  }, [data]);

  React.useEffect(() => {
    draw();
  }, [draw]);

  return (
    <svg id="task-status-pie-chart" width="336" height="228" />
  );
};

TaskStatusPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

export default TaskStatusPieChart;
