import { useQuery } from 'react-query';
import { TaskStatusItem } from '../../../dataTypes';
import api from '../../../util/api';

const useGetCallingNoteTaskStatusAPI = () => {
  const fetchFunction = async () => {
    const res = await api.get('/calling_notes/task_status');
    return {
      ...res.data,
    };
  };

  return useQuery<TaskStatusItem>(
    'calling_notes/task_status',
    fetchFunction,
  );
};

export default useGetCallingNoteTaskStatusAPI;
