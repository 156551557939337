/* eslint-disable camelcase */
import * as PropTypes from 'prop-types';

export type Insight = {
  about?: string;
  category: string;
  description?: string;
  img_url?: string;
  is_saved: boolean;
  lang: string;
  link: string;
  provider: string;
  published_dt: string;
  reco_type?: string;
  reco_uid: string;
  summary?: string;
  title: string;
  uid: string;
}

export const InsightPropTypes = PropTypes.exact({
  about: PropTypes.string,
  category: PropTypes.string.isRequired,
  description: PropTypes.string,
  img_url: PropTypes.string,
  is_saved: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  published_dt: PropTypes.string.isRequired,
  reco_type: PropTypes.string,
  reco_uid: PropTypes.string,
  summary: PropTypes.string,
  title: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
});

type CallingNote = {
  last_touch_dt: string;
  summary?: string;
  uid: string;
}

export type Client = {
  address?: string;
  calling_note_count: number;
  contact_count: number;
  description?: string;
  edit_dt: string;
  ent_uid?: string;
  industry?: string;
  last_calling_note?: CallingNote;
  last_touch_dt: string;
  name: string;
  phone?: string;
  photo_uid?: string;
  photo_url?: string;
  sub_industry?: string;
  uid: string;
  website?: string;
}

export const CompanyPropTypes = PropTypes.exact({
  address: PropTypes.string,
  calling_note_count: PropTypes.number,
  contact_count: PropTypes.number,
  description: PropTypes.string,
  edit_dt: PropTypes.string,
  ent_uid: PropTypes.string,
  industry: PropTypes.string,
  last_calling_note: PropTypes.exact({
    last_touch_dt: PropTypes.string,
    summary: PropTypes.string,
    uid: PropTypes.string,
  }),
  last_touch_dt: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  photo_uid: PropTypes.string,
  photo_url: PropTypes.string,
  sub_industry: PropTypes.string,
  uid: PropTypes.string,
  website: PropTypes.string,
});

export type Lead = {
  address?: string;
  description?: string;
  industry?: string;
  is_saved: boolean;
  logo?: string;
  name: string;
  price?: number;
  price_change?: number;
  price_change_percent?: number;
  reco_score_percent?: number;
  reco_type?: string;
  reco_uid?: string;
  sec_code?: string;
  uid: string;
  website?: string;
}

export const LeadPropTypes = PropTypes.exact({
  address: PropTypes.string,
  description: PropTypes.string,
  industry: PropTypes.string,
  is_saved: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  photo_uid: PropTypes.string,
  photo_url: PropTypes.string,
  price: PropTypes.number,
  price_change: PropTypes.number,
  price_change_percent: PropTypes.number,
  reco_score_percent: PropTypes.number,
  reco_type: PropTypes.string,
  reco_uid: PropTypes.string,
  sec_code: PropTypes.string,
  uid: PropTypes.string.isRequired,
  website: PropTypes.string,
});

export type LifeCycleStage = 'approach' | 'lead' | 'pre_proposal' | 'proposal' | 'contract' | 'complete' | 'postpone' | 'cancel';

type CreatedBy = {
  department?: string;
  dt: string;
  first_name: string;
  job_title: string;
  last_name: string;
  uid: string;
}

type LifeCycle = {
  edit_dt: string;
  owner_department?: string;
  owner_first_name: string;
  owner_id: string;
  owner_job_title: string;
  owner_last_name: string;
  stage: LifeCycleStage;
}

export type Contact = {
  address?: string;
  bizcard_back_uid?: string;
  bizcard_back_url?: string;
  bizcard_front_uid?: string;
  bizcard_front_url?: string;
  client_uid: string;
  company_name?: string;
  created_by: CreatedBy;
  department?: string;
  edit_dt: string;
  email?: string;
  first_name: string;
  is_personal: boolean;
  job_title?: string;
  last_name: string;
  last_touch_dt: string;
  life_cycle_stage?: LifeCycleStage;
  life_cycles?: LifeCycle[];
  local_first_name?: string;
  local_last_name?: string;
  memo?: string;
  mobile?: string;
  phonetic_first_name?: string;
  phonetic_last_name?: string;
  tel?: string;
  uid: string;
}

export const ContactPropTypes = PropTypes.exact({
  address: PropTypes.string,
  bizcard_back_uid: PropTypes.string,
  bizcard_back_url: PropTypes.string,
  bizcard_front_uid: PropTypes.string,
  bizcard_front_url: PropTypes.string,
  client_uid: PropTypes.string.isRequired,
  company_name: PropTypes.string,
  created_by: PropTypes.exact({
    department: PropTypes.string,
    dt: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    job_title: PropTypes.string,
    last_name: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }).isRequired,
  department: PropTypes.string,
  edit_dt: PropTypes.string.isRequired,
  email: PropTypes.string,
  first_name: PropTypes.string.isRequired,
  is_personal: PropTypes.bool.isRequired,
  job_title: PropTypes.string,
  last_name: PropTypes.string.isRequired,
  last_touch_dt: PropTypes.string.isRequired,
  life_cycle_stage: PropTypes.oneOf(['approach', 'lead', 'pre_proposal', 'proposal', 'contract', 'complete', 'postpone', 'cancel'] as const),
  life_cycles: PropTypes.array,
  local_first_name: PropTypes.string,
  local_last_name: PropTypes.string,
  memo: PropTypes.string,
  mobile: PropTypes.string,
  phonetic_first_name: PropTypes.string,
  phonetic_last_name: PropTypes.string,
  tel: PropTypes.string,
  uid: PropTypes.string.isRequired,
});

export type ActivityType = 'call' | 'email' | 'meeting' | 'others';

export type Note = {
  activity_type: ActivityType;
  client_name: string;
  client_uid: string;
  contact_email?: string;
  contact_first_name?: string;
  contact_job_title?: string;
  contact_last_name?: string;
  contact_phone?: string;
  contact_uid?: string;
  due_dt: string;
  is_public: boolean;
  owner_department?: string;
  owner_first_name: string;
  owner_job_title: string;
  owner_last_name: string;
  owner_uid: string;
  summary: string;
  tags?: string[];
  task_content?: string;
  task_due_dt?: string;
  task_is_done: boolean;
  uid: string;
}

export const NotePropTypes = PropTypes.exact({
  activity_type: PropTypes.oneOf(['call', 'email', 'meeting', 'others'] as const).isRequired,
  client_name: PropTypes.string.isRequired,
  client_uid: PropTypes.string.isRequired,
  contact_email: PropTypes.string,
  contact_first_name: PropTypes.string,
  contact_job_title: PropTypes.string,
  contact_last_name: PropTypes.string,
  contact_phone: PropTypes.string,
  contact_uid: PropTypes.string,
  due_dt: PropTypes.string.isRequired,
  is_public: PropTypes.bool.isRequired,
  owner_department: PropTypes.string,
  owner_first_name: PropTypes.string.isRequired,
  owner_job_title: PropTypes.string,
  owner_last_name: PropTypes.string.isRequired,
  owner_uid: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  task_content: PropTypes.string,
  task_due_dt: PropTypes.string,
  task_is_done: PropTypes.bool,
  uid: PropTypes.string.isRequired,
});

export type CompanySuggestion = {
  address?: string;
  client_uid: string;
  description?: string;
  ent_uid?: string;
  industry?: string;
  name: string;
  phone?: string;
  photo_uid?: string;
  photo_url?: string;
  sub_industry?: string;
  website: string;
}

export type ContactSuggestion = {
  contact_uid: string;
  contact_full_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_job_title: string;
}

export type SearchResult = {
  calling_note: Note[];
  client: Client[];
  contact: Contact[];
  news: Insight[];
};

export const SearchResultPropTypes = PropTypes.exact({
  client: PropTypes.arrayOf(CompanyPropTypes).isRequired,
  contact: PropTypes.arrayOf(ContactPropTypes).isRequired,
  news: PropTypes.arrayOf(InsightPropTypes).isRequired,
  calling_note: PropTypes.arrayOf(NotePropTypes).isRequired,
  place: PropTypes.array.isRequired,
});

export type Profile = {
  address?: string;
  bizcard_back_uid?: string;
  bizcard_back_url?: string;
  bizcard_front_uid?: string;
  bizcard_front_url?: string;
  company_name: string;
  department?: string;
  first_name: string;
  is_setup_profile: boolean;
  job_title: string;
  last_name: string;
  local_first_name?: string;
  local_last_name?: string;
  mobile?: string;
  phonetic_first_name?: string;
  phonetic_last_name?: string;
  photo_uid?: string;
  photo_url?: string;
  profile_url?: string;
  reg_email: string;
  share_reg_email: boolean;
  tel?: string;
  uid: string;
  username: string;
  work_email?: string;
}

export const ProfilePropTypes = PropTypes.exact({
  address: PropTypes.string,
  bizcard_back_uid: PropTypes.string,
  bizcard_back_url: PropTypes.string,
  bizcard_front_uid: PropTypes.string,
  bizcard_front_url: PropTypes.string,
  company_name: PropTypes.string.isRequired,
  department: PropTypes.string,
  first_name: PropTypes.string.isRequired,
  is_setup_profile: PropTypes.bool.isRequired,
  job_title: PropTypes.string,
  last_name: PropTypes.string.isRequired,
  local_first_name: PropTypes.string,
  local_last_name: PropTypes.string,
  mobile: PropTypes.string,
  phonetic_first_name: PropTypes.string,
  phonetic_last_name: PropTypes.string,
  photo_uid: PropTypes.string,
  photo_url: PropTypes.string,
  profile_url: PropTypes.string,
  reg_email: PropTypes.string.isRequired,
  share_reg_email: PropTypes.bool.isRequired,
  tel: PropTypes.string,
  uid: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  work_email: PropTypes.string,
});

export type Corp = {
  address?: string;
  description?: string;
  industry?: string;
  is_saved: boolean;
  name: string;
  photo_uid?: string;
  photo_url?: string;
  price?: number;
  price_change?: number;
  price_change_percent?: number;
  sec_code?: string;
  website?: string;
  uid: string;
}

export type ContactGeoDist = {
  code: string;
  count: number;
  lat: number;
  lng: number;
  name: string;
  name_ja: string;
}

export type ContactGeoDistItems = {
  items: ContactGeoDist[];
}

export const ContactGeoDistPropTypes = PropTypes.exact({
  code: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  name_ja: PropTypes.string.isRequired,
});

export type ClientIndustryDistribution = {
  dist_percent: number;
  industry: string;
};

export type ClientIndustryDistributionItems = {
  items: ClientIndustryDistribution[];
}

export type TaskStatusItem = {
  item: {
    done: number;
    not_done: number;
  };
}

export type ExchangeRateAlert = {
  base: string;
  base_amount: number;
  create_dt: string;
  is_alerted: boolean;
  rate: number;
  target: string;
  target_amount: number;
  uid: string;
}

export const ExchangeRateAlertPropTypes = PropTypes.exact({
  base: PropTypes.string.isRequired,
  base_amount: PropTypes.number.isRequired,
  create_dt: PropTypes.string.isRequired,
  is_alerted: PropTypes.bool.isRequired,
  rate: PropTypes.number.isRequired,
  target: PropTypes.string.isRequired,
  target_amount: PropTypes.number.isRequired,
  uid: PropTypes.string.isRequired,
});

export type Notice = {
  uid: string;
  create_dt: string;
  kind: string;
  is_read: boolean;
  data?: any;
}

export const NoticePropTypes = PropTypes.exact({
  uid: PropTypes.string.isRequired,
  create_dt: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  is_read: PropTypes.bool.isRequired,
  data: PropTypes.any,
});
