import styled from 'styled-components';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export default Field;
