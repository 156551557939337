import styled from 'styled-components';

const Input = styled.input`
  display: flex;
  align-items: center;
  height: 48px;
  background: #E5EFF5;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  font-size: 14px;
  padding: 0 16px;

  &:disabled {
    background: #E8E9EA;
  }

  &::placeholder {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #1B262C;
    opacity: 0.3;
  }
`;

export default Input;
