import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import Modal from './Modal';
import ModalHeader from './ModalHeader';

const StyledModalHeader = styled(ModalHeader)`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Body = styled.div`
  position: relative;
  padding: 20px;
  background: #f1f1f1;
`;

interface IProps {
  isOpen: boolean;
  onRequestClose: () => any;
  title: string;
  children: React.ReactNode;
}

const TitledModal: React.FC<IProps> = ({
  isOpen,
  onRequestClose,
  title,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <StyledModalHeader
        title={title}
        onClose={onRequestClose}
      />
      <Body>
        {children}
      </Body>
    </Modal>
  );
};

TitledModal.defaultProps = {};

TitledModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TitledModal;
