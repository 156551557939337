import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Note, NotePropTypes } from '../../dataTypes';
import callThumbnailImg from './images/thumbnail-call.svg';
import emailThumbnailImg from './images/thumbnail-email.svg';
import meetingThumbnailImg from './images/thumbnail-meeting.svg';
import othersThumbnailImg from './images/thumbnail-others.svg';
import { getLocalizedName } from '../../util/i18n';
import Dot from '../common/Dot';
import creatorImg from '../../images/edit.svg';
import clockImg from '../../images/clock.svg';
import lockImg from './images/lock.svg';
import editImg from './images/edit.svg';
import deleteImg from './images/delete.svg';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import useDeleteCallingNoteAPI from '../../hooks/api/calling-notes/useDeleteCallingNoteAPI';
import AddOrEditNoteModal from './AddOrEditNoteModal';
import Card from '../common/Card';
import CompanyJobTitle from '../common/CompanyJobTitle';
import { getLineClampStyles } from '../../util/styles';

const Container = styled(Card)`
  padding: 12px 20px;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled(Row)``;

const ClientBody = styled.div`
  flex: 1;
`;

const Name = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
`;

const Thumbnail = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
`;

const CallThumbnail = styled(Thumbnail)`
  background-image: url(${callThumbnailImg});
`;

const EmailThumbnail = styled(Thumbnail)`
  background-image: url(${emailThumbnailImg});
`;

const MeetingThumbnail = styled(Thumbnail)`
  background-image: url(${meetingThumbnailImg});
`;

const OthersThumbnail = styled(Thumbnail)`
  background-image: url(${othersThumbnailImg});
`;

const ClientCompanyJobTitle = styled(CompanyJobTitle)`
  color: #1B262C;
  opacity: 0.6;
`;

const EditButton = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
  background: center/contain no-repeat url(${editImg});
`;

const DeleteButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: center/contain no-repeat url(${deleteImg});
`;

const SummaryContainer = styled.div``;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
  margin-bottom: 4px;
`;

const Summary = styled.div<{ maxLines?: number }>`
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
  opacity: 0.6;
  overflow-wrap: anywhere;
  ${(props) => props.maxLines && getLineClampStyles(props.maxLines)}

  p {
    margin: 0;
  }
`;

const SeeMore = styled.div`
  text-align: right;
  color: #01AAC1;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
`;

const TagsContainer = styled(Row)`
  flex-wrap: wrap;
`;

const Tag = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  color: #00649F;
  &:not(:last-child) {
    margin-right: 0.3em;
  }
`;

const OwnerContainer = styled.div``;

const Description = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
  opacity: 0.6;
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

const CreatorIcon = styled(Icon)`
  background: center/contain no-repeat url(${creatorImg});
`;

const ClockIcon = styled(Icon)`
  background: center/contain no-repeat url(${clockImg});
`;
const LockIcon = styled(Icon)`
  background: center/contain no-repeat url(${lockImg});
`;

const Private = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  color: #00649F;
  opacity: 0.6;
`;

interface IProps {
  className?: string;
  note: Note;
  maxLines?: number;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showOwner?: boolean;
  showTags?: boolean;
  // eslint-disable-next-line no-unused-vars
  onDelete?: (note: Note) => any;
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: React.MouseEvent) => any;
}

const SummaryCard: React.FC<IProps> = ({
  className,
  note,
  maxLines,
  showEditButton,
  showDeleteButton,
  showOwner,
  showTags,
  onDelete,
  onClick,
}) => {
  const {
    activity_type: activityType,
    client_name: clientName,
    contact_first_name: contactFirstName,
    contact_last_name: contactLastName,
    contact_job_title: contactJobTitle,
    due_dt: dueDt,
    is_public: isPublic,
    owner_first_name: ownerFirstName,
    owner_last_name: ownerLastName,
    owner_job_title: ownerJobTitle,
    tags,
    summary,
    uid,
  } = note;

  const { t } = useTranslation();

  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const { mutateAsync: deleteCallingNoteAPI } = useDeleteCallingNoteAPI(uid);
  const { enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  const contactName = getLocalizedName(contactFirstName, contactLastName);

  return (
    <>
      <Container className={className} onClick={onClick}>
        <Header>
          {activityType === 'call' && <CallThumbnail />}
          {activityType === 'email' && <EmailThumbnail />}
          {activityType === 'meeting' && <MeetingThumbnail />}
          {activityType === 'others' && <OthersThumbnail />}
          <ClientBody>
            <Row>
              <Name>{contactName || clientName}</Name>
            </Row>
            <ClientCompanyJobTitle companyName={clientName} jobTitle={contactJobTitle} />
          </ClientBody>
          { showEditButton && <EditButton onClick={() => setIsEditModalOpen(true)} /> }
          { showDeleteButton && (
            <DeleteButton
              onClick={async () => {
                try {
                // TODO: use a better confirm modal
                  const result = window.confirm(t('common.delete_confirm'));
                  if (result) {
                    await deleteCallingNoteAPI();
                    onDelete(note);
                  }
                } catch (err) {
                  enqueueGeneralErrorSnackbar();
                  throw err;
                }
              }}
            />
          )}
        </Header>

        <SummaryContainer>
          <SubTitle>
            {t('common.summary')}
          </SubTitle>
          <Summary maxLines={maxLines}>
            {
              summary.split('\n').map((p, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={i}>
                  {p}
                </p>
              ))
            }
          </Summary>
          {maxLines && <SeeMore>{t('common.show_more')}</SeeMore>}
        </SummaryContainer>

        { (showTags && tags?.length > 0) && (
          <TagsContainer>
            {tags && tags.map((tag) => <Tag key={tag} to={`/search?q=${encodeURIComponent(tag)}`}>{tag}</Tag>)}
          </TagsContainer>
        )}

        { showOwner && (
          <OwnerContainer>
            <SubTitle>
              {t('common.owner')}
            </SubTitle>
            <Description>
              <CreatorIcon />
              {getLocalizedName(ownerFirstName, ownerLastName)}
              <Dot />
              {ownerJobTitle}
            </Description>
            <Description>
              <ClockIcon />
              {dayjs(dueDt).format(t('format.datetime.llll'))}
            </Description>
            {!isPublic && (
              <Private>
                <LockIcon />
                {t('notes.modal.private_desc')}
              </Private>
            )}
          </OwnerContainer>
        )}
      </Container>
      <AddOrEditNoteModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        note={note}
      />
    </>
  );
};

SummaryCard.defaultProps = {
  className: null,
  maxLines: null,
  showEditButton: true,
  showDeleteButton: true,
  showOwner: true,
  showTags: true,
  onDelete: null,
  onClick: null,
};

SummaryCard.propTypes = {
  className: PropTypes.string,
  note: NotePropTypes.isRequired,
  maxLines: PropTypes.number,
  showEditButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showOwner: PropTypes.bool,
  showTags: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

export default SummaryCard;
