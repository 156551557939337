import * as React from 'react';
import * as PropTypes from 'prop-types';
import InsightListView from '../insight/InsightListView';
import useGetNewsfeedsAPI from '../../hooks/api/clients/useGetNewsfeedsAPI';
import { getArrayFromInfiniteData } from '../../util/api';

type Props = {
  className?: string;
  uid: string;
}

const NewsfeedList: React.FC<Props> = ({
  className,
  uid,
}) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useGetNewsfeedsAPI(uid);

  const insights = getArrayFromInfiniteData(data);

  return (
    <InsightListView
      className={className}
      insights={insights}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
    />
  );
};

NewsfeedList.defaultProps = {
  className: null,
};

NewsfeedList.propTypes = {
  className: PropTypes.string,
  uid: PropTypes.string.isRequired,
};

export default NewsfeedList;
