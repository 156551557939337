export const getCardStyles = () => `
  background: #fff;
  box-shadow: 0px 3px 5px rgba(0, 100, 159, 0.08);
  border-radius: 10px;
`;

export const getCardHoverStyles = () => `
  background: rgba(1, 170, 193, 0.12);
`;

export const getLineClampStyles = (maxLines: number) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
`;
