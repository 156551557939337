import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getArrayFromInfiniteData } from '../../util/api';
import BusinessInsightCard from './BusinessInsightCard';
import useGetInsightsAPI from '../../hooks/api/insight/useGetInsightsAPI';
import InfiniteScroll from '../common/InfiniteScroll';

type Props = {
  className?: string;
}

const Title = styled.h1`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  margin-bottom: 12px;
`;

const StyledBusinessInsightCard = styled(BusinessInsightCard)`
  margin-bottom: 12px;
`;

const BusinessInsightList: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useGetInsightsAPI({ mode: 'business' });

  const businessInsights = getArrayFromInfiniteData(data);

  return (
    <InfiniteScroll
      className={className}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      isEmpty={businessInsights.length === 0}
    >
      <Title>{t('insight.business_insight_title')}</Title>
      {
        businessInsights.map((item) => {
          return (
            <StyledBusinessInsightCard
              key={item.uid}
              insight={item}
            />
          );
        })
      }
    </InfiniteScroll>
  );
};

BusinessInsightList.defaultProps = {
  className: null,
};

BusinessInsightList.propTypes = {
  className: PropTypes.string,
};

export default BusinessInsightList;
