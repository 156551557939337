import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const useGeneralSnackbar = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return {
    enqueueGeneralSuccessSnackbar() {
      enqueueSnackbar(t('common.success'), {
        variant: 'success',
        persist: false,
      });
    },
    enqueueGeneralErrorSnackbar() {
      enqueueSnackbar(t('common.general_error'), {
        variant: 'error',
        persist: false,
      });
    },
    enqueueSnackbar,
  };
};

export default useGeneralSnackbar;
