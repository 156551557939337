import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Insight, InsightPropTypes } from '../../dataTypes';
import { fromNow } from '../../util/datetime';
import SaveButton from '../common/SaveButton';
import InsightModal from './InsightModal';
import useClickNewsAPI from '../../hooks/api/insight/useClickNewsAPI';
import clockImg from '../../images/clock.svg';
import useToggleFavoriteNewsAPI from '../../hooks/api/favorites/useToggleFavoriteNewsAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import NonBlurryImage from '../common/NonBlurryImage';
import Card from '../common/Card';
import { getLineClampStyles } from '../../util/styles';

type Props = {
  className?: string;
  insight: Insight;
  showDescription?: boolean;
  descriptionMaxLines?: number;
  onImageHidden?: () => void;
}

const Container = styled(Card)`
  background: #FFFFFF;
  border-radius: 10px;
  padding: 12px 20px;
  cursor: pointer;
  &:hover {
    background: rgba(1, 170, 193, 0.1);
  }
`;

const Header = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
  opacity: 0.6;
  margin-bottom: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const COVER_IMAGE_HEIGHT = 80;

const ThumbnailImg = styled(NonBlurryImage)`
  width: 100%;
  height: ${COVER_IMAGE_HEIGHT}px;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  object-fit: cover;
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  margin: 0;
  ${getLineClampStyles(2)}
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Description = styled.p<{maxLines: number}>`
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
  opacity: 0.6;
  margin: 0;
  ${(props) => getLineClampStyles(props.maxLines)}
  margin-bottom: 8px;
`;

const Footer = styled.div`
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TimestampContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ClockImg = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${clockImg});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 4px;
`;

const Timestamp = styled.div`
  color: #1B262C;
  opacity: 0.6;
`;

const BusinessInsightCard: React.FC<Props> = ({
  className,
  insight,
  showDescription,
  descriptionMaxLines,
  onImageHidden,
}) => {
  const {
    category,
    img_url: imgUrl,
    is_saved: isSaved,
    provider,
    published_dt: publishedDt,
    summary,
    title,
    uid,
  } = insight;

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { mutateAsync: toggleFavoriteNewsAPI } = useToggleFavoriteNewsAPI(uid, !insight.is_saved);
  const { enqueueGeneralErrorSnackbar } = useGeneralSnackbar();
  const clickNews = useClickNewsAPI();

  const onCardClick = () => {
    clickNews(insight);
    setIsModalOpen(true);
  };

  const onSaveButtonClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    try {
      await toggleFavoriteNewsAPI();
    } catch (err) {
      enqueueGeneralErrorSnackbar();
      throw err;
    }
  };

  return (
    <>
      <Container onClick={onCardClick} className={className}>
        <Header>
          {provider}
          ・
          {t(`insight.topics.${category}`)}
        </Header>
        <Body>
          <ThumbnailImg
            src={imgUrl}
            alt={title}
            minNaturalWidth={326}
            minNaturalHeight={COVER_IMAGE_HEIGHT}
            onImageHidden={onImageHidden}
          />
          <TextContainer>
            <Title>{title}</Title>
            {showDescription && <Description maxLines={descriptionMaxLines}>{summary}</Description>}
          </TextContainer>
        </Body>
        <Footer>
          <TimestampContainer>
            <ClockImg />
            <Timestamp>{fromNow(publishedDt, t)}</Timestamp>
          </TimestampContainer>
          <SaveButton isSaved={isSaved} onClick={onSaveButtonClick} />
        </Footer>
      </Container>
      <InsightModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        insight={insight}
        onSaveButtonClick={onSaveButtonClick}
      />
    </>
  );
};

BusinessInsightCard.defaultProps = {
  className: null,
  showDescription: false,
  descriptionMaxLines: 3,
  onImageHidden: null,
};

BusinessInsightCard.propTypes = {
  className: PropTypes.string,
  insight: InsightPropTypes.isRequired,
  showDescription: PropTypes.bool,
  descriptionMaxLines: PropTypes.number,
  onImageHidden: PropTypes.func,
};

export default BusinessInsightCard;
