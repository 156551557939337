import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  margin: 0 auto;
  padding: 32px 0;
`;

const Dot = styled.div`
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-out;
  border-radius: 50%;
  margin-right: 4px;
`;

const Dot1 = styled(Dot)`
  @keyframes anim1 {
    from {
      width: 24px;
      height: 24px;
      background: #01AAC1;
    }
    to {
      width: 4px;
      height: 4px;
      background: rgba(1, 170, 193, 0.1);
    }
  }
  animation-name: anim1;
`;

const Dot2 = styled(Dot)`
  @keyframes anim2 {
    from {
      width: 4px;
      height: 4px;
      background: rgba(1, 170, 193, 0.1);
    }
    to {
      width: 24px;
      height: 24px;
      background: #01AAC1;
    }
  }
  animation-name: anim2;
`;

interface IProps {
  className?: string;
}

const Loading: React.FC<IProps> = ({
  className,
}) => {
  return (
    <Container className={className}>
      <Dot1 />
      <Dot2 />
    </Container>
  );
};

Loading.defaultProps = {
  className: null,
};

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;
