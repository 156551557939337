import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RankBy, { RankByItem } from '../common/RankBy';
import editImg from './images/rank-by-edit.svg';

interface IProps {
  className?: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => any
}

const ItemIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const NoteRankBy: React.FC<IProps> = ({
  className,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <RankBy
      className={className}
      value={value}
      onChange={onChange}
    >
      <RankByItem
        value="create_dt_dsc"
        name={t('common.create_dt_dsc')}
      >
        <ItemIcon src={editImg} role="presentation" />
        {t('common.create_dt_dsc')}
      </RankByItem>

      <RankByItem
        value="create_dt_asc"
        name={t('common.create_dt_asc')}
      >
        <ItemIcon src={editImg} role="presentation" />
        {t('common.create_dt_asc')}
      </RankByItem>
    </RankBy>
  );
};

NoteRankBy.defaultProps = {
  className: null,
};

NoteRankBy.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NoteRankBy;
