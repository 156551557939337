import { useQuery } from 'react-query';
import api from '../../../util/api';

interface Options {
  enabled?: boolean;
  retry?: boolean;
}

const useGetClientAPI = (uid: string, options: Options) => {
  const fetchFunction = async () => {
    const res = await api.get(`/clients/${uid}`);
    return {
      ...res.data,
    };
  };

  return useQuery(
    ['clients', uid],
    fetchFunction,
    options,
  );
};

export default useGetClientAPI;
