import * as React from 'react';
import * as PropTypes from 'prop-types';
import useClientsAutoCompleteAPI from '../../../hooks/api/clients/useClientsAutoCompleteAPI';
import { getArrayFromInfiniteData } from '../../../util/api';
import { CompanySuggestion } from '../../../dataTypes';
import AutoCompleteInput from './AutoCompleteInput';

interface IProps {
  className?: string;
  src: 'client' | 'contact';
  searchInput: string;
  // eslint-disable-next-line no-unused-vars
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  // eslint-disable-next-line no-unused-vars
  onSelect: (item?: CompanySuggestion) => any;
  // eslint-disable-next-line no-unused-vars
  onDismiss?: (e: MouseEvent) => any;
}

const AutoCompleteCompanyInput: React.FC<IProps> = ({
  className,
  src,
  searchInput,
  onInputChange,
  onSelect,
  onDismiss,
}) => {
  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useClientsAutoCompleteAPI({ q: searchInput, src }, { enabled: Boolean(searchInput) });

  const items = getArrayFromInfiniteData(data);

  return (
    <AutoCompleteInput
      className={className}
      inputValue={searchInput}
      onInputChange={onInputChange}
      onSelect={onSelect}
      onDismiss={onDismiss}
      items={items}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      keyExtractor={(item: CompanySuggestion) => item.client_uid || item.ent_uid}
      renderItem={(item: CompanySuggestion) => item.name}
    />
  );
};

AutoCompleteCompanyInput.defaultProps = {
  className: null,
  onDismiss: () => {},
};

AutoCompleteCompanyInput.propTypes = {
  className: PropTypes.string,
  src: PropTypes.oneOf(['client', 'contact'] as const).isRequired,
  searchInput: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
};

export default AutoCompleteCompanyInput;
