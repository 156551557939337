import { updateContactModal } from '../../slices/modalSlice';
import { useAppDispatch } from '../store';

const useOpenContactModal = () => {
  const dispatch = useAppDispatch();
  return (uid: string) => {
    dispatch(updateContactModal({ isOpen: true, uid }));
  };
};

export default useOpenContactModal;
