import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'normalize.css';
import './util/i18n';
import './util/datetime';
import App from './App';
import { store } from './store';
import { restoreAndRefreshAuthState } from './slices/authSlice';
import { initSocket } from './util/socket';

const REFRESH_AUTH_STATE_PERIOD = 10 * 60 * 1000;

(async () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://d88653cb49fa4f8297f8aecc69bab695@o715930.ingest.sentry.io/5921418',
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  setInterval(() => {
    store.dispatch(restoreAndRefreshAuthState());
  }, REFRESH_AUTH_STATE_PERIOD);

  await store.dispatch(restoreAndRefreshAuthState());
  initSocket();

  const root = document.getElementById('root');
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root,
  );
})();
