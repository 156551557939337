import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

const useDeleteNoticeAPI = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (uid: string) => api.delete(`/notices/${uid}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notices');
      },
    },
  );

  return mutation;
};

export default useDeleteNoticeAPI;
