import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useSearchAPI, { SrcType } from '../../hooks/api/search/useSearchAPI';
import useLoginRequired from '../../hooks/auth/useLoginRequired';
import useQuery from '../../hooks/useQuery';
import { HEADER_BAR_HEIGHT } from '../layouts/header-bar/HeaderBar';
import HeaderLayout from '../layouts/HeaderLayout';
import SearchInput from './SearchInput';
import SearchResultList from './SearchResultList';
import TabBar from './TabBar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSearchInput = styled(SearchInput)`
  margin-bottom: 12px;
`;

const Header = styled.header`
  position: sticky;
  top: ${HEADER_BAR_HEIGHT}px;
  background: #f1f1f1;
  z-index: 1;
`;

const StyledSearchResultList = styled(SearchResultList)`
  flex: 1;
  overflow-y: auto;
`;

const SearchScreen: React.FC = () => {
  useLoginRequired();

  const history = useHistory();
  const location = useLocation();

  const query = useQuery();
  const q = query.get('q') || '';
  const src = (query.get('src') || 'all') as SrcType;

  const [searchInput, setSearchInput] = React.useState(q || '');
  const { data: searchResult, status, refetch } = useSearchAPI({ q, src }, { enabled: Boolean(q) });

  return (
    <HeaderLayout>
      <Container>
        <Header>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const searchParams = new URLSearchParams(location.search);
              searchParams.set('q', searchInput);
              history.push({ ...location, search: searchParams.toString() });
            }}
          >
            <StyledSearchInput
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onCancel={() => setSearchInput('')}
            />
          </form>
          {q && <TabBar />}
        </Header>
        <StyledSearchResultList
          status={status}
          result={searchResult}
          onRetry={() => refetch()}
        />
      </Container>
    </HeaderLayout>
  );
};

export default SearchScreen;
