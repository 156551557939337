import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseErrorView from './BaseErrorView';
import iconImg from './images/empty.svg';

interface IProps {
  className?: string;
  message?: string;
}

const NoData: React.FC<IProps> = ({
  className,
  message,
}) => {
  const { t } = useTranslation();
  return (
    <BaseErrorView
      className={className}
      iconSrc={iconImg}
      message={message || t('common.no_result')}
    />
  );
};

NoData.defaultProps = {
  className: null,
  message: null,
};

NoData.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default NoData;
