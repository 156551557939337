import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { getAccessToken, getLogin } from '../slices/authSlice';
import { store } from '../store';
import queryClient from './queryClient';

let socket: Socket<DefaultEventsMap, DefaultEventsMap>;

export const initSocket = () => {
  if (socket) {
    throw new Error('Do not re-initialize the socket!');
  }

  socket = io('https://aisa.ingenta.ai');

  socket.on('connect', () => {
    const accessToken = getAccessToken(store.getState());
    const isLogin = getLogin(store.getState());
    if (accessToken && isLogin) {
      socket.emit('join', accessToken);
    }
  });

  socket.on('disconnect', (reason) => {
    console.log('Socket disconnected! Reason:', reason);
    // Ref: https://socket.io/docs/v4/client-socket-instance/#disconnect
    if (reason === 'io server disconnect' || reason === 'io client disconnect') {
      socket.connect();
    }
  });

  // socket.on('join', () => {
  //   console.log('Socket joined!');
  // });

  socket.on('notice', () => {
    // refresh notice list
    queryClient.invalidateQueries('notices');
  });
};

const getSocket = () => {
  return socket;
};

export default getSocket();
