import styled from 'styled-components';

const Textarea = styled.textarea`
  height: 104px;
  background: #E5EFF5;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  font-size: 14px;
  padding: 16px;
  resize: none;
`;

export default Textarea;
