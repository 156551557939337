import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import * as PropTypes from 'prop-types';
import { Contact, ContactPropTypes } from '../../dataTypes';
import { getLocalizedName } from '../../util/i18n';
import ContactModal from './ContactModal';
import MoreButton, { MoreButtonItem } from '../common/MoreButton';
import useQuery from '../../hooks/useQuery';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import useDeleteContactAPI from '../../hooks/api/contacts/useDeletContactAPI';
import HoverCard from '../common/HoverCard';
import UserThumbnail from '../common/UserThumbnail';
import CompanyJobTitle from '../common/CompanyJobTitle';
import { getLineClampStyles } from '../../util/styles';

interface Param {
  className?: string;
  contact: Contact;
}

const Container = styled(HoverCard)`
  display: flex;
  padding: 12px 20px;
  cursor: pointer;
`;

const StyledUserThumbnail = styled(UserThumbnail)`
  margin-right: 12px;
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Name = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  ${getLineClampStyles(2)}
`;

const LastTouch = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
  opacity: 0.6;
`;

const ContactCard: React.FC<Param> = ({
  className,
  contact,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
    job_title: jobTitle,
    last_touch_dt: lastTouchDt,
    bizcard_front_url: bizcardFrontUrl,
    uid,
  } = contact;

  const query = useQuery();
  const { mutateAsync: deleteContactAPI } = useDeleteContactAPI(uid);
  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  const onDeleteClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const result = window.confirm(t('common.delete_confirm'));
    if (result) {
      try {
        await deleteContactAPI();
        enqueueGeneralSuccessSnackbar();
      } catch (err) {
        enqueueGeneralErrorSnackbar();
        throw err;
      }
    }
  };

  return (
    <>
      <Container onClick={() => setIsModalOpen(true)} className={className}>
        <StyledUserThumbnail url={bizcardFrontUrl} />
        <TextContainer>
          <Name>{getLocalizedName(firstName, lastName)}</Name>
          <CompanyJobTitle companyName={companyName} jobTitle={jobTitle} />
          <LastTouch>{t('common.last_touch', { time: dayjs(lastTouchDt).calendar() })}</LastTouch>
        </TextContainer>
        {
          query.get('mode') === 'admin' && (
            <MoreButton>
              <MoreButtonItem onClick={onDeleteClick}>{t('common.delete')}</MoreButtonItem>
            </MoreButton>
          )
        }
      </Container>
      <ContactModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contact={contact}
      />
    </>
  );
};

ContactCard.defaultProps = {
  className: null,
};

ContactCard.propTypes = {
  className: PropTypes.string,
  contact: ContactPropTypes.isRequired,
};

export default ContactCard;
