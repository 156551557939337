import * as React from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import useGetClientIndustryDistAPI from '../../hooks/api/clients/useGetClientIndustryDistAPI';
import CompanyIndustryDistPieChart from './CompanyIndustryDistPieChart';
import Card from '../common/Card';
import Loading from '../common/status/Loading';
import NoData from '../common/status/NoData';
import GeneralError from '../common/status/GeneralError';
import CompanyIndustryDistItem from './CompanyIndustryDistItem';
import CompanyIndustryDistModal from './CompanyIndustryDistModal';

const Container = styled(Card)``;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  height: 32px;
  color: #1B262C;
`;

const Body = styled.div`
  padding: 12px 10px;
`;

const CompanyIndustryDistWidget: React.FC = () => {
  const { t } = useTranslation();
  const { data, status, refetch } = useGetClientIndustryDistAPI();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // Sort by percentage, make empty industry the last
  const sortedItems = (data?.items || [])
    .sort((a, b) => {
      if (a.industry === '') return 1;
      if (b.industry === '') return -1;
      return (b.dist_percent - a.dist_percent);
    });

  // set the color scale; only the largest 4 have their own colors; the rests are gray
  const color = d3.scaleOrdinal<string, string>()
    .domain(sortedItems
      .filter((item) => item.industry !== '')
      .slice(0, 4)
      .map((item) => item.industry))
    .range(['#F2994A', '#27AE60', '#BB6BD9', '#2F80ED'])
    .unknown('#E0E0E0');

  return (
    <>
      <Container>
        <Title>
          {t('home.company_industry_dist.title')}
        </Title>
        {data?.items?.length > 0 && (
          <CompanyIndustryDistPieChart
            data={sortedItems}
            color={color}
          />
        )}
        <Body>
          {sortedItems.slice(0, 3).map((item) => {
            return (
              <CompanyIndustryDistItem
                key={item.industry}
                name={item.industry || t('common.others')}
                percentage={item.dist_percent}
                color={color(item.industry)}
                onClick={() => setIsModalOpen(true)}
              />
            );
          })}
          { status === 'loading' && <Loading /> }
          { (status === 'success' && !data?.items?.length) && <NoData /> }
          { status === 'error' && <GeneralError onButtonClick={() => refetch()} />}
        </Body>
      </Container>
      <CompanyIndustryDistModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        data={sortedItems}
        color={color}
      />
    </>
  );
};

CompanyIndustryDistWidget.defaultProps = {};

CompanyIndustryDistWidget.propTypes = {};

export default CompanyIndustryDistWidget;
