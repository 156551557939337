import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

const useDeleteContactAPI = (uid: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => api.delete(`/contacts/${uid}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
      },
    },
  );

  return mutation;
};

export default useDeleteContactAPI;
