import styled from 'styled-components';

const LinkButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  color: #00649F;
  cursor: pointer;
  padding: 0;
`;

export default LinkButton;
