import * as React from 'react';
import styled from 'styled-components';
import useGetFavoriteCorpsAPI from '../../hooks/api/favorites/useGetFavoriteCorpsAPI';
import { getArrayFromInfiniteData } from '../../util/api';
import InfiniteScroll from '../common/InfiniteScroll';
import LeadCard from '../company/LeadCard';

const StyledLeadCard = styled(LeadCard)`
  margin-bottom: 12px;
`;

const SavedCorpList: React.FC = () => {
  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useGetFavoriteCorpsAPI();

  const corps = getArrayFromInfiniteData(data);

  return (
    <InfiniteScroll
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      isEmpty={corps.length === 0}
      observeBrowserViewport
    >
      {
          corps.map((corp) => {
            const client = { ...corp, logo: corp.photo_url };
            return (
              <StyledLeadCard key={corp.uid} lead={client} />
            );
          })
        }
    </InfiniteScroll>
  );
};

export default SavedCorpList;
