import styled from 'styled-components';

const Submit = styled.input`
  width: 100%;
  height: 40px;
  background: #00649F;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  border: none;
  cursor: pointer;
`;

export default Submit;
