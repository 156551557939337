import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { ClientIndustryDistribution } from '../../dataTypes';
import { SIDE_COLUMN_WIDTH } from '../layouts/HeaderLayout';

type Props = {
  data?: ClientIndustryDistribution[];
  color: d3.ScaleOrdinal<string, string, string>;
}

const CompanyIndustryDistPieChart: React.FC<Props> = ({
  data,
  color,
}) => {
  const { t } = useTranslation();

  const draw = React.useCallback(() => {
    // draw at most 5 industries; group the rest into one industry
    const restPercentage = data.slice(4).reduce((acc, cur) => { return acc + cur.dist_percent; }, 0);
    const displayData = (restPercentage > 0) ? [...data.slice(0, 4), { industry: t('common.others'), dist_percent: restPercentage }] : data;

    // set the dimensions and margins of the graph
    const width = SIDE_COLUMN_WIDTH;
    const height = 228;
    const margin = 32;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(width, height) / 2 - margin;

    const svg = d3.select('svg#company-industry-distribution')
      .attr('width', width)
      .attr('height', height)
      .style('background', '#F2F2F2');

    if (data.length === 0) return;

    // Reset the graph
    svg.selectAll('svg > *').remove();

    // Center
    const g = svg
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Compute the position of each group on the pie:
    const pieGenerator = d3.pie<ClientIndustryDistribution>()
      .value((d) => { return d.dist_percent; });
    const arcData = pieGenerator(displayData);

    // For drawing the pie chart
    const arcGenerator = d3.arc<d3.PieArcDatum<ClientIndustryDistribution>>()
      .innerRadius(0)
      .outerRadius(radius);

    // For drawing polylines and text labels
    const outerArcGenerator = d3.arc<d3.PieArcDatum<ClientIndustryDistribution>>()
      .innerRadius(radius * 1.1)
      .outerRadius(radius * 1.1);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    g.selectAll('path')
      .data(arcData)
      .join('path')
      .attr('d', arcGenerator)
      .attr('fill', (d) => { return color(d.data.industry); });

    // Text label
    function midAngle(d: d3.PieArcDatum<ClientIndustryDistribution>) {
      return d.startAngle + (d.endAngle - d.startAngle) / 2;
    }

    g.append('g')
      .attr('class', 'labels');

    g.select('.labels')
      .selectAll('text')
      .data(arcData)
      .join('text')
      .attr('dy', '.35em')
      .text((d) => `${Math.round(d.data.dist_percent * 100)}%`)
      .attr('transform', (d) => {
        const pos = outerArcGenerator.centroid(d);
        pos[0] = radius * 1.2 * (midAngle(d) < Math.PI ? 1 : -1);
        return `translate(${pos})`;
      })
      .style('text-anchor', (d) => {
        return midAngle(d) < Math.PI ? 'start' : 'end';
      })
      .style('font-size', '13px')
      .style('color', '#1B262C');

    /* ------- SLICE TO TEXT POLYLINES -------*/
    g.append('g')
      .attr('class', 'lines');

    g.select('.lines')
      .selectAll('polyline')
      .data(arcData)
      .join('polyline')
      .attr('points', (d) => {
        const pos = outerArcGenerator.centroid(d);
        pos[0] = radius * 1.15 * (midAngle(d) < Math.PI ? 1 : -1);
        return `${arcGenerator.centroid(d)},${outerArcGenerator.centroid(d)},${pos}`;
      })
      .style('stroke', '#1B262C')
      .style('stroke-width', '1px')
      .style('fill', 'none');
  }, [color, data, t]);

  React.useEffect(() => {
    draw();
  }, [draw]);

  return (
    <svg id="company-industry-distribution" />
  );
};

CompanyIndustryDistPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.exact({
    industry: PropTypes.string.isRequired,
    dist_percent: PropTypes.number.isRequired,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  color: PropTypes.any.isRequired,
};

export default CompanyIndustryDistPieChart;
