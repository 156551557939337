/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MeState {
  firstName: string;
  lastName: string,
  username: string,
  email: string,
}

const initialState: MeState = {
  firstName: null,
  lastName: null,
  username: null,
  email: null,
};

export const slice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<MeState>) => {
      const {
        firstName, lastName, username, email,
      } = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
      state.username = username;
      state.email = email;
    },
  },
});

export const { update } = slice.actions;

export default slice.reducer;
