import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import BaseNotice from './BaseNotice';
import contactImg from './images/contact.svg';
import { Notice, NoticePropTypes } from '../../dataTypes';
import { getLocalizedName } from '../../util/i18n';
import useOpenCompanyModal from '../../hooks/modals/useOpenCompanyModal';
import LinkButton from '../common/LinkButton';
import useReadNoticeAPI from '../../hooks/api/notices/useReadNoticeAPI';

type Props = {
  className?: string;
  notice: Notice;
};

const NewClientNotice: React.FC<Props> = ({
  className,
  notice,
}) => {
  const { producer, company } = notice.data;
  const openCompanyModal = useOpenCompanyModal();
  const { mutateAsync: readNoticeAPI } = useReadNoticeAPI();

  const onClick = async () => {
    readNoticeAPI({ uid: notice.uid });
    openCompanyModal(company.uid);
  };

  const producerName = getLocalizedName(producer.first_name, producer.last_name);
  const companyName = company.name;

  return (
    <BaseNotice
      className={className}
      uid={notice.uid}
      iconSrc={contactImg}
      timestamp={notice.create_dt}
      onClick={onClick}
    >
      <Trans i18nKey="notices.add_company">
        <LinkButton>{{ producerName }}</LinkButton>
        {' '}
        just connects with
        {' '}
        <LinkButton>{{ companyName }}</LinkButton>
      </Trans>
    </BaseNotice>
  );
};

NewClientNotice.defaultProps = {
  className: null,
};

NewClientNotice.propTypes = {
  className: PropTypes.string,
  notice: NoticePropTypes.isRequired,
};

export default NewClientNotice;
