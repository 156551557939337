import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import Submit from './Submit';

const StyledModalHeader = styled(ModalHeader)`
  position: sticky;
  top: 0;
`;

const Body = styled.div`
  padding: 20px;
  background: #F6F6F6;
`;

interface IProps {
  isOpen: boolean;
  onRequestClose: () => any;
  onSubmit: () => any;
  title: string;
  children: React.ReactNode;
}

const FormModal: React.FC<IProps> = ({
  isOpen,
  onRequestClose,
  onSubmit,
  title,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <StyledModalHeader title={title} onClose={onRequestClose} />
      <form onSubmit={onSubmit}>
        <Body>
          {children}
        </Body>
        <Submit type="submit" value={t('common.save') as string} />
      </form>
    </Modal>
  );
};

FormModal.defaultProps = {};

FormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FormModal;
