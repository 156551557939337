import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as calendar from 'dayjs/plugin/calendar';
import 'dayjs/locale/ja';

dayjs.extend(relativeTime);
dayjs.extend(calendar);
// dayjs.locale('ja'); // For testing ja strings

export const fromNow = (timestamp: string, t: any) => {
  const time = dayjs(timestamp);
  const today = dayjs().hour(0).minute(0).second(0);
  const thisYear = dayjs().month(0).date(1).hour(0)
    .minute(0)
    .second(0);

  if (time.diff(today) > 0) {
    return time.fromNow();
  } if (time.diff(thisYear) < 0) {
    return time.format(t('format.date.long'));
  }

  return time.format(t('format.date.short'));
};

export const toDatetimeLocalFormat = (ISOStr?: string): string => {
  if (!ISOStr) return '';

  const date = new Date(ISOStr);
  const timezoneOffset = (new Date()).getTimezoneOffset() * 60000; // in milliseconds
  const localDate = new Date(date.getTime() - timezoneOffset);
  return localDate.toISOString().substr(0, 16); // Remove trailing characters
};
