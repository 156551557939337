import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { Corp } from '../../../dataTypes';
import api, { PageData } from '../../../util/api';

const useToggleFavoriteCorpAPI = (uid: string, save: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      if (save) {
        return api.post('/favorites/corps', { uid });
      }
      return api.delete(`/favorites/corps/${uid}`);
    }, {
      onMutate: async () => {
        await queryClient.cancelQueries('favorites/corps');

        const previousNewsList = queryClient.getQueryData('favorites/corps');

        // optimistic updates
        queryClient.setQueryData<InfiniteData<PageData<Corp>>>('favorites/corps', (oldData) => {
          if (!oldData) return undefined;

          return {
            ...oldData,
            pages: oldData.pages
              .map((page) => {
                return {
                  ...page,
                  items: page.items.map((item) => {
                    return item.uid === uid ? { ...item, is_saved: save } : item;
                  }),
                };
              }),
          };
        });

        return { previousNewsList };
      },

      onError: (err, news, context) => {
        queryClient.setQueryData('favorites/corps', context.previousNewsList);
      },

      onSettled: () => {
        queryClient.invalidateQueries('leads');
        // NOTE: To keep saved list UI undoable, do not invalidate 'favorites/corps' right away
      },
    },
  );
};

export default useToggleFavoriteCorpAPI;
