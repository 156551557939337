import styled from 'styled-components';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import logoImg from '../../images/logo-white.svg';
import aisaImg from '../../images/aisa-white.svg';
import collapseImg from './images/collapse.svg';
import expandImg from './images/expand.svg';
import homeImg from './images/home.svg';
import eventImg from './images/event.svg';
import insightImg from './images/insight.svg';
import noteImg from './images/note.svg';
import companyImg from './images/company.svg';
import contactImg from './images/contact.svg';

const NAV_WIDTH_EXPANDED = 222;
const NAV_WIDTH_COLLAPSED = 132;

const Container = styled(animated.nav)`
  background: linear-gradient(180deg, #01AAC1 33.55%, #FFFFFF 100%);
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 24px 44px;
`;

const LogoLinkContainer = styled(animated.div)``;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 6px 5px;
`;

const Logo = styled.div`
  margin-right: 6px;
  width: 32px;
  height: 34px;
  background: center/contain no-repeat url(${logoImg});
  flex-shrink: 0;
`;

const AiSA = styled(animated.div)`
  width: 50px;
  height: 16px;
  background: center left/cover no-repeat url(${aisaImg});
`;

const Button = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ExpandButton = styled(Button)`
  background: center/contain no-repeat url(${expandImg});
`;

const CollapseButton = styled(Button)`
  background: center/contain no-repeat url(${collapseImg});
`;

const StyledLink = styled(NavLink)`
  height: 56px;
  padding: 0 20px;
  margin-left: 30px;
  border-radius: 30px 0px 0px 30px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;

  &.active {
    background: rgba(255, 255, 255, 0.2);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const Icon = styled.div`
  width: 26px;
  height: 26px;
  margin-right: 16px;
  flex-shrink: 0;
`;

const HomeIcon = styled(Icon)`
  background: center/contain no-repeat url(${homeImg});
`;

const EventIcon = styled(Icon)`
  background: center/contain no-repeat url(${eventImg});
`;

const InsightIcon = styled(Icon)`
  background: center/contain no-repeat url(${insightImg});
`;

const NoteIcon = styled(Icon)`
  background: center/contain no-repeat url(${noteImg});
`;

const CompanyIcon = styled(Icon)`
  background: center/contain no-repeat url(${companyImg});
`;

const ContactIcon = styled(Icon)`
  background: center/contain no-repeat url(${contactImg});
`;

const LinkText = styled(animated.div)``;

type Props = {
  className?: string;
}

const Nav: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(true);

  const containerProps = useSpring({
    width: expanded ? NAV_WIDTH_EXPANDED : NAV_WIDTH_COLLAPSED,
    borderTopRightRadius: expanded ? 100 : 50,
  });

  const logoProps = useSpring({
    width: expanded ? 111 : 48,
  });

  const textProps = useSpring({
    opacity: expanded ? 1 : 0,
  });

  return (
    <Container className={className} style={containerProps}>
      <LogoContainer>
        <LogoLinkContainer style={logoProps}>
          <LogoLink to="/">
            <Logo />
            <AiSA style={textProps} />
          </LogoLink>
        </LogoLinkContainer>
        { expanded
          ? <CollapseButton onClick={() => setExpanded(false)} />
          : <ExpandButton onClick={() => setExpanded(true)} />}
      </LogoContainer>

      <StyledLink to="/" exact>
        <HomeIcon />
        <LinkText style={textProps}>
          {t('common.home')}
        </LinkText>
      </StyledLink>

      {/* <StyledLink to="/events">
        <EventIcon />
        <LinkText style={textProps}>
          {t('common.events')}
        </LinkText>
      </StyledLink> */}

      <StyledLink to="/insight">
        <InsightIcon />
        <LinkText style={textProps}>
          {t('common.insight')}
        </LinkText>
      </StyledLink>

      <StyledLink to="/notes">
        <NoteIcon />
        <LinkText style={textProps}>
          {t('common.notes')}
        </LinkText>
      </StyledLink>

      <StyledLink to="/companies">
        <CompanyIcon />
        <LinkText style={textProps}>
          {t('common.companies')}
        </LinkText>
      </StyledLink>
      <StyledLink to="/contacts">
        <ContactIcon />
        <LinkText style={textProps}>
          {t('common.contacts')}
        </LinkText>
      </StyledLink>
    </Container>
  );
};

Nav.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Nav;
