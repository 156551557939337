import { useQuery } from 'react-query';
import { Profile } from '../../../dataTypes';
import api from '../../../util/api';

const useGetMyProfileAPI = () => {
  const fetchFunction = async () => {
    const res = await api.get('/profile/me');
    return {
      ...res.data,
    };
  };

  return useQuery<Profile>(
    'profile/me',
    fetchFunction,
  );
};

export default useGetMyProfileAPI;
