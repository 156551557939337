import { useMutation } from 'react-query';
import { Insight } from '../../../dataTypes';
import api from '../../../util/api';

const useClickNewsAPI = () => {
  const clickInsightMutation = useMutation(
    (newInsight: Insight) => {
      return api.post('/click/news', {
        news_uid: newInsight.uid,
        lang: newInsight.lang,
        reco_type: newInsight.reco_type,
        reco_uid: newInsight.reco_uid,
      });
    },
  );

  return clickInsightMutation.mutate;
};

export default useClickNewsAPI;
