import { useQuery } from 'react-query';
import { ContactGeoDistItems } from '../../../dataTypes';
import api from '../../../util/api';

const useGetContactGeoDistAPI = () => {
  const fetchFunction = async () => {
    const res = await api.get('/contacts/geo_dist');
    return {
      ...res.data,
    };
  };

  return useQuery<ContactGeoDistItems>(
    'contacts/geo_dist',
    fetchFunction,
  );
};

export default useGetContactGeoDistAPI;
