import * as React from 'react';
import styled from 'styled-components';
import Card from '../common/Card';
import { ProfilePropTypes, Profile } from '../../dataTypes';
import { getLocalizedName } from '../../util/i18n';
import Dot from '../common/Dot';
import ContactInfo from '../common/ContactInfo';
import BizcardAccordion from './BizcardAccordion';

const Container = styled(Card)`
  padding: 16px;
`;

const Name = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1B262C;
  margin: 0;
`;

const DepartmentJobTitle = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
  opacity: 0.6;
  margin-bottom: 12px;
`;

const StyledContactInfo = styled(ContactInfo)`
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Separator = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin-bottom: 12px;
`;

interface IProps {
  profile: Profile
}

const ProfileCard: React.FC<IProps> = ({
  profile,
}) => {
  if (!profile) return null;

  const {
    address,
    bizcard_back_url: bizcardBackUrl,
    bizcard_front_url: bizcardFrontUrl,
    company_name: companyName,
    department,
    first_name: firstName,
    job_title: jobTitle,
    last_name: lastName,
    mobile,
    reg_email: email,
    tel,
    work_email: workEmail,
  } = profile;

  return (
    <Container>
      <Name>{getLocalizedName(firstName, lastName)}</Name>
      <DepartmentJobTitle>
        {department ? (
          <>
            {department}
            <Dot />
            {jobTitle}
          </>
        ) : (
          jobTitle
        )}
      </DepartmentJobTitle>

      <StyledContactInfo email={email} address={address} mobile={mobile} tel={tel} workEmail={workEmail} company={companyName} />

      {
        (bizcardFrontUrl || bizcardBackUrl) && (
          <>
            <Separator />
            <BizcardAccordion bizcardFrontUrl={bizcardFrontUrl} bizcardBackUrl={bizcardBackUrl} />
          </>
        )
      }
    </Container>
  );
};

ProfileCard.defaultProps = {
  profile: null,
};

ProfileCard.propTypes = {
  profile: ProfilePropTypes,
};

export default ProfileCard;
