import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Card from '../common/Card';
import useGetContactGeoDistAPI from '../../hooks/api/contacts/useGetContactGeoDistAPI';
import ContactGeoDistMap from './ContactGeoDistMap';
import Loading from '../common/status/Loading';
import NoData from '../common/status/NoData';
import GeneralError from '../common/status/GeneralError';
import ContactGeoDistItem from './ContactGeoDistItem';
import ContactGeoDistModal from './ContactGeoDistModal';
import { SIDE_COLUMN_WIDTH } from '../layouts/HeaderLayout';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #1B262C;
`;

const Body = styled.div`
  padding: 12px;
`;

const ContactGeoDistWidget: React.FC = () => {
  const { t } = useTranslation();
  const { data: geoDist, status, refetch } = useGetContactGeoDistAPI();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const items = (geoDist && geoDist.items) ? geoDist.items : [];
  items.sort((a, b) => ((a.count === b.count) ? a.name.localeCompare(b.name) : (b.count - a.count)));

  return (
    <>
      <Container>
        <Title>
          {t('home.contact_geo_dist.title')}
        </Title>
        {
          geoDist?.items?.length > 0 && (
            <ContactGeoDistMap
              id="contact-geo-dist-widget"
              width={SIDE_COLUMN_WIDTH}
              height={157}
              data={items}
            />
          )
        }
        <Body>
          {
            items.slice(0, 3).map((item) => {
              return (
                <ContactGeoDistItem
                  key={item.code}
                  name={item.name}
                  count={item.count}
                  onClick={() => setIsModalOpen(true)}
                />
              );
            })
          }
          { status === 'loading' && <Loading /> }
          { (status === 'success' && !items.length) && <NoData /> }
          { status === 'error' && <GeneralError onButtonClick={() => refetch()} />}
        </Body>
      </Container>
      <ContactGeoDistModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        data={items}
      />
    </>
  );
};

ContactGeoDistWidget.defaultProps = {};

ContactGeoDistWidget.propTypes = {};

export default ContactGeoDistWidget;
