import * as React from 'react';
import * as PropTypes from 'prop-types';
import InsightListView from './InsightListView';
import useGetInsightsAPI from '../../hooks/api/insight/useGetInsightsAPI';
import { getArrayFromInfiniteData } from '../../util/api';

type Props = {
  className?: string;
}

const InsightList: React.FC<Props> = ({
  className,
}) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useGetInsightsAPI();

  const insights = getArrayFromInfiniteData(data);

  return (
    <InsightListView
      className={className}
      insights={insights}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      observeBrowserViewport
    />
  );
};

InsightList.defaultProps = {
  className: null,
};

InsightList.propTypes = {
  className: PropTypes.string,
};

export default InsightList;
