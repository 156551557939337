/* eslint-disable camelcase */
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

const useDeleteCallingNoteAPI = (uid: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => {
      return api.delete(`/calling_notes/${uid}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('calling_notes');
      },
    },
  );

  return mutation;
};

export default useDeleteCallingNoteAPI;
