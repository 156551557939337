import * as React from 'react';
import styled from 'styled-components';
import useGetFavoriteNewsAPI from '../../hooks/api/favorites/useGetFavoriteNewsAPI';
import { getArrayFromInfiniteData } from '../../util/api';
import InfiniteScroll from '../common/InfiniteScroll';
import InsightCard from '../insight/InsightCard';

const StyledInsightCard = styled(InsightCard)`
  margin-bottom: 12px;
`;

const SavedInsightList: React.FC = () => {
  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useGetFavoriteNewsAPI();

  const news = getArrayFromInfiniteData(data);

  return (
    <InfiniteScroll
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      isEmpty={news.length === 0}
      observeBrowserViewport
    >
      {news.map((n) => {
        return (
          <StyledInsightCard key={n.uid} insight={n} />
        );
      })}
    </InfiniteScroll>
  );
};

SavedInsightList.defaultProps = {};

SavedInsightList.propTypes = {};

export default SavedInsightList;
