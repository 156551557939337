import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import useGetExchangeRateAlertsAPI from '../../hooks/api/exchange_rates/useGetExchageRateAlertsAPI';
import Loading from '../common/status/Loading';
import GeneralError from '../common/status/GeneralError';
import ExchangeRateAlertItem from './ExchangeRateAlertItem';

type Props = {
  className?: string;
  showDeleteButton: boolean;
  showIsAlertedOnly?: boolean;
  alertColor: string;
}

const Container = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ExchangeRateAlertList: React.FC<Props> = ({
  className,
  showDeleteButton,
  showIsAlertedOnly,
  alertColor,
}) => {
  const { data, status, refetch } = useGetExchangeRateAlertsAPI();
  const filteredItems = data?.items?.filter((item) => (showIsAlertedOnly ? item.is_alerted : true));

  if (status === 'loading') return <Loading />;
  if (status === 'error') return <GeneralError onButtonClick={() => refetch()} />;
  if (status === 'success' && !filteredItems?.length) return null;

  return (
    <Container className={className}>
      {
        filteredItems
          .map((item) => {
            return (
              <ExchangeRateAlertItem
                key={item.uid}
                item={item}
                showDeleteButton={showDeleteButton}
                alertColor={alertColor}
              />
            );
          })
      }
    </Container>
  );
};

ExchangeRateAlertList.defaultProps = {
  className: null,
  showIsAlertedOnly: false,
};

ExchangeRateAlertList.propTypes = {
  className: PropTypes.string,
  showDeleteButton: PropTypes.bool.isRequired,
  showIsAlertedOnly: PropTypes.bool,
  alertColor: PropTypes.string.isRequired,
};

export default ExchangeRateAlertList;
