import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import * as PropTypes from 'prop-types';
import * as ReactModal from 'react-modal';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { MODAL_Z_INDEX } from '../../constants/z-indices';

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  onRequestClose: () => void;
  className?: string;
}

ReactModal.setAppElement(document.getElementById('root'));

const GlobalStyle = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    z-index: ${MODAL_Z_INDEX};
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
`;

const StyledReactModal = styled(ReactModal)`
  width: 600px;
  max-height: calc(100vh - 80px); // should grow with content
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  box-shadow: -6px 0px 20px rgba(0, 0, 0, 0.1);
`;

const Modal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  children,
  className,
}) => {
  const contentRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!isOpen && contentRef.current) {
      enableBodyScroll(contentRef.current);
    }
  }, [isOpen]);

  return (
    <>
      <GlobalStyle />
      <StyledReactModal
        className={className}
        isOpen={isOpen}
        // NOTE: lock body scroll on open, see: https://github.com/reactjs/react-modal/issues/191#issuecomment-639123880
        onAfterOpen={() => disableBodyScroll(contentRef.current)}
        onRequestClose={() => {
          enableBodyScroll(contentRef.current);
          onRequestClose();
        }}
        contentRef={(element) => { contentRef.current = element; }}
      >
        {children}
      </StyledReactModal>
    </>
  );
};

Modal.defaultProps = {
  className: null,
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Modal;
