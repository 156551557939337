import * as React from 'react';
import styled from 'styled-components';

const Container = styled.span`
  font-size: 16px;
  color: #01AAC1;
`;

const Dot = () => (
  <Container>．</Container>
);

export default Dot;
