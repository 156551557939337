/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Label from '../common/form-modal/Label';
import Input from '../common/form-modal/Input';
import Card from '../common/Card';
import ErrorMessage from '../common/form-modal/ErrorMessage';
import useResetPasswordWithTokenAPI from '../../hooks/api/account/useResetPasswordWithTokenAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';

type Params = {
  token: string;
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0px 3px 5px rgba(0, 100, 159, 0.08);
  border-radius: 10px;
  overflow: hidden;
`;

const Form = styled.form`
  background: #fff;
  padding: 16px 12px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #1B262C;
  margin: 0;
  background: #F9F9F9;
  padding: 16px 12px;
`;

const Desc = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #BDBDBD;
  margin: 12px 0;
`;

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: 8px;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const Submit = styled.input`
  width: 100%;
  height: 40px;
  background: #00649F;
  opacity: 0.6;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  margin-top: 55px;
`;

type FormData = {
  password: string;
  confirmPassword: string;
}

const ResetPaswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useParams<Params>();
  const {
    register, handleSubmit, formState: { errors, isSubmitting }, setError,
  } = useForm<FormData>();
  const { mutateAsync: resetPasswordWithTokenAPI } = useResetPasswordWithTokenAPI();
  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();
  const history = useHistory();

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      const { password, confirmPassword } = formData;
      if (password !== confirmPassword) {
        setError('confirmPassword', {
          type: 'not_match',
          message: t('reset_password.error_not_match'),
        });
        return;
      }

      await resetPasswordWithTokenAPI({ url_token: token, password });
      enqueueGeneralSuccessSnackbar();
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (err) {
      switch (err?.response?.data?.code) {
        case 'password_invalid':
        case 'url_token_expired':
        case 'url_token_invalid':
          setError('password', {
            type: 'password_invalid',
            message: err.response.data.message,
          });
          break;
        default:
          enqueueGeneralErrorSnackbar();
          throw err;
      }
    }
  };

  return (
    <Container>
      <Title>
        {t('reset_password.title')}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledLabel>
          {t('reset_password.new_password')}
        </StyledLabel>
        <StyledInput type="password" {...register('password')} placeholder={t('reset_password.new_password')} />
        {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
        <Desc>
          {t('reset_password.password_rule')}
        </Desc>

        <StyledLabel>
          {t('reset_password.confirm_password')}
        </StyledLabel>
        <StyledInput type="password" {...register('confirmPassword')} placeholder={t('reset_password.confirm_password')} />
        {errors.confirmPassword && <ErrorMessage>{errors.confirmPassword.message}</ErrorMessage> }

        <Submit type="submit" disabled={isSubmitting} value={t('common.save') as string} />
      </Form>
    </Container>
  );
};

export default ResetPaswordScreen;
