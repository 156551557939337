import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TitledModal from '../common/TitledModal';
import ContactGeoDistMap from './ContactGeoDistMap';
import { ContactGeoDist, ContactGeoDistPropTypes } from '../../dataTypes';
import ContactGeoDistItem from './ContactGeoDistItem';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  data: ContactGeoDist[];
}

const StyledContactGeoDistMap = styled(ContactGeoDistMap)`
  box-shadow: 0px 3px 5px rgba(0, 100, 159, 0.08);
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ListContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const StyledContactGeoDistItem = styled(ContactGeoDistItem)`
  padding: 12px 20px;
  box-shadow: 0px 3px 5px rgba(0, 100, 159, 0.08);
`;

const ContactGeoDistModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  data,
}) => {
  const { t } = useTranslation();

  return (
    <TitledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t('home.contact_geo_dist.title')}
    >
      <StyledContactGeoDistMap
        id="contact-geo-dist-modal"
        data={data}
        width={560}
        height={289}
      />
      <ListContainer>
        {
          data.map((item) => {
            return (
              <StyledContactGeoDistItem
                key={item.code}
                name={item.name}
                count={item.count}
              />
            );
          })
        }
      </ListContainer>
    </TitledModal>
  );
};

ContactGeoDistModal.defaultProps = {
  data: [],
};

ContactGeoDistModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(ContactGeoDistPropTypes),
};

export default ContactGeoDistModal;
