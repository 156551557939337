import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import HeaderBar, { HEADER_BAR_HEIGHT } from './header-bar/HeaderBar';
import { HEADER_BAR_Z_INDEX } from '../../constants/z-indices';
import ErrorScreen from '../common/ErrorScreen';
import useLoginRequired from '../../hooks/auth/useLoginRequired';

const GUTTER_WIDTH = 20;

const MAIN_COLUMN_WIDTH = 366;
export const SIDE_COLUMN_WIDTH = 336;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeaderBar = styled(HeaderBar)`
  position: sticky;
  top: 0;
  z-index: ${HEADER_BAR_Z_INDEX};
  padding: 0 ${2 * GUTTER_WIDTH}px;
`;

const ContentContainer = styled.div`
  display: flex;
  margin: 0 ${2 * GUTTER_WIDTH}px;
`;

const Main = styled.main`
  flex: 1;
  width: ${MAIN_COLUMN_WIDTH}px;
  &:not(:last-child) {
    margin-right: ${GUTTER_WIDTH}px;
  }
`;

const SideContainer = styled.div<{ isSticky: boolean }>`
  width: ${SIDE_COLUMN_WIDTH}px;

  ${(props) => (
    props.isSticky && `
      position: sticky;
      top: ${HEADER_BAR_HEIGHT}px;
      height: calc(100vh - ${HEADER_BAR_HEIGHT}px);
      z-index: 1;
    `
  )}
  overflow-y: auto;
  overscroll-behavior-y: contain;
`;

type Props = {
  children?: React.ReactNode;
  mainComponent?: React.FunctionComponent;
  sideComponent?: React.FunctionComponent;
  isSideSticky?: boolean;
}

const HeaderLayout: React.FC<Props> = ({
  children,
  mainComponent: MainComponent,
  sideComponent: SideComponent,
  isSideSticky,
}) => {
  useLoginRequired();

  return (
    <Container>
      <StyledHeaderBar />
      <ContentContainer>
        <Main>
          <Sentry.ErrorBoundary fallback={<ErrorScreen />} showDialog>
            {children || (MainComponent && <MainComponent />)}
          </Sentry.ErrorBoundary>
        </Main>
        {
          SideComponent && (
            <Sentry.ErrorBoundary fallback={<ErrorScreen />} showDialog>
              <SideContainer isSticky={isSideSticky}>
                <SideComponent />
              </SideContainer>
            </Sentry.ErrorBoundary>
          )
        }
      </ContentContainer>
    </Container>
  );
};

HeaderLayout.defaultProps = {
  children: null,
  mainComponent: null,
  sideComponent: null,
  isSideSticky: true,
};

HeaderLayout.propTypes = {
  children: PropTypes.node,
  mainComponent: PropTypes.func,
  sideComponent: PropTypes.func,
  isSideSticky: PropTypes.bool,
};

export default HeaderLayout;
