import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseErrorView from './BaseErrorView';
import errorImg from './images/error.svg';

interface IProps {
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onButtonClick?: (e: React.MouseEvent<HTMLElement>) => any;
}

const GeneralError: React.FC<IProps> = ({
  className,
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <BaseErrorView
      className={className}
      iconSrc={errorImg}
      message={t('common.general_error')}
      buttonText={onButtonClick ? t('common.try_again') : null}
      onButtonClick={onButtonClick}
    />
  );
};

GeneralError.defaultProps = {
  className: null,
  onButtonClick: null,
};

GeneralError.propTypes = {
  className: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default GeneralError;
