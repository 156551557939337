import * as React from 'react';
import styled from 'styled-components';
import useGetInsightsAPI from '../../hooks/api/insight/useGetInsightsAPI';
import InsightCard from '../insight/InsightCard';
import BusinessInsightCard from '../insight/BusinessInsightCard';
import { getArrayFromInfiniteData } from '../../util/api';
import Loading from '../common/status/Loading';
import GeneralError from '../common/status/GeneralError';
import InfiniteScroll from '../common/InfiniteScroll';

// image height 80 / line height 16 === 5
const DESC_MAX_LINE_WITH_IMAGE = 10;
const DESC_MAX_LINE_WITHOUT_IMAGE = 15;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 3px 5px rgba(0, 100, 159, 0.08);
  border-radius: 10px;
  padding: 12px;

  * {
    box-shadow: none;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  max-height: 376px;
`;

const StyledBusinessInsightCard = styled(BusinessInsightCard)`
  max-height: 376px;
`;

const CardContainer = styled.div`
  & > * {
    position: relative;
    box-shadow: none;

    /* separator */
    &:not(:last-child):after {
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #01AAC1;
      opacity: 0.1;
      z-index: 1;
    }
  }
`;

const InsightGrid: React.FC = () => {
  const {
    data, status, fetchNextPage, hasNextPage, isFetchingNextPage,
  } = useGetInsightsAPI();
  const insights = getArrayFromInfiniteData(data);
  const [descMaxLines, setDescMaxLines] = React.useState(DESC_MAX_LINE_WITH_IMAGE);

  const firstInsight = (insights?.length > 0) && insights[0];

  React.useEffect(() => {
    setDescMaxLines(firstInsight?.img_url ? DESC_MAX_LINE_WITH_IMAGE : DESC_MAX_LINE_WITHOUT_IMAGE);
  }, [firstInsight]);

  if (status === 'success' && !insights?.length) return null;

  return (
    <Container>
      { insights.length > 0 && (
        <Column>
          <StyledBusinessInsightCard
            insight={firstInsight}
            showDescription
            descriptionMaxLines={descMaxLines}
            onImageHidden={() => setDescMaxLines(DESC_MAX_LINE_WITHOUT_IMAGE)}
          />
        </Column>
      )}
      {insights.length > 1 && (
        <Column>
          <StyledInfiniteScroll
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            status={status}
            isEmpty={insights.length <= 1}
          >
            <CardContainer>
              {insights.slice(1).map((insight) => {
                return (
                  <InsightCard key={insight.uid} insight={insight} showDescription={false} />
                );
              })}
            </CardContainer>
          </StyledInfiniteScroll>
        </Column>
      )}
      {status === 'loading' && <Loading />}
      {status === 'error' && <GeneralError onButtonClick={() => fetchNextPage()} />}
    </Container>
  );
};

export default InsightGrid;
