import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import Card from './Card';
import { getCardHoverStyles } from '../../util/styles';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const BizcardContainer = styled(Card)`
  position: relative;
  padding: 5px;
  max-width: calc(50% - 6px);
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* Hover effects */
  &:hover {
    &::after {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      ${getCardHoverStyles()}
    }
  }
  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const Bizcard = styled.img`
  object-fit: contain;
  border-radius: 5px;
  width: 100%;
`;

interface IProps {
  className?: string;
  bizcardFrontUrl?: string;
  bizcardBackUrl?: string;
}

const BizcardList: React.FC<IProps> = ({
  className,
  bizcardFrontUrl,
  bizcardBackUrl,
}) => {
  return (
    <Container className={className}>
      {bizcardFrontUrl && (
        <BizcardContainer>
          <Bizcard src={bizcardFrontUrl} />
        </BizcardContainer>
      )}
      {bizcardBackUrl && (
        <BizcardContainer>
          <Bizcard src={bizcardBackUrl} />
        </BizcardContainer>
      )}
    </Container>
  );
};

BizcardList.defaultProps = {
  className: null,
  bizcardFrontUrl: null,
  bizcardBackUrl: null,
};

BizcardList.propTypes = {
  className: PropTypes.string,
  bizcardFrontUrl: PropTypes.string,
  bizcardBackUrl: PropTypes.string,
};

export default BizcardList;
