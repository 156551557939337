import styled from 'styled-components';

import bookmarkImg from '../../images/bookmark.svg';
import bookmarkHoverImg from '../../images/bookmark-hover.svg';
import bookmarkSaveImg from '../../images/bookmark-save.svg';

interface SaveButtonProps {
  isSaved: boolean;
}

const SaveButton = styled.div<SaveButtonProps>`
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => (props.isSaved ? bookmarkSaveImg : bookmarkImg)});
  cursor: pointer;
  &:hover {
    background-image: url(${bookmarkHoverImg})
  }
`;

export default SaveButton;
