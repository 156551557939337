import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { Insight } from '../../../dataTypes';

type Options = {
  enabled: boolean;
}

const useGetNewsfeedsAPI = (uid: string, options?: Options) => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q = QueryString.stringify({
      client: uid,
      per_page: 10,
      page: pageParam,
    });

    const res = await api.get(`/newsfeeds?${q}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<Insight>>(
    ['newsfeeds', uid],
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
      enabled: options?.enabled,
    },
  );
};

export default useGetNewsfeedsAPI;
