import { useQuery } from 'react-query';
import api from '../../../util/api';
import { ExchangeRateAlert } from '../../../dataTypes';

type ExchangeRateAlertData = {
  items: ExchangeRateAlert[];
}

const useGetExchangeRateAlertsAPI = () => {
  const fetchFunction = async () => {
    const res = await api.get('/exchange_rates/alerts');
    return {
      ...res.data,
    };
  };

  return useQuery<ExchangeRateAlertData>(
    'exchange_rates/alerts',
    fetchFunction,
  );
};

export default useGetExchangeRateAlertsAPI;
