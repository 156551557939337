import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Client } from '../../dataTypes';
import noteImg from './images/note.svg';
import contactImg from './images/contact.svg';
import CompanyModal from './CompanyModal';
import MoreButton, { MoreButtonItem } from '../common/MoreButton';
import useDeleteClientAPI from '../../hooks/api/clients/useDeleteClientAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import useQuery from '../../hooks/useQuery';
import Card from '../common/Card';
import { getLineClampStyles } from '../../util/styles';

type Params = {
  className?: string;
  client: Client;
}

const Container = styled(Card)`
  background: #FFFFFF;
  border-radius: 10px;
  padding: 12px 20px;
  display: flex;
  cursor: pointer;
  &:hover {
    background: rgba(1, 170, 193, 0.12);
  }
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 8px;
`;

const PlaceholderImage = styled.div`
  width: 40px;
  height: 40px;
  background: #C4C4C4;
  border-radius: 8px;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  margin: 0;
  ${getLineClampStyles(2)}
`;

const LastTouch = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
  opacity: 0.6;
  margin-bottom: 4px;
`;

const NoteRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  color: #1B262C;
`;

const NoteIcon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${noteImg});
  background-repeat: no-repeat;
  background-size: contain;
`;

const ContactIcon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${contactImg});
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 12px;
`;

const CompanyCard: React.FC<Params> = ({
  className,
  client,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const query = useQuery();
  const { mutateAsync: deleteClientAPI } = useDeleteClientAPI(client.uid);
  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar, enqueueSnackbar } = useGeneralSnackbar();

  const {
    photo_url: photoUrl,
    name,
    last_touch_dt: lastTouchDt,
    calling_note_count: callingNoteCount,
    contact_count: contactCount,
  } = client;

  const { t } = useTranslation();
  const lastTouch = dayjs(lastTouchDt).calendar();

  const onDeleteClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const result = window.confirm(t('common.delete_confirm'));
    if (result) {
      try {
        await deleteClientAPI();
        enqueueGeneralSuccessSnackbar();
      } catch (err) {
        switch (err?.response?.data?.code) {
          case 'client_contact_exists':
            enqueueSnackbar(t('company.error.client_contact_exists'), { variant: 'error' });
            break;
          case 'client_note_exists':
            enqueueSnackbar(t('company.error.client_note_exists'), { variant: 'error' });
            break;
          default:
            enqueueGeneralErrorSnackbar();
            throw err;
        }
      }
    }
  };

  return (
    <>
      <Container onClick={() => setIsModalOpen(true)} className={className}>
        {photoUrl ? <Image src={photoUrl} /> : <PlaceholderImage />}
        <TextContainer>
          <Title>{name}</Title>
          <LastTouch>{t('common.last_touch', { time: lastTouch })}</LastTouch>
          <NoteRow>
            <NoteIcon />
            {callingNoteCount}
            <ContactIcon />
            {contactCount}
          </NoteRow>
          {/* TODO: last_calling_note */}
          {/* TODO: tel */}
        </TextContainer>
        {
          query.get('mode') === 'admin' && (
            <MoreButton>
              <MoreButtonItem onClick={onDeleteClick}>{t('common.delete')}</MoreButtonItem>
            </MoreButton>
          )
        }
      </Container>
      <CompanyModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        client={client}
      />
    </>
  );
};

CompanyCard.defaultProps = {
  className: null,
};

CompanyCard.propTypes = {
  className: PropTypes.string,
  client: PropTypes.exact({
    address: PropTypes.string,
    calling_note_count: PropTypes.number,
    contact_count: PropTypes.number,
    description: PropTypes.string,
    edit_dt: PropTypes.string,
    ent_uid: PropTypes.string,
    industry: PropTypes.string,
    last_calling_note: PropTypes.exact({
      last_touch_dt: PropTypes.string,
      summary: PropTypes.string,
      uid: PropTypes.string,
    }),
    last_touch_dt: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    photo_uid: PropTypes.string,
    photo_url: PropTypes.string,
    sub_industry: PropTypes.string,
    uid: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
};

export default CompanyCard;
