/* eslint-disable camelcase */
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

type PostClientData = {
  name: string;
  description?: string;
  industry?: string;
  sub_industry?: string;
  address?: string;
  phone?: string;
  website: string;
  photo_uid?: string;
  photo_url?: string;
};

const useCreateClientAPI = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: PostClientData) => {
      return api.post('/clients', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
      },
    },
  );

  return mutation;
};

export default useCreateClientAPI;
