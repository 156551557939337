import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import Dot from './Dot';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
`;

interface IProps {
  className?: string;
  companyName: string;
  jobTitle?: string;
}

const CompanyJobTitle: React.FC<IProps> = ({
  className,
  companyName,
  jobTitle,
}) => {
  return (
    <Container className={className}>
      {companyName}
      {jobTitle && (
      <>
        <Dot />
        {jobTitle}
      </>
      )}
    </Container>
  );
};

CompanyJobTitle.defaultProps = {
  className: null,
  jobTitle: null,
};

CompanyJobTitle.propTypes = {
  className: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
};

export default CompanyJobTitle;
