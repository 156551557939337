/* eslint-disable camelcase */
import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  SearchResultPropTypes, SearchResult,
} from '../../dataTypes';
import CompanyCard from '../company/CompanyCard';
import ContactCard from '../contacts/ContactCard';
import InsightCard from '../insight/InsightCard';
import NoteCard from '../notes/NoteCard';
import GeneralError from '../common/status/GeneralError';
import NoData from '../common/status/NoData';
import Loading from '../common/status/Loading';

const Container = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
  margin: 28px 0;
`;

const CardContainer = styled.div`
  & > * {
    margin-bottom: 12px;
  }
`;

const StatusContainer = styled.div`
  margin: 32px auto;
`;

interface IProps {
  className?: string;
  status: 'idle' | 'error' | 'loading' | 'success';
  result?: SearchResult;
  onRetry: () => any;
}

const SearchResultList: React.FC<IProps> = ({
  className,
  status,
  result,
  onRetry,
}) => {
  const { t } = useTranslation();

  if (status === 'loading') {
    return (
      <StatusContainer>
        <Loading />
      </StatusContainer>
    );
  }

  if (status === 'error') {
    return (
      <StatusContainer>
        <GeneralError onButtonClick={onRetry} />
      </StatusContainer>
    );
  }

  if (!result) return null;

  if (!result.client.length && !result.contact.length && !result.news.length && !result.calling_note.length) {
    return (
      <StatusContainer>
        <NoData />
      </StatusContainer>
    );
  }

  return (
    <Container className={className}>
      { result.client.length > 0 && (
        <>
          <Title>{t('common.companies')}</Title>
          <CardContainer>
            {
              result.client.map((client) => {
                return (
                  <CompanyCard key={client.uid} client={client} />
                );
              })
            }
          </CardContainer>
        </>
      )}

      { result.contact.length > 0 && (
        <>
          <Title>{t('common.contacts')}</Title>
          <CardContainer>
            {
              result.contact.map((contact) => {
                return (
                  <ContactCard key={contact.uid} contact={contact} />
                );
              })
            }
          </CardContainer>
        </>
      ) }

      { result.news.length > 0 && (
        <>
          <Title>{t('common.insight')}</Title>
          <CardContainer>
            {
              result.news.map((insight) => {
                return (
                  <InsightCard key={insight.uid} insight={insight} />
                );
              })
            }
          </CardContainer>
        </>
      ) }

      { result.calling_note.length > 0 && (
        <>
          <Title>{t('common.notes')}</Title>
          <CardContainer>
            {
              result.calling_note.map((note) => {
                return (
                  <NoteCard key={note.uid} note={note} />
                );
              })
            }
          </CardContainer>
        </>
      ) }
    </Container>
  );
};

SearchResultList.defaultProps = {
  className: null,
  result: null,
};

SearchResultList.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(['idle', 'error', 'loading', 'success'] as const).isRequired,
  result: SearchResultPropTypes,
  onRetry: PropTypes.func.isRequired,
};

export default SearchResultList;
