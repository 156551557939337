/* eslint-disable camelcase */
import { useMutation } from 'react-query';
import api from '../../../util/api';

type PostData = {
  url_token: string;
  password: string;
};

const useResetPasswordWithTokenAPI = () => {
  const mutation = useMutation(
    (data: PostData) => {
      return api.post('/account/pwd_reset', data);
    },
  );

  return mutation;
};

export default useResetPasswordWithTokenAPI;
