import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Notice, NoticePropTypes } from '../../dataTypes';
import NewClientNotice from './NewClientNotice';
import NewContactNotice from './NewContactNotice';

type Props = {
  className?: string;
  notice: Notice;
};

const NotificationItem: React.FC<Props> = ({
  className,
  notice,
}) => {
  switch (notice.kind) {
    case 'client_new': {
      switch (notice.data.sub_kind) {
        case 'company':
          return <NewClientNotice className={className} notice={notice} />;
        case 'contact':
          return <NewContactNotice className={className} notice={notice} />;
        default:
          return null;
      }
    }
    default:
      return null;
  }
};

NotificationItem.defaultProps = {
  className: null,
};

NotificationItem.propTypes = {
  className: PropTypes.string,
  notice: NoticePropTypes.isRequired,
};

export default NotificationItem;
