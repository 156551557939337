import styled from 'styled-components';
import { getCardHoverStyles } from '../../util/styles';
import Card from './Card';

const HoverCard = styled(Card)`
  &:hover {
    ${getCardHoverStyles()}
  }
`;

export default HoverCard;
