import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';
import ModalHeader from '../common/ModalHeader';
import ExchangeRateInput from './ExchangeRateInput';
import useCreateExchangeRateAlertAPI from '../../hooks/api/exchange_rates/useCreateExchangeRateAlertAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import ExchangeRateAlertList from './ExchangeRateAlertList';
import ErrorMessage from '../common/form-modal/ErrorMessage';
import TitledModal from '../common/TitledModal';

type Props = {
  isOpen: boolean;
  onRequestClose: () => any;
}

const Form = styled.form`
  padding: 24px 72px;
`;

const AddButton = styled.button`
  display: block;
  margin-top: 24px;
  border: none;
  height: 48px;
  width: 100%;
  background: #01AAC1;
  border-radius: 100px;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
`;

const ExchangeRateModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const [USD, setUSD] = React.useState<string>('');
  const [JPY, setJPY] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const { mutateAsync: createExchangeRateAPI } = useCreateExchangeRateAlertAPI();
  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  const reset = () => {
    setUSD('');
    setJPY('');
    setError('');
  };

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  const onSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      await createExchangeRateAPI({
        base: 'USD',
        target: 'JPY',
        base_amount: parseFloat(USD),
        target_amount: parseFloat(JPY),
      });
      reset();
      enqueueGeneralSuccessSnackbar();
    } catch (err) {
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        enqueueGeneralErrorSnackbar();
        throw err;
      }
    }
  };

  return (
    <TitledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t('exchange_rate.modal.title')}
    >
      <Form onSubmit={onSubmit}>
        <ExchangeRateInput
          USD={USD}
          JPY={JPY}
          onChangeUSD={setUSD}
          onChangeJPY={setJPY}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <AddButton type="submit">
          {t('exchange_rate.modal.add_button')}
        </AddButton>
      </Form>
      <ExchangeRateAlertList showDeleteButton alertColor="red" />
    </TitledModal>
  );
};

ExchangeRateModal.defaultProps = {};

ExchangeRateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ExchangeRateModal;
