import styled from 'styled-components';
import GeneralError from './status/GeneralError';

const ErrorScreen = styled(GeneralError)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ErrorScreen;
