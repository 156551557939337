import * as React from 'react';
import styled from 'styled-components';
import useLoginRequired from '../../hooks/auth/useLoginRequired';
import HeaderLayout from '../layouts/HeaderLayout';
import WelcomeCard from './WelcomeCard';
import LeadList from './LeadList';
import InsightGrid from './InsightGrid';
import NoteGrid from './NoteGrid';
import ContactGeoDistWidget from './ContactGeoDistWidget';
import CompanyIndustryDistWidget from './CompanyIndustryDistWidget';
import TaskStatusWidget from './TaskStatusWidget';
import ExchangeRateWidget from './ExchangeRateWidget';

const Container = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;

const StyledLeadList = styled(LeadList)`
  margin-bottom: 15px;
`;

const Main = () => (
  <Container>
    <WelcomeCard />
    <StyledLeadList />
    <InsightGrid />
    <NoteGrid />
  </Container>
);

const Side = () => (
  <Container>
    <ContactGeoDistWidget />
    <ExchangeRateWidget />
    <CompanyIndustryDistWidget />
    <TaskStatusWidget />
  </Container>
);

const HomeScreen = () => {
  useLoginRequired();

  return (
    <HeaderLayout
      mainComponent={Main}
      sideComponent={Side}
      isSideSticky={false}
    />
  );
};

export default HomeScreen;
