import { useQuery } from 'react-query';
import api from '../../../util/api';

interface Options {
  enabled: boolean;
}

const useGetLatestExchangeRateAPI = (base: string = 'USD', options?: Options) => {
  const fetchFunction = async () => {
    const res = await api.get(`/exchange_rates/latest?base=${base}`);
    return {
      ...res.data,
    };
  };

  return useQuery(
    ['exchage_rates/latest', base],
    fetchFunction,
    { enabled: options?.enabled },
  );
};

export default useGetLatestExchangeRateAPI;
