import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import useGetClientsAPI, { FilterType, RankByType } from '../../hooks/api/clients/useGetClientsAPI';
import CompanyCard from './CompanyCard';
import CompanyFilter from './CompanyFilter';
import CompanyRankBy from './CompanyRankBy';
import AddOrEditCompanyModal from './AddOrEditCompanyModal';
import AddButton from '../common/AddButton';
import InfiniteScroll from '../common/InfiniteScroll';
import { getArrayFromInfiniteData } from '../../util/api';
import { HEADER_BAR_HEIGHT } from '../layouts/header-bar/HeaderBar';

type Param = {
  className?: string;
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  position: sticky;
  top: ${HEADER_BAR_HEIGHT}px;
  z-index: 1;
  background: #F0F0F0;
`;

const StyledCompanyCard = styled(CompanyCard)`
  margin: 12px 0;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const AlignRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CompanyList: React.FC<Param> = ({ className }) => {
  const [filter, setFilter] = React.useState<FilterType>('my');
  const [rankBy, setRankBy] = React.useState<RankByType>('company_name');

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useGetClientsAPI({ filter, rankBy });
  const clients = getArrayFromInfiniteData(data);

  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = React.useState(false);

  return (
    <>
      <Container className={className}>
        <Header>
          <FilterContainer>
            <CompanyFilter
              value={filter}
              onChange={setFilter}
            />
            <AddButton onClick={() => setIsAddCompanyModalOpen(true)} />
          </FilterContainer>
          <AlignRightContainer>
            <CompanyRankBy
              value={rankBy}
              onChange={setRankBy}
            />
          </AlignRightContainer>
        </Header>
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          status={status}
          isEmpty={clients.length === 0}
          observeBrowserViewport
        >
          {
            clients.map((client) => {
              return (
                <StyledCompanyCard key={client.uid} client={client} />
              );
            })
          }
        </InfiniteScroll>
      </Container>
      <AddOrEditCompanyModal
        isOpen={isAddCompanyModalOpen}
        onRequestClose={() => setIsAddCompanyModalOpen(false)}
      />
    </>
  );
};

CompanyList.defaultProps = {
  className: null,
};

CompanyList.propTypes = {
  className: PropTypes.string,
};

export default CompanyList;
