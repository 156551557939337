import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import useGetContactsAPI, {
  FilterType, LifeCycleOwnerType, RankByType,
} from '../../hooks/api/contacts/useGetContactsAPI';
import { useAppSelector } from '../../hooks/store';
import { getIsEnterprise } from '../../slices/enterpriseAuthSlice';
import InfiniteScroll from '../common/InfiniteScroll';
import ContactCard from './ContactCard';
import { LifeCycleStage } from '../../dataTypes';
import ContactFilter from './ContactFilter';
import LifeCycleStagesFilter from './LifeCycleStagesFilter';
import LifeCycleOwnerFilter from './LifeCycleOwnerFilter';
import ContactRankBy from './ContactRankBy';
import AddButton from '../common/AddButton';
import AddOrEditContactModal from './AddOrEditContactModal';
import { getArrayFromInfiniteData } from '../../util/api';
import { HEADER_BAR_HEIGHT } from '../layouts/header-bar/HeaderBar';

interface Param {
  className?: string;
  clientUid?: string;
  initialFilter?: FilterType,
  initialRankBy?: RankByType,
  showHeader?: boolean;
  observeBrowserViewport: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  position: sticky;
  top: ${HEADER_BAR_HEIGHT}px;
  background: #f1f1f1;
  z-index: 1;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterLeftContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-right: 12px;
    margin-bottom: 12px;
  }
`;

const RankByContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledContactCard = styled(ContactCard)`
  margin: 12px 0;
`;

const ContactList: React.FC<Param> = ({
  className,
  clientUid,
  initialFilter,
  initialRankBy,
  showHeader,
  observeBrowserViewport,
}) => {
  const isEnterprise = useAppSelector(getIsEnterprise);
  const [filter, setFilter] = React.useState<FilterType>(initialFilter);
  const [rankBy, setRankBy] = React.useState<RankByType>(initialRankBy);
  const [lifeCycleStages, setLifeCycleStages] = React.useState<LifeCycleStage[]>([]);
  const [lifeCycleOwner, setLifeCycleOwner] = React.useState<LifeCycleOwnerType>('all');

  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);

  const query = {
    isPersonal: !isEnterprise,
    filter,
    rankBy,
    lifeCycleStages,
    lifeCycleOwner,
    client: clientUid,
  };

  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useGetContactsAPI(query);

  const contacts = getArrayFromInfiniteData(data);

  return (
    <>
      <Container className={className}>
        {showHeader && (
          <Header>
            <FilterContainer>
              <FilterLeftContainer>
                <ContactFilter
                  value={filter}
                  onChange={setFilter}
                />
                <LifeCycleStagesFilter
                  values={lifeCycleStages}
                  onChange={(newValue) => {
                    if (lifeCycleStages.includes(newValue)) {
                      setLifeCycleStages(lifeCycleStages.filter((value) => value !== newValue));
                    } else {
                      const newLifeCycleStages = [...lifeCycleStages, newValue];
                      newLifeCycleStages.sort(); // values are used as a cache key, value order matters
                      setLifeCycleStages(newLifeCycleStages);
                    }
                  }}
                />
                <LifeCycleOwnerFilter
                  value={lifeCycleOwner}
                  onChange={setLifeCycleOwner}
                />
              </FilterLeftContainer>
              <AddButton onClick={() => setIsAddModalOpen(true)} />
            </FilterContainer>
            <RankByContainer>
              <ContactRankBy
                value={rankBy}
                onChange={setRankBy}
              />
            </RankByContainer>
          </Header>
        )}
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          status={status}
          isEmpty={contacts.length === 0}
          observeBrowserViewport={observeBrowserViewport}
        >
          {
            contacts.map((contact) => {
              return (
                <StyledContactCard key={contact.uid} contact={contact} />
              );
            })
          }
        </InfiniteScroll>
      </Container>
      <AddOrEditContactModal
        isOpen={isAddModalOpen}
        onRequestClose={() => setIsAddModalOpen(false)}
      />
    </>
  );
};

ContactList.defaultProps = {
  className: null,
  clientUid: null,
  initialFilter: 'my',
  initialRankBy: 'company_name',
  showHeader: true,
};

ContactList.propTypes = {
  className: PropTypes.string,
  clientUid: PropTypes.string,
  initialFilter: PropTypes.oneOf(['my', 'all', 'ai']),
  initialRankBy: PropTypes.oneOf(['company_name', 'first_name', 'last_name', 'last_touch_dt', 'create_dt_dsc', 'ai']),
  showHeader: PropTypes.bool,
  observeBrowserViewport: PropTypes.bool.isRequired,
};

export default ContactList;
