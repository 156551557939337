import axios from 'axios';
import { InfiniteData } from 'react-query';
import { API_URL } from '../config';
import { restoreAndRefreshAuthState } from '../slices/authSlice';
import { store } from '../store';

const api = axios.create({
  baseURL: API_URL,
});

api.defaults.headers.post['Content-Type'] = 'application/json';

const handleSuccessResponse = (response: any) => response;

const handleErrorResponse = async (error: any) => {
  if (error?.response?.status === 401) {
    // try to refresh token and wait for APIs to retry
    // if refresh token failed then logout
    await store.dispatch(restoreAndRefreshAuthState());
  }

  return Promise.reject(error);
};

api.interceptors.response.use(
  handleSuccessResponse,
  handleErrorResponse,
);

export default api;

// For useInfiniteQuery
export const getNextPageParam = (lastPage: any) => (lastPage.has_more ? lastPage.pageParam + 1 : undefined);
export const getPreviousPageParam = (firstPage: any) => (firstPage.pageParam > 1 ? firstPage.pageParam - 1 : undefined);

export interface PageData<TData> {
  count: number;
  // eslint-disable-next-line camelcase
  has_more: boolean;
  items: TData[];
}

export const getArrayFromInfiniteData = <TData extends unknown>(data: InfiniteData<PageData<TData>>) => {
  const arr = data
    ? data.pages
      .map((page) => page.items)
      .reduce((acc, cur) => [...acc, ...cur], [])
    : [];

  return arr;
};
