import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import HeaderLayout from '../layouts/HeaderLayout';
import useLoginRequired from '../../hooks/auth/useLoginRequired';
import useGetNoticesAPI from '../../hooks/api/notices/useGetNoticesAPI';
import { getArrayFromInfiniteData } from '../../util/api';
import NotificationItem from './NotificationItem';
import InfiniteScroll from '../common/InfiniteScroll';

type Props = {
  className?: string;
};

const Container = styled.div`
  & > * {
    margin-bottom: 12px;
  }
`;

const NotificationScreen: React.FC<Props> = ({
  className,
}) => {
  useLoginRequired();
  const {
    data, status, fetchNextPage, hasNextPage, isFetchingNextPage,
  } = useGetNoticesAPI();
  const notices = getArrayFromInfiniteData(data);

  return (
    <HeaderLayout>
      <InfiniteScroll
        className={className}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        status={status}
        isEmpty={notices.length === 0}
        observeBrowserViewport
      >
        <Container>
          {notices.map((notice) => <NotificationItem key={notice.uid} notice={notice} />)}
        </Container>
      </InfiniteScroll>
    </HeaderLayout>
  );
};

NotificationScreen.defaultProps = {
  className: null,
};

NotificationScreen.propTypes = {
  className: PropTypes.string,
};

export default NotificationScreen;
