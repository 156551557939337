import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import filterImg from './images/filter-icon.svg';
import chevronImg from './images/chevron-down.svg';
import checkboxCheckedImg from './images/checkbox-checked.svg';
import checkboxUncheckedImg from './images/checkbox-unchecked.svg';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import HoverCard from './HoverCard';

interface Props {
  name: string;
  values: any[];
  // eslint-disable-next-line no-unused-vars
  onChange: (newValue: any) => any;
  className?: string;
  children: React.ReactNode;
}

const Container = styled.div`
  position: relative;
`;

const Body = styled(HoverCard)`
  height: 28px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
`;

const Icon = styled.div`
  background: center/contain no-repeat url(${filterImg});
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

const Title = styled.div`
  font-size: 12px;
  color: #01AAC1;
  margin-right: 8px;
  white-space: nowrap;
`;

const Chevron = styled.div`
  width: 16px;
  height: 16px;
  background: center/contain no-repeat url(${chevronImg});
`;

const MenuBody = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: calc(100% + 8px);
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 10px;
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 60px;
  height: 48px;
  padding: 16px;
  font-size: 12px;
  color: #1B262C;
  cursor: pointer;
  &:hover {
    background: rgba(1, 170, 193, 0.12);
  }
`;

interface CheckboxProps {
  checked: boolean;
}

const Checkbox = styled.div<CheckboxProps>`
  width: 16px;
  height: 16px;
  background: center/contain no-repeat url(${(props) => (props.checked ? checkboxCheckedImg : checkboxUncheckedImg)});
  margin-right: 20px;
`;

interface MenuItemProps {
  checked: boolean;
  name: string;
  onClick: () => any;
}

const MenuItem: React.FC<MenuItemProps> = ({ checked, name, onClick }) => {
  return (
    <MenuItemContainer onClick={onClick}>
      <Checkbox checked={checked} />
      {name}
    </MenuItemContainer>
  );
};

MenuItem.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

interface CheckboxDropDownMenuItemProps {
  name: string;
  value: string;
}

export const CheckboxDropDownMenuItem: React.FC<CheckboxDropDownMenuItemProps> = () => null;

const CheckboxDropDownMenu: React.FC<Props> = ({
  className,
  name,
  values,
  onChange,
  children,
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const containerRef = useClickOutsideHandler(() => setIsMenuOpen(false));

  return (
    <Container className={className} ref={containerRef}>
      <Body onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Icon />
        <Title>{name}</Title>
        <Chevron />
      </Body>

      { isMenuOpen && (
        <MenuBody>
          {
              React.Children.map(children, (child: any) => {
                return (
                  <MenuItem
                    name={child.props.name}
                    checked={values.includes(child.props.value)}
                    onClick={() => {
                      onChange(child.props.value);
                    }}
                  />
                );
              })
            }
        </MenuBody>
      )}
    </Container>
  );
};

CheckboxDropDownMenu.defaultProps = {
  className: null,
};

CheckboxDropDownMenu.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CheckboxDropDownMenu;
