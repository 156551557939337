import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '../common/InfiniteScroll';
import useGetLeadsAPI from '../../hooks/api/clients/useGetLeadsAPI';
import LeadCard from './LeadCard';
import { getArrayFromInfiniteData } from '../../util/api';

type Props = {
  className?: string;
}

const Title = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  margin-bottom: 12px;
`;

const StyledLeadCard = styled(LeadCard)`
  margin-bottom: 12px;
`;

const LeadList: React.FC<Props> = ({
  className,
}) => {
  const { t } = useTranslation();
  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useGetLeadsAPI();

  const leads = getArrayFromInfiniteData(data);

  return (
    <InfiniteScroll
      className={className}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      isEmpty={leads.length === 0}
    >
      <Title>{t('company.lead_list.title')}</Title>
      {
        leads.map((lead) => {
          return (
            <StyledLeadCard key={lead.uid} lead={lead} />
          );
        })
      }
    </InfiniteScroll>
  );
};

LeadList.defaultProps = {
  className: null,
};

LeadList.propTypes = {
  className: PropTypes.string,
};

export default LeadList;
