import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { Note } from '../../../dataTypes';

interface Options {
  enabled: boolean;
}

const useGetTasksDueAPI = (options?: Options) => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q: any = {
      per_page: 10,
      page: pageParam,
    };

    const queryStr = QueryString.stringify(q);

    const res = await api.get(`/calling_notes/tasks_due?${queryStr}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<Note>>(
    ['calling_notes', 'tasks_due'],
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
      enabled: options?.enabled,
    },
  );
};

export default useGetTasksDueAPI;
