import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import Nav from './Nav';
import { NAV_Z_INDEX } from '../../constants/z-indices';
import useLoginRequired from '../../hooks/auth/useLoginRequired';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
`;

const StyledNav = styled(Nav)`
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: ${NAV_Z_INDEX};
  flex-shrink: 0;
`;

const RightColumn = styled.div`
  flex: 1;
`;

type Props = {
  children?: React.ReactNode;
}

const NavLayout: React.FC<Props> = ({
  children,
}) => {
  useLoginRequired();

  return (
    <Container>
      <StyledNav />
      <RightColumn>
        {children}
      </RightColumn>
    </Container>
  );
};

NavLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavLayout;
