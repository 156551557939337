import * as React from 'react';

import useLoginRequired from '../../hooks/auth/useLoginRequired';
import HeaderLayout from '../layouts/HeaderLayout';
import InsightList from './InsightList';
import BusinessInsightList from './BusinessInsightList';

const InsightScreen: React.FC = () => {
  useLoginRequired();

  return (
    <HeaderLayout
      mainComponent={InsightList}
      sideComponent={BusinessInsightList}
    />
  );
};

export default InsightScreen;
