import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import searchImg from './images/search.svg';
import cancelImg from './images/cancel.svg';
import { getCardStyles } from '../../util/styles';

const Container = styled.div`
  position: relative;
`;

const SearchIcon = styled.div`
  width: 20px;
  height: 20px;
  background: center/contain no-repeat url(${searchImg});
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = styled.input<{ref: any}>`
  ${getCardStyles()}
  border: none;
  padding: 0 48px;
  width: 100%;
  height: 48px;
  font-size: 14px;
  color: #333333;
`;

const CancelIcon = styled.div`
  width: 24px;
  height: 24px;
  background: center/contain no-repeat url(${cancelImg});
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
`;

interface IProps {
  className?: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onCancel: () => any;
}

const SearchInput: React.FC<IProps> = React.forwardRef(({
  className,
  value,
  onChange,
  onCancel,
}, ref) => {
  return (
    <Container className={className}>
      <SearchIcon />
      <Input ref={ref} type="text" value={value} onChange={onChange} />
      <CancelIcon onClick={onCancel} />
    </Container>
  );
});

SearchInput.defaultProps = {
  className: null,
};

SearchInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SearchInput;
