import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getLogin } from '../../slices/authSlice';
import { useAppSelector } from '../store';

const useLoginRequired = () => {
  const isLogin = useAppSelector(getLogin);
  const history = useHistory();

  useEffect(() => {
    if (!isLogin) {
      history.replace({ pathname: '/login' });
    }
  }, [history, isLogin]);
};

export default useLoginRequired;
