import * as React from 'react';
import styled from 'styled-components';
import {
  Route, Switch, NavLink,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderLayout from '../layouts/HeaderLayout';
import Card from '../common/Card';
import { getCardStyles } from '../../util/styles';
import bookmarkImg from './images/bookmark.svg';
import SavedInsightList from './SavedInsightList';
import SavedCorpList from './SavedCorpList';
import useLoginRequired from '../../hooks/auth/useLoginRequired';
import { HEADER_BAR_HEIGHT } from '../layouts/header-bar/HeaderBar';

const Container = styled.div`
  display: flex;
`;

const NavContainer = styled.nav`
  position: sticky;
  top: ${HEADER_BAR_HEIGHT}px;
  margin-right: 20px;
  height: fit-content; // Make it scrollable inside a flex container
  width: 360px;
`;

const Title = styled(Card)`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #1B262C;
  margin-bottom: 12px;
`;

const BookmarkImg = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: center/contain no-repeat url(${bookmarkImg});
`;

const NavItem = styled(NavLink)`
  ${getCardStyles()}
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00649F;
  font-size: 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.active {
    color: #fff;
    background: #01AAC1;
  }
`;

const Nav = () => {
  const { t } = useTranslation();
  return (
    <NavContainer>
      <Title>
        <BookmarkImg />
        {t('common.saved')}
      </Title>
      <NavItem to="/saved" exact>
        {t('common.newsfeeds')}
      </NavItem>
      <NavItem to="/saved/corps">
        {t('common.companies')}
      </NavItem>
    </NavContainer>
  );
};

const Content = styled.div`
  flex: 1;
`;

const SavedScreen: React.FC = () => {
  useLoginRequired();

  return (
    <HeaderLayout>
      <Container>
        <Nav />
        <Content>
          <Switch>
            <Route exact path="/saved">
              <SavedInsightList />
            </Route>
            <Route path="/saved/corps">
              <SavedCorpList />
            </Route>
          </Switch>
        </Content>
      </Container>
    </HeaderLayout>
  );
};

export default SavedScreen;
