import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { CompanySuggestion } from '../../../dataTypes';

export type Query = {
  q: string;
  src: 'client' | 'contact'
}

interface Options {
  enabled: boolean;
}

const useClientsAutoCompleteAPI = (query: Query, options: Options) => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q = QueryString.stringify({
      q: query.q,
      src: query.src,
      per_page: 10,
      page: pageParam,
    });

    const res = await api.get(`/clients/autocomplete?${q}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<CompanySuggestion>>(
    ['clients/autocomplete', query.q, query.src],
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
      enabled: options?.enabled,
    },
  );
};

export default useClientsAutoCompleteAPI;
