import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { Insight } from '../../../dataTypes';
import api, { PageData } from '../../../util/api';

const useToggleFavoriteNewsAPI = (uid: string, save: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      if (save) {
        return api.post('/favorites/news', { uid });
      }
      return api.delete(`/favorites/news/${uid}`);
    }, {
      onMutate: async () => {
        await queryClient.cancelQueries('favorites/news');

        const previousNewsList = queryClient.getQueryData('favorites/news');

        queryClient.setQueryData<InfiniteData<PageData<Insight>>>('favorites/news', (oldData) => {
          if (!oldData) return undefined;

          return {
            ...oldData,
            pages: oldData.pages
              .map((page) => {
                return {
                  ...page,
                  items: page.items.map((item) => {
                    return item.uid === uid ? { ...item, is_saved: save } : item;
                  }),
                };
              }),
          };
        });

        return { previousNewsList };
      },

      onError: (err, news, context) => {
        queryClient.setQueryData('favorites/news', context.previousNewsList);
      },

      onSettled: () => {
        queryClient.invalidateQueries('insights');
        queryClient.invalidateQueries('newsfeeds');
        queryClient.invalidateQueries('search');
        // NOTE: To keep saved list UI undoable, do not invalidate 'favorites/news' right away
      },
    },
  );
};

export default useToggleFavoriteNewsAPI;
