import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import TitledModal from '../common/TitledModal';
import { ClientIndustryDistribution } from '../../dataTypes';
import CompanyIndustryDistItem from './CompanyIndustryDistItem';

const ListContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const StyledCompanyIndustryDistItem = styled(CompanyIndustryDistItem)`
  padding: 12px 20px;
  background: #FFFFFF;
  box-shadow: 0px 3px 5px rgba(0, 100, 159, 0.08);
  border-radius: 10px;
`;

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  data: ClientIndustryDistribution[];
  color: d3.ScaleOrdinal<string, string, string>;
}

const CompanyIndustryDistModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  data,
  color,
}) => {
  const { t } = useTranslation();

  return (
    <TitledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t('home.company_industry_dist.title')}
    >
      <ListContainer>
        {
          data.map((item) => {
            return (
              <StyledCompanyIndustryDistItem
                key={item.industry}
                name={item.industry || t('common.others')}
                percentage={item.dist_percent}
                color={color(item.industry)}
              />
            );
          })
        }
      </ListContainer>
    </TitledModal>
  );
};

CompanyIndustryDistModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.exact({
    industry: PropTypes.string.isRequired,
    dist_percent: PropTypes.number.isRequired,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  color: PropTypes.any.isRequired,
};

export default CompanyIndustryDistModal;
