import * as React from 'react';
import * as PropTypes from 'prop-types';
import AutoCompleteInput from './AutoCompleteInput';
import useContactsAutoCompleteAPI from '../../../hooks/api/contacts/useContactsAutocompleteAPI';
import { getArrayFromInfiniteData } from '../../../util/api';
import { ContactSuggestion } from '../../../dataTypes';
import { getLocalizedName } from '../../../util/i18n';

interface IProps {
  className?: string;
  clientUid?: string;
  searchInput: string;
  // eslint-disable-next-line no-unused-vars
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  // eslint-disable-next-line no-unused-vars
  onSelect: (item?: ContactSuggestion) => any;
  // eslint-disable-next-line no-unused-vars
  onDismiss?: (e: MouseEvent) => any;
  disabled: boolean;
}

const AutoCompleteContactInput: React.FC<IProps> = ({
  className,
  clientUid,
  searchInput,
  onInputChange,
  onSelect,
  onDismiss,
  disabled,
}) => {
  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useContactsAutoCompleteAPI({ q: searchInput, client: clientUid }, { enabled: Boolean(clientUid) });

  const items = getArrayFromInfiniteData(data);

  return (
    <AutoCompleteInput
      className={className}
      inputValue={searchInput}
      onInputChange={onInputChange}
      onSelect={onSelect}
      onDismiss={onDismiss}
      items={items}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      keyExtractor={(item: ContactSuggestion) => item.contact_uid}
      renderItem={(item: ContactSuggestion) => getLocalizedName(item.contact_first_name, item.contact_last_name)}
      disabled={disabled}
    />
  );
};

AutoCompleteContactInput.defaultProps = {
  className: null,
  clientUid: null,
};

AutoCompleteContactInput.propTypes = {
  className: PropTypes.string,
  clientUid: PropTypes.string,
  searchInput: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AutoCompleteContactInput;
