import styled from 'styled-components';

const SubTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  color: #1B262C;
  margin-bottom: 8px;
  &:not(:first-child) {
    margin-top: 24px;
  }
`;

export default SubTitle;
