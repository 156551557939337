import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import HoverCard from '../common/HoverCard';
import clockImg from '../../images/clock.svg';
import { fromNow } from '../../util/datetime';
import useQuery from '../../hooks/useQuery';
import useDeleteNoticeAPI from '../../hooks/api/notices/useDeleteNoticeAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import MoreButton, { MoreButtonItem } from '../common/MoreButton';

type Props = {
  className?: string;
  iconSrc: string;
  text?: string;
  children?: React.ReactNode;
  timestamp: string;
  onClick?: React.MouseEventHandler;
  uid: string;
};

const Container = styled(HoverCard)`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  cursor: pointer;
`;

const Content = styled.div`
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
`;

const Timestamp = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
  opacity: 0.6;
  display: flex;
  align-items: center;
`;

const ClockImg = styled.div`
  width: 18px;
  height: 18px;
  background: center/contain no-repeat url(${clockImg});
  margin-right: 4px;
`;

const BaseNotice: React.FC<Props> = ({
  className,
  iconSrc,
  text,
  children,
  timestamp,
  onClick,
  uid,
}) => {
  const { t } = useTranslation();
  const query = useQuery();
  const { mutateAsync: deleteNoticeAPI } = useDeleteNoticeAPI();
  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  return (
    <Container className={className} onClick={onClick}>
      <Content>
        <Row>
          <Icon src={iconSrc} role="presentation" />
          <Text>{text || children}</Text>
        </Row>
        <Timestamp>
          <ClockImg />
          {fromNow(timestamp, t)}
        </Timestamp>
      </Content>
      {
        query.get('mode') === 'admin' && (
          <MoreButton>
            <MoreButtonItem
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  await deleteNoticeAPI(uid);
                  enqueueGeneralSuccessSnackbar();
                } catch (err) {
                  Sentry.captureException(err);
                  enqueueGeneralErrorSnackbar();
                }
              }}
            >
              {t('common.delete')}
            </MoreButtonItem>
          </MoreButton>
        )
      }
    </Container>
  );
};

BaseNotice.defaultProps = {
  className: null,
  onClick: null,
  text: null,
  children: null,
};

BaseNotice.propTypes = {
  className: PropTypes.string,
  iconSrc: PropTypes.string.isRequired,
  text: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  uid: PropTypes.string.isRequired,
};

export default BaseNotice;
