import styled from 'styled-components';
import thumbnailImg from './images/thumbnail.svg';

interface IProps {
  url?: string;
}

const UserThumbnail = styled.div<IProps>`
  width: 40px;
  height: 40px;
  background-image: url(${(props) => (props.url ? props.url : thumbnailImg)});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  flex-shrink: 0;
`;

export default UserThumbnail;
