/* eslint-disable camelcase */
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

type UpdateProfileData = {
  first_name: string;
  last_name: string;
  phonetic_first_name?: string;
  phonetic_last_name?: string;
  local_first_name?: string;
  local_last_name?: string;
  share_reg_email: boolean;
  company_name: string;
  department?: string;
  job_title: string;
  work_email: string;
  tel?: string;
  mobile?: string;
  address?: string;
  photo_uid?: string;
  photo_url?: string;
  bizcard_front_uid?: string;
  bizcard_front_url?: string;
  bizcard_back_uid?: string;
  bizcard_back_url?: string;
  is_setup_profile: boolean;
}

const useUpdateMyProfileAPI = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: UpdateProfileData) => {
      return api.patch('/profile/me', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('profile/me');
      },
    },
  );

  return mutation;
};

export default useUpdateMyProfileAPI;
