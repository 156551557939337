import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

type PostData = {
  uid: string;
}

const useReadNoticeAPI = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: PostData) => {
      return api.post('/notices/read', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notices');
      },
    },
  );

  return mutation;
};

export default useReadNoticeAPI;
