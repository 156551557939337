import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import Input from './Input';
import InfiniteScroll from '../InfiniteScroll';
import { CompanySuggestion } from '../../../dataTypes';
import useClickOutsideHandler from '../../../hooks/useClickOutsideHandler';
import { getCardStyles } from '../../../util/styles';

const Container = styled.div`
  position: relative;
`;

const SearchInput = styled(Input)`
  width: 100%;
`;

const OptionsInifiniteScroll = styled(InfiniteScroll)`
  ${getCardStyles()}
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  right: 0;
  z-index: 1;
  overflow-y: auto;
  overscroll-behavior: contain;
  max-height: 108px;
  margin: 0;
`;

const Option = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background: rgba(1, 170, 193, 0.1);
  }
`;

interface IProps {
  className?: string;
  // eslint-disable-next-line no-unused-vars
  inputValue: string;
  // eslint-disable-next-line no-unused-vars
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  // eslint-disable-next-line no-unused-vars
  onSelect: (item: any) => any;
  // eslint-disable-next-line no-unused-vars
  onDismiss?: (e: MouseEvent) => any;
  items: any[];
  fetchNextPage: () => any,
  hasNextPage: boolean,
  isFetchingNextPage: boolean;
  status: string;
  // eslint-disable-next-line no-unused-vars
  keyExtractor: (item: any) => string;
  // eslint-disable-next-line no-unused-vars
  renderItem: (item: any) => React.ReactNode;
  disabled?: boolean;
}

const AutoCompleteInput: React.FC<IProps> = ({
  className,
  inputValue,
  onInputChange,
  onSelect,
  onDismiss,
  items,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  status,
  keyExtractor,
  renderItem,
  disabled,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const containerRef = useClickOutsideHandler((e) => {
    setExpanded(false);
    if (typeof onDismiss === 'function') {
      onDismiss(e);
    }
  });

  return (
    <Container className={className} ref={containerRef}>
      <SearchInput
        value={inputValue}
        onChange={onInputChange}
        onFocus={() => setExpanded(true)}
        disabled={disabled}
      />
      {
        expanded && (
          <OptionsInifiniteScroll
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            status={status}
            isEmpty={items.length === 0}
            showLoading={false}
            showNoData={false}
          >
            {
              items.map((item: CompanySuggestion) => {
                return (
                  <Option
                    key={keyExtractor(item)}
                    onClick={() => {
                      // HACK: delay setting new input so checking click outside handler happens before the re-render
                      setTimeout(() => {
                        onSelect(item);
                        setExpanded(false);
                      }, 0);
                    }}
                  >
                    {renderItem(item)}
                  </Option>
                );
              })
            }
          </OptionsInifiniteScroll>
        )
      }
    </Container>
  );
};

AutoCompleteInput.defaultProps = {
  className: null,
  onDismiss: null,
  hasNextPage: false,
  disabled: false,
};

AutoCompleteInput.propTypes = {
  className: PropTypes.string,
  inputValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  keyExtractor: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AutoCompleteInput;
