import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import HoverCard from '../common/HoverCard';

const ItemContainer = styled(HoverCard)`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  box-shadow: none;
  ${(props) => props.onClick && 'cursor: pointer;'}
`;

const ItemName = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #1B262C;
`;

const ItemPercentage = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
  margin-right: 4px;
`;

const ItemLegend = styled.div<{color: string}>`
  width: 18px;
  height: 18px;
  background: ${(props) => props.color};
`;

type ItemProps = {
  className?: string;
  name: string;
  percentage: number;
  color: string;
  onClick?: React.MouseEventHandler;
}

const CompanyIndustryDistItem: React.FC<ItemProps> = ({
  className,
  name,
  percentage,
  color,
  onClick,
}) => {
  return (
    <ItemContainer className={className} onClick={onClick}>
      <ItemName>{name}</ItemName>
      <ItemPercentage>
        {Math.round(percentage * 100)}
        %
      </ItemPercentage>
      <ItemLegend color={color} />
    </ItemContainer>
  );
};

CompanyIndustryDistItem.defaultProps = {
  className: null,
  onClick: null,
};

CompanyIndustryDistItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default CompanyIndustryDistItem;
