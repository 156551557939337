import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import emailImg from './images/email.svg';
import telImg from './images/tel.svg';
import mobileImg from './images/mobile.svg';
import addressImg from './images/address.svg';
import companyImg from './images/company.svg';

const Container = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const DetailItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: #1B262C;
  font-size: 13px;
  line-height: 20px;
`;

const DetailIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 6px;
`;

interface IDetailItemProps {
  value: string;
  iconSrc: string;
  type?: 'email' | 'tel';
}

const DetailItem: React.FC<IDetailItemProps> = ({ value, type, iconSrc }) => {
  let link;
  if (type === 'email') {
    link = <a href={`mailto:${value}`}>{value}</a>;
  } else if (type === 'tel') {
    link = <a href={`tel:${value}`}>{value}</a>;
  } else {
    link = value;
  }

  return (
    <DetailItemContainer>
      <DetailIcon src={iconSrc} role="presentation" />
      {link}
    </DetailItemContainer>
  );
};

DetailItem.defaultProps = {
  type: null,
};

DetailItem.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['email', 'tel']),
  iconSrc: PropTypes.string.isRequired,
};

interface IProps {
  className?: string,
  address?: string;
  email?: string;
  workEmail?: string;
  mobile?: string;
  company?: string;
  tel?: string;
}

const ContactInfo: React.FC<IProps> = ({
  className,
  email,
  workEmail,
  tel,
  mobile,
  company,
  address,
}) => {
  return (
    <Container className={className}>
      {email && <DetailItem value={email} type="email" iconSrc={emailImg} />}
      {workEmail && <DetailItem value={workEmail} type="email" iconSrc={emailImg} />}
      {tel && <DetailItem value={tel} type="tel" iconSrc={telImg} />}
      {mobile && <DetailItem value={mobile} type="tel" iconSrc={mobileImg} />}
      {company && <DetailItem value={company} iconSrc={companyImg} />}
      {address && <DetailItem value={address} iconSrc={addressImg} />}
    </Container>
  );
};

ContactInfo.defaultProps = {
  className: null,
  email: null,
  workEmail: null,
  tel: null,
  mobile: null,
  company: null,
  address: null,
};

ContactInfo.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  workEmail: PropTypes.string,
  tel: PropTypes.string,
  mobile: PropTypes.string,
  company: PropTypes.string,
  address: PropTypes.string,
};

export default ContactInfo;
