import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import InfiniteScroll from '../common/InfiniteScroll';
import useGetTasksDueAPI from '../../hooks/api/calling-notes/useGetTasksDueAPI';
import { getArrayFromInfiniteData } from '../../util/api';
import NoteCard from '../notes/NoteCard';

const Container = styled.div`
  & > * {
    position: relative;
    box-shadow: none;

    /* separator */
    &:not(:last-child):after {
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #01AAC1;
      opacity: 0.1;
      z-index: 1;
    }
  }
`;

interface IProps {
  className?: string;
}

const TaskDueList: React.FC<IProps> = ({
  className,
}) => {
  const {
    data, fetchNextPage, hasNextPage, isFetchingNextPage, status,
  } = useGetTasksDueAPI();

  const notes = getArrayFromInfiniteData(data);

  return (
    <InfiniteScroll
      className={className}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      isEmpty={notes.length === 0}
    >
      <Container>
        {
          notes.map((note) => {
            return (
              <NoteCard key={note.uid} note={note} />
            );
          })
        }
      </Container>
    </InfiniteScroll>
  );
};

TaskDueList.defaultProps = {
  className: null,
};

TaskDueList.propTypes = {
  className: PropTypes.string,
};

export default TaskDueList;
