import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import InsightCard from './InsightCard';
import { Insight, InsightPropTypes } from '../../dataTypes';
import InfiniteScroll from '../common/InfiniteScroll';

const StyledInsightCard = styled(InsightCard)`
  margin: 12px 0;
`;

interface IProps {
  className?: string;
  insights: Insight[];
  fetchNextPage: () => any;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  status: string;
  observeBrowserViewport?: boolean;
}

const InsightListView: React.FC<IProps> = ({
  className,
  insights,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  status,
  observeBrowserViewport,
}) => {
  return (
    <InfiniteScroll
      className={className}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
      isEmpty={insights.length === 0}
      observeBrowserViewport={observeBrowserViewport}
    >
      {
        insights.map((item) => (
          <StyledInsightCard
            key={item.uid}
            insight={item}
          />
        ))
      }
    </InfiniteScroll>
  );
};

InsightListView.defaultProps = {
  className: null,
  hasNextPage: false,
  observeBrowserViewport: false,
};

InsightListView.propTypes = {
  className: PropTypes.string,
  insights: PropTypes.arrayOf(InsightPropTypes).isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  observeBrowserViewport: PropTypes.bool,
};

export default InsightListView;
