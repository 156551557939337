import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TitledModal from '../common/TitledModal';
import { Lead, LeadPropTypes } from '../../dataTypes';
import locationImg from './images/modal-location.svg';
import websiteImg from './images/modal-website.svg';
import StockPrice from '../common/StockPrice';
import Card from '../common/Card';
import SaveButton from '../common/SaveButton';
import useToggleFavoriteCorpAPI from '../../hooks/api/favorites/useToggleFavoriteCorpAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';

const TitleCard = styled(Card)`
  position: relative;
  padding: 12px;
  margin-bottom: 12px;
`;

const NameContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const StyledSaveButton = styled(SaveButton)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 12px;
  object-fit: contain;
`;

const Name = styled.h1`
  font-size: 18px;
  color: #1B262C;
  margin: 0;
`;

const IndustryContainer = styled.div`
  font-size: 12px;
  color: #1B262C;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescCard = styled(Card)`
  padding: 12px 20px;
`;

const DescIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const DescText = styled.div`
  margin-left: 8px;
`;

const DescItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  color: #1B262C;
  margin-bottom: 8px;
`;

type DescItemProps = {
  iconSrc: string;
  text?: string;
  url?: string;
  phone?: string;
}

const DescItem: React.FC<DescItemProps> = ({
  iconSrc,
  text,
  url,
  phone,
}) => {
  let desc;
  if (url) {
    desc = <a href={url} target="_blank" rel="noreferrer"><DescText>{url}</DescText></a>;
  } else if (phone) {
    desc = <a href={`tel://${phone}`}><DescText>{phone}</DescText></a>;
  } else {
    desc = <DescText>{text}</DescText>;
  }

  return (
    <DescItemContainer>
      <DescIcon src={iconSrc} />
      {desc}
    </DescItemContainer>
  );
};

DescItem.defaultProps = {
  text: null,
  url: null,
  phone: null,
};

DescItem.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  text: PropTypes.string,
  url: PropTypes.string,
  phone: PropTypes.string,
};

const DescTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #1B262C;
  margin-bottom: 12px;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
`;

const StyledStockPrice = styled(StockPrice)`
  margin-bottom: 12px;
`;

type Props = {
  isOpen: boolean;
  onRequestClose: () => any;
  client: Lead;
}

const LeadModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  client,
}) => {
  const {
    name,
    logo,
    industry,
    is_saved: isSaved,
    address,
    website,
    description,
    price,
    price_change: priceChange,
    price_change_percent: priceChangePercent,
    sec_code: secCode,
    uid,
  } = client;

  const { t } = useTranslation();
  const { mutateAsync: toggleFavoriteCorpAPI } = useToggleFavoriteCorpAPI(uid, !isSaved);
  const { enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  return (
    <TitledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={name}
    >
      <TitleCard>
        <NameContainer>
          <StyledSaveButton
            isSaved={isSaved}
            onClick={async (e) => {
              e.stopPropagation();
              try {
                await toggleFavoriteCorpAPI();
              } catch (err) {
                enqueueGeneralErrorSnackbar();
                throw err;
              }
            }}
          />
          {logo && <Logo src={logo} />}
          <Name>{name}</Name>
        </NameContainer>
        { industry && (
          <IndustryContainer>
            {industry}
          </IndustryContainer>
        )}
      </TitleCard>
      <DescCard>
        { address && <DescItem iconSrc={locationImg} text={address} /> }
        { website && <DescItem iconSrc={websiteImg} url={website} /> }
        { price && (
          <StyledStockPrice
            price={price}
            priceChange={priceChange}
            priceChangePercent={priceChangePercent}
            secCode={secCode}
          />
        )}
        <DescTitle>{t('common.description')}</DescTitle>
        <Desc>{description}</Desc>
      </DescCard>
    </TitledModal>
  );
};

LeadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  client: LeadPropTypes.isRequired,
};

export default LeadModal;
