import * as React from 'react';
import * as PropTypes from 'prop-types';

interface IProps {
  className?: string;
  src?: string;
  alt?: string;
  role?: string;
  minNaturalWidth: number;
  minNaturalHeight: number;
  onImageHidden?: () => void;
}

const NonBlurryImage: React.FC<IProps> = ({
  className,
  src,
  alt,
  role,
  minNaturalWidth,
  minNaturalHeight,
  onImageHidden,
}) => {
  const [showCoverImage, setShowCoverImage] = React.useState(true);

  if (!src || !showCoverImage) return null;

  return (
    <img
      className={className}
      src={src}
      alt={alt}
      role={role}
      onLoad={(e) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < minNaturalWidth || naturalHeight < minNaturalHeight) {
          setShowCoverImage(false);
          if (typeof onImageHidden === 'function') {
            onImageHidden();
          }
        }
      }}
      onError={() => {
        setShowCoverImage(false);
        if (typeof onImageHidden === 'function') {
          onImageHidden();
        }
      }}
    />
  );
};

NonBlurryImage.defaultProps = {
  className: null,
  src: null,
  alt: null,
  role: null,
  onImageHidden: null,
};

NonBlurryImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  role: PropTypes.string,
  minNaturalWidth: PropTypes.number.isRequired,
  minNaturalHeight: PropTypes.number.isRequired,
  onImageHidden: PropTypes.func,
};

export default NonBlurryImage;
