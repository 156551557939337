/* eslint-disable camelcase */
import { useMutation, useQueryClient } from 'react-query';
import { LifeCycleStage } from '../../../dataTypes';
import api from '../../../util/api';

interface UpdateContactData {
  first_name: string;
  last_name: string;
  phonetic_first_name?: string;
  phonetic_last_name?: string;
  local_first_name?: string;
  local_last_name?: string;
  // TODO: bizcard photo
  // bizcard_front_uid?: string;
  // bizcard_front_url?: string;
  // bizcard_back_uid?: string;
  // bizcard_back_url?: string;
  company_name: string;
  department?: string;
  job_title: string;
  email: string;
  tel?: string;
  mobile?: string;
  memo: string;
  address?: string;
  life_cycle_stage: LifeCycleStage;
}

const useUpdateContactAPI = (uid: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: UpdateContactData) => {
      return api.patch(`/contacts/${uid}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
        queryClient.invalidateQueries('search');
      },
    },
  );

  return mutation;
};

export default useUpdateContactAPI;
