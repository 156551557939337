import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import moreImg from './images/more.svg';

const Container = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;

const Button = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: center/contain no-repeat url(${moreImg});
  &:hover {
    opacity: 0.6;
  }
`;

const ItemsContainer = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  padding: 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  z-index: 1;
`;

export const MoreButtonItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 60px;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &:hover {
    background: rgba(1, 170, 193, 0.12);
  }
`;

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const MoreButton: React.FC<IProps> = ({
  className,
  children,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const containerRef = useClickOutsideHandler(() => {
    setIsExpanded(false);
  });

  return (
    <Container ref={containerRef} className={className}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      />
      { isExpanded && (
        <ItemsContainer>
          {
            React.Children.map(children, (child) => {
              // eslint-disable-next-line no-unused-vars
              const item = child as React.ReactElement<React.PropsWithChildren<{onClick: (e: React.MouseEvent) => any}>>;
              const onClick = (e: React.MouseEvent) => {
                setIsExpanded(false);
                item.props.onClick(e);
              };
              return React.cloneElement(item, { onClick });
            })
          }
        </ItemsContainer>
      )}
    </Container>
  );
};

MoreButton.defaultProps = {
  className: null,
};

MoreButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default MoreButton;
