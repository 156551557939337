import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { store } from './store';
import HomeScreen from './components/home/HomeScreen';
import LoginScreen from './components/login/LoginScreen';
import InsightScreen from './components/insight/InsightScreen';
import CompanyScreen from './components/company/CompanyScreen';
import ContactScreen from './components/contacts/ContactScreen';
import NotesScreen from './components/notes/NotesScreen';
import SearchScreen from './components/search/SearchScreen';
import ProfileScreen from './components/profile/ProfileScreen';
import SavedScreen from './components/saved/SavedScreen';
import ResetPaswordScreen from './components/reset/ResetPasswordScreen';
import ErrorScreen from './components/common/ErrorScreen';
import NavLayout from './components/layouts/NavLayout';
import NotificationScreen from './components/notifications/NotificationScreen';
import ModalRoot from './components/layouts/ModalRoot';
import queryClient from './util/queryClient';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Helvetica', 'Arial', sans-serif;
    min-height: 100vh;
    background: #F0F0F0;
  }

  a {
    color: #00649F;
    text-decoration: none;
  }
`;

const StyledErrorScreen = styled(ErrorScreen)`
  height: 100vh;
`;

const PlainLayout: React.FC = ({ children }) => {
  return (
    <Switch>
      <Route path="/login">
        <LoginScreen />
      </Route>
      <Route path="/reset/:token">
        <ResetPaswordScreen />
      </Route>
      <Route path="/not-found">
        {/* TODO: 404 screen */}
        Not found
      </Route>
      <Route path="/">
        {children}
      </Route>
    </Switch>
  );
};

PlainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const App = () => (
  <>
    <GlobalStyle />
    <Sentry.ErrorBoundary fallback={<StyledErrorScreen />} showDialog>
      <React.Suspense fallback="loading...">
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
              <Router>
                <PlainLayout>
                  <NavLayout>
                    <ModalRoot />
                    <Switch>
                      <Route exact path="/">
                        <HomeScreen />
                      </Route>
                      <Route path="/insight">
                        <InsightScreen />
                      </Route>
                      <Route path="/companies">
                        <CompanyScreen />
                      </Route>
                      <Route path="/contacts">
                        <ContactScreen />
                      </Route>
                      <Route path="/notes">
                        <NotesScreen />
                      </Route>
                      <Route path="/search">
                        <SearchScreen />
                      </Route>
                      <Route path="/profile">
                        <ProfileScreen />
                      </Route>
                      <Route path="/saved">
                        <SavedScreen />
                      </Route>
                      <Route path="/notifications">
                        <NotificationScreen />
                      </Route>
                      <Route path="*">
                        <Redirect to="/not-found" />
                      </Route>
                    </Switch>
                  </NavLayout>
                </PlainLayout>
              </Router>
            </SnackbarProvider>
          </QueryClientProvider>
        </Provider>
      </React.Suspense>
    </Sentry.ErrorBoundary>
  </>
);

export default App;
