/* eslint-disable camelcase */
import { useMutation, useQueryClient } from 'react-query';
import { ActivityType } from '../../../dataTypes';
import api from '../../../util/api';

interface UpdateData {
  summary: string;
  tags_text?: string;
  activity_type: ActivityType;
  due_dt: string;
  contact_uid?: string;
  is_public: boolean;
  client_uid: string;
  task_content?: string;
  task_due_dt?: string;
  task_is_done: boolean;
}

const useUpdateCallingNoteAPI = (uid: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: UpdateData) => {
      return api.patch(`/calling_notes/${uid}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('calling_notes');
        queryClient.invalidateQueries('search');
      },
    },
  );

  return mutation;
};

export default useUpdateCallingNoteAPI;
