import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { Insight } from '../../../dataTypes';

interface Query {
  mode: 'default' | 'business';
}

const useGetInsightsAPI = (query: Query = { mode: 'default' }) => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q = QueryString.stringify({
      per_page: 10,
      page: pageParam,
      mode: query.mode,
    });

    const res = await api.get(`/insights?${q}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<Insight>>(
    ['insights', query.mode],
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
    },
  );
};

export default useGetInsightsAPI;
