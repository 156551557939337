import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import usFlagImg from './images/us-flag.svg';
import jpFlagImg from './images/jp-flag.svg';
import exchangeImg from './images/exchange.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  min-width: 0;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
`;

const Flag = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

const USFlag = styled(Flag)`
  background: center/contain no-repeat url(${usFlagImg});
`;

const JPFlag = styled(Flag)`
  background: center/contain no-repeat url(${jpFlagImg});
`;

const Input = styled.input`
  border: 1px solid rgba(1, 170, 193, 0.2);
  border-radius: 8px;
  padding: 0 8px 0 40px;
  width: 100%;
  height: 40px;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
`;

const ExchangeImg = styled.div`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  background: center/contain no-repeat url(${exchangeImg});
  margin: 0 12px;
`;

type Props = {
  className?: string;
  USD: string;
  JPY: string;
  // eslint-disable-next-line no-unused-vars
  onChangeUSD?: (USD: string) => void;
  // eslint-disable-next-line no-unused-vars
  onChangeJPY?: (JPY: string) => void;
  readOnly?: boolean;
}

const ExchangeRateInput: React.FC<Props> = ({
  className,
  USD,
  JPY,
  onChangeUSD,
  onChangeJPY,
  readOnly,
}) => {
  return (
    <Container className={className}>
      <InputContainer>
        <USFlag />
        <Input
          type="number"
          value={USD}
          readOnly={readOnly}
          onChange={(e) => onChangeUSD(e.target.value)}
          step={0.0001}
          min={0.0001}
          required
        />
      </InputContainer>
      <ExchangeImg />
      <InputContainer>
        <JPFlag />
        <Input
          type="number"
          value={JPY}
          readOnly={readOnly}
          onChange={(e) => onChangeJPY(e.target.value)}
          step={0.0001}
          min={0.0001}
          required
        />
      </InputContainer>
    </Container>
  );
};

ExchangeRateInput.defaultProps = {
  className: null,
  onChangeUSD: null,
  onChangeJPY: null,
  readOnly: false,
};

ExchangeRateInput.propTypes = {
  className: PropTypes.string,
  USD: PropTypes.string.isRequired,
  JPY: PropTypes.string.isRequired,
  onChangeUSD: PropTypes.func,
  onChangeJPY: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ExchangeRateInput;
