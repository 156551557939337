import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import TitledModal from '../common/TitledModal';
import { Contact, ContactPropTypes } from '../../dataTypes';
import userThumbnailImg from '../../images/user-thumbnail.svg';
import { getLocalizedName } from '../../util/i18n';
import callingNoteImg from './images/calling-note.svg';
import editImg from '../../images/edit.svg';
import clockImg from '../../images/clock.svg';
import useGetCallingNotesAPI from '../../hooks/api/calling-notes/useGetCallingNotesAPI';
import editModalButtonImg from './images/edit.svg';
import AddOrEditContactModal from './AddOrEditContactModal';
import CompanyModal from '../company/CompanyModal';
import useGetClientAPI from '../../hooks/api/clients/useGetClientAPI';
import chevronLeftImg from './images/modal-chevron-left.svg';
import NoteList from '../notes/NoteList';
import { getCardHoverStyles } from '../../util/styles';
import Card from '../common/Card';
import ContactInfo from '../common/ContactInfo';
import BizcardList from '../common/BizcardList';
import Loading from '../common/status/Loading';
import GeneralError from '../common/status/GeneralError';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => any;
  contact?: Contact;
  status?: string;
}

const StyledCard = styled(Card)`
  position: relative;
  padding: 12px 20px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

const Thumb = styled.div`
  width: 40px;
  height: 40px;
  background: center/contain no-repeat url(${userThumbnailImg});
  margin-right: 12px;
`;

const Name = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #1B262C;
`;

const CompanyContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
`;

const CompanyNameContainer = styled.div`
  color: #00649F;
  text-decoration: underline;
  cursor: pointer;
`;

const Dot = styled.span`
  color: #01AAC1;
`;

const EditButton = styled.div`
  background: center/contain no-repeat url(${editModalButtonImg});
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

const CallingNoteCard = styled(StyledCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${getCardHoverStyles()}
  }
`;

const CallingNoteImg = styled.div`
  width: 24px;
  height: 24px;
  background: center/contain no-repeat url(${callingNoteImg});
  margin-bottom: 4px;
`;

const Notes = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: #1B262C;
  opacity: 0.6;
`;

const NoteCount = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #1B262C;
`;

const DetailIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 6px;
`;

const DescriptionTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
  margin: 12px 0;
`;

const DescriptionSubTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.6;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LifeCycleStagesTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  margin: 8px 0;
`;

const LifeCycleList = styled.div`
 & > *:not(:last-child) {
   margin-bottom: 12px;
 }
`;

const LifeCycleContainer = styled(Card)`
  border-left: 8px solid #01AAC1;
  padding: 12px 20px;
`;

const LifeCycleStage = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
`;

const LifeCycleDescription = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
  opacity: 0.6;
`;

const BackButton = styled.div`
  display: inline-flex;
  align-items: center;
  height: 48px;
  background: #FFFFFF;
  border-radius: 10px;
  font-size: 12px;
  color: #1B262C;
  padding: 0 16px 0 8px;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    ${getCardHoverStyles()}
  }

  &::before {
    width: 24px;
    height: 24px;
    display: block;
    content: '';
    background-image: url(${chevronLeftImg});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const StyledNoteList = styled(NoteList)`
  max-height: 75vh; /* a workaround to make infinite scroll work */
`;

interface ILifeCycleProps {
  stage: string;
  ownerName: string;
  ownerDepartment?: string;
  ownerJobTitle: string;
}

const LifeCycleCard: React.FC<ILifeCycleProps> = ({
  stage,
  ownerName,
  ownerDepartment,
  ownerJobTitle,
}) => {
  const { t } = useTranslation();
  return (
    <LifeCycleContainer>
      <LifeCycleStage>{t(`common.lifecycle_stages.${stage}`)}</LifeCycleStage>
      <LifeCycleDescription>{ownerName}</LifeCycleDescription>
      <LifeCycleDescription>
        {ownerJobTitle}
        {ownerDepartment && (
          <>
            <Dot>．</Dot>
            {ownerDepartment}
          </>
        )}
      </LifeCycleDescription>
    </LifeCycleContainer>
  );
};

LifeCycleCard.defaultProps = {
  ownerDepartment: null,
  ownerJobTitle: null,
};

LifeCycleCard.propTypes = {
  stage: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  ownerDepartment: PropTypes.string,
  ownerJobTitle: PropTypes.string,
};

const ContactModal: React.FC<IProps> = ({
  isOpen,
  onRequestClose,
  contact,
  status,
}) => {
  const { t } = useTranslation();
  const { data } = useGetCallingNotesAPI({
    filter: 'ai',
    rankBy: 'create_dt_dsc',
    contact: contact?.uid,
  }, { enabled: Boolean(isOpen && contact?.uid) });
  const callingNoteCount = (data?.pages?.length > 0) ? (data.pages[0]?.count) : 0;

  const [tab, setTab] = React.useState<'main'|'notes'>('main');
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  const [isCompanyModalOpen, setIsCompanyModalOpen] = React.useState(false);
  const { data: client } = useGetClientAPI(contact?.client_uid, { enabled: Boolean(isOpen && contact?.client_uid) });

  const onClose = () => {
    setTab('main');
    onRequestClose();
  };

  if (!contact) {
    let body;
    if (status === 'loading') {
      body = <Loading />;
    } else if (status === 'error') {
      body = <GeneralError />;
    }

    if (!body) return null;

    return (
      <TitledModal
        isOpen={isOpen}
        onRequestClose={onClose}
        title=""
      >
        {body}
      </TitledModal>
    );
  }

  let body;
  if (tab === 'main') {
    body = (
      <>
        <StyledCard>
          <NameContainer>
            <Thumb />
            <Name>{getLocalizedName(contact?.first_name, contact?.last_name)}</Name>
          </NameContainer>
          <CompanyContainer>
            <CompanyNameContainer onClick={() => setIsCompanyModalOpen(true)}>
              {contact?.company_name}
            </CompanyNameContainer>
            {contact?.job_title && (
            <>
              <Dot>．</Dot>
              {contact?.job_title}
            </>
            )}
          </CompanyContainer>
          <EditButton onClick={() => setIsEditModalOpen(true)} />
        </StyledCard>

        <CallingNoteCard
          onClick={() => {
            if (callingNoteCount > 0) {
              setTab('notes');
            }
          }}
        >
          <CallingNoteImg />
          <Notes>{t('common.notes')}</Notes>
          <NoteCount>{callingNoteCount}</NoteCount>
        </CallingNoteCard>

        <StyledCard>
          <ContactInfo email={contact?.email} tel={contact?.tel} mobile={contact?.mobile} address={contact?.address} />

          <DescriptionTitle>{t('common.description')}</DescriptionTitle>

          {contact?.department && (
          <>
            <DescriptionSubTitle>{t('contact.modal.department')}</DescriptionSubTitle>
            <Description>{contact?.department}</Description>
          </>
          )}

          {contact?.job_title && (
          <>
            <DescriptionSubTitle>{t('contact.modal.job_title')}</DescriptionSubTitle>
            <Description>{contact?.job_title}</Description>
          </>
          )}

          {contact?.memo && (
          <>
            <DescriptionSubTitle>{t('contact.modal.memo')}</DescriptionSubTitle>
            <Description>{contact?.memo}</Description>
          </>
          )}

          <DescriptionSubTitle>{t('contact.modal.created_by')}</DescriptionSubTitle>
          <Description>
            <CenterContainer>
              <DetailIcon src={editImg} />
              {getLocalizedName(contact?.created_by?.first_name, contact?.created_by?.last_name)}
              {contact?.created_by?.job_title && (
              <>
                <Dot>．</Dot>
                {contact?.created_by?.job_title}
              </>
              )}
            </CenterContainer>
            <CenterContainer>
              <DetailIcon src={clockImg} role="presentation" />
              {dayjs(contact?.created_by?.dt).format(t('format.datetime.llll'))}
            </CenterContainer>
          </Description>
        </StyledCard>

        {(contact?.bizcard_front_url || contact?.bizcard_back_url) && (
          <BizcardList bizcardFrontUrl={contact?.bizcard_front_url} bizcardBackUrl={contact?.bizcard_back_url} />
        )}

        {contact?.life_cycles?.length > 0 && (
        <>
          <LifeCycleStagesTitle>{t('contact.modal.lifecycle_stages_title')}</LifeCycleStagesTitle>
          <LifeCycleList>
            {contact?.life_cycles.map((lifeCycle) => {
              const {
                stage,
                owner_first_name: ownerFirstName,
                owner_last_name: ownerLastName,
                owner_department: ownerDepartment,
                owner_job_title: ownerJobTitle,
                edit_dt: editDt,
              } = lifeCycle;
              return (
                <LifeCycleCard
                  key={editDt}
                  stage={stage}
                  ownerName={getLocalizedName(ownerFirstName, ownerLastName)}
                  ownerDepartment={ownerDepartment}
                  ownerJobTitle={ownerJobTitle}
                />
              );
            })}
          </LifeCycleList>
        </>
        )}
      </>
    );
  } else if (tab === 'notes') {
    body = (
      <>
        <BackButton onClick={() => setTab('main')}>{getLocalizedName(contact?.first_name, contact?.last_name)}</BackButton>
        <StyledNoteList
          contactUid={contact?.uid}
          initialFilter="all"
          initialRankBy="create_dt_dsc"
          showHeader={false}
          groupByDay={false}
        />
      </>
    );
  }

  return (
    <>
      <TitledModal
        isOpen={isOpen}
        onRequestClose={onClose}
        title={t('contact.modal.title')}
      >
        {body}
      </TitledModal>
      <AddOrEditContactModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        contact={contact}
      />
      <CompanyModal
        isOpen={isCompanyModalOpen}
        onRequestClose={() => setIsCompanyModalOpen(false)}
        client={client}
      />
    </>
  );
};

ContactModal.defaultProps = {
  contact: null,
  status: 'idle',
};

ContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  contact: ContactPropTypes,
  status: PropTypes.string,
};

export default ContactModal;
