import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { getCardHoverStyles } from '../../util/styles';
import contactImg from './images/contact.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 10px;
  padding: 8px;
  background: #fff;
  ${(props) => props.onClick && 'cursor: pointer;'}

  &:hover {
    ${getCardHoverStyles()}
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 15px;
  color: #1B262C;
  flex: 1;
`;

const Count = styled.div`
  font-size: 13px;
  color: #1B262C;
`;

const ContactIcon = styled.div`
  width: 18px;
  height: 18px;
  background: center/contain no-repeat url(${contactImg});
`;

type Props = {
  className?: string;
  name: string;
  count: number;
  onClick?: React.MouseEventHandler;
}

const ContactGeoDistItem: React.FC<Props> = ({
  className,
  name,
  count,
  onClick,
}) => {
  return (
    <Container onClick={onClick} className={className}>
      <Name>{name}</Name>
      <Count>{count}</Count>
      <ContactIcon />
    </Container>
  );
};

ContactGeoDistItem.defaultProps = {
  className: null,
  onClick: null,
};

ContactGeoDistItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default ContactGeoDistItem;
