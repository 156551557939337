import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

const useDeleteExchangeRateAlertAPI = (uid: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => api.delete(`/exchange_rates/alerts/${uid}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('exchange_rates/alerts');
      },
    },
  );

  return mutation;
};

export default useDeleteExchangeRateAlertAPI;
