import { useMutation } from 'react-query';
import api from '../../../util/api';

const useCreateClientPhotoAPI = () => {
  const mutation = useMutation(
    (file: File) => {
      const form = new FormData();
      form.append('photo', file);
      return api.post('/clients/photo', form);
    },
  );

  return mutation;
};

export default useCreateClientPhotoAPI;
