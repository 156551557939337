/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  CompanySuggestion, Contact, ContactPropTypes, LifeCycleStage,
} from '../../dataTypes';
import chevronImg from './images/chevron-down.svg';
import useCreateContactAPI from '../../hooks/api/contacts/useCreateContactAPI';
import useUpdateContactAPI from '../../hooks/api/contacts/useUpdateContactAPI';
import AutoCompleteCompanyInput from '../common/form-modal/AutoCompleteCompanyInput';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import FormModal from '../common/form-modal/FormModal';

interface FormData {
  first_name: string;
  last_name: string;
  phonetic_first_name?: string;
  phonetic_last_name?: string;
  local_first_name?: string;
  local_last_name?: string;
  // TODO: bizcard photo
  company_name: string;
  department?: string;
  job_title: string;
  email: string;
  tel?: string;
  mobile?: string;
  address?: string;
  life_cycle_stage: LifeCycleStage;
  memo: string;
}

interface IProps {
  isOpen: boolean;
  onRequestClose: () => any;
  contact?: Contact;
}

const SubTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  color: #1B262C;
  margin: 12px 0;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

interface LabelProps {
  required?: boolean;
}

const Label = styled.label<LabelProps>`
  font-size: 14px;
  line-height: 24px;
  color: #444150;
  margin-bottom: 8px;
  &::after {
    ${(props) => props.required && "content: ' *';"}
    color: #01AAC1;
  }
`;

const Input = styled.input`
  display: flex;
  align-items: center;
  height: 48px;
  background: #E5EFF5;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  font-size: 14px;
  padding: 0 16px;
`;

const SelectContainer = styled.div`
  position: relative;
  /* chevron img */
  &::after {
    content: '';
    background: center/contain no-repeat url(${chevronImg});
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const Select = styled.select`
  /* reset */
  position: relative;
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;

  display: flex;
  align-items: center;
  height: 48px;
  background: #E5EFF5;
  border: 1px solid rgba(1, 170, 193, 0.6);
  border-radius: 10px;
  font-size: 14px;
  padding: 0 16px;
`;

const DisplayPhoneticNamesButton = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #01AAC1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Chevron = styled.div`
  width: 24px;
  height: 24px;
  background: center/contain no-repeat url(${chevronImg});
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
`;

const AddOrEditContactModal: React.FC<IProps> = ({
  isOpen,
  onRequestClose,
  contact,
}) => {
  const { t } = useTranslation();
  const [companyName, setCompanyName] = React.useState(contact?.company_name || '');
  const {
    register, handleSubmit, formState: { errors, isSubmitSuccessful }, reset, setError,
  } = useForm<FormData>();
  const { mutateAsync: createContactAPI } = useCreateContactAPI();
  const { mutateAsync: updateContactAPI } = useUpdateContactAPI(contact?.uid);

  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  // Reset form after adding contact
  React.useEffect(() => {
    if (isSubmitSuccessful && !contact) {
      reset();
    }
  }, [contact, isSubmitSuccessful, reset]);

  // reset default value whenever contact changes or closing modal
  React.useEffect(() => {
    if (!isOpen) {
      reset({
        first_name: contact?.first_name,
        last_name: contact?.last_name,
        phonetic_first_name: contact?.phonetic_first_name,
        phonetic_last_name: contact?.phonetic_last_name,
        local_first_name: contact?.local_first_name,
        local_last_name: contact?.local_last_name,
        company_name: contact?.company_name,
        department: contact?.department,
        job_title: contact?.job_title,
        email: contact?.email,
        tel: contact?.tel,
        mobile: contact?.mobile,
        address: contact?.address,
        life_cycle_stage: contact?.life_cycle_stage,
        memo: contact?.memo,
      });
      setCompanyName(contact?.company_name || '');
    }
  }, [contact, reset, isOpen]);

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      const contactData = { ...formData };

      if (!companyName) {
        setError('company_name', { type: 'required' });
        return;
      }
      contactData.company_name = companyName;

      if (contact?.uid) {
        await updateContactAPI(contactData);
      } else {
        await createContactAPI(contactData);
      }

      onRequestClose();
      enqueueGeneralSuccessSnackbar();
    } catch (err) {
      switch (err?.response?.data?.code) {
        case 'contact_already_exists':
          setError('email', {
            type: 'contact_already_exists',
            message: err.response.data.message,
          });
          break;
        default:
          enqueueGeneralErrorSnackbar();
          throw err;
      }
    }
  };

  const [showPhoneticName, setShowPhoneticName] = React.useState(false);

  return (
    <FormModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      title={contact ? t('contact.add_contact_modal.edit_title') : t('contact.add_contact_modal.add_title')}
    >
      <SubTitle>{t('contact.add_contact_modal.info')}</SubTitle>

      <Field>
        <Label required>{t('contact.add_contact_modal.first_name')}</Label>
        <Input type="text" {...register('first_name', { required: true })} />
        { errors?.first_name?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      <Field>
        <Label required>{t('contact.add_contact_modal.last_name')}</Label>
        <Input type="text" {...register('last_name', { required: true })} />
        { errors?.last_name?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      {!showPhoneticName && (
      <DisplayPhoneticNamesButton onClick={() => setShowPhoneticName(true)}>
        {t('contact.add_contact_modal.display_phonetic_names')}
        <Chevron />
      </DisplayPhoneticNamesButton>
      )}

      {showPhoneticName && (
      <>
        <Field>
          <Label>{t('contact.add_contact_modal.phonetic_first_name')}</Label>
          <Input type="text" {...register('phonetic_first_name')} />
        </Field>

        <Field>
          <Label>{t('contact.add_contact_modal.phonetic_last_name')}</Label>
          <Input type="text" {...register('phonetic_last_name')} />
        </Field>

        <Field>
          <Label>{t('contact.add_contact_modal.local_first_name')}</Label>
          <Input type="text" {...register('local_first_name')} />
        </Field>

        <Field>
          <Label>{t('contact.add_contact_modal.local_last_name')}</Label>
          <Input type="text" {...register('local_last_name')} />
        </Field>
      </>
      )}

      <SubTitle>{t('contact.add_contact_modal.contact_details')}</SubTitle>

      <Field>
        <Label required>{t('contact.add_contact_modal.company_name')}</Label>
        <AutoCompleteCompanyInput
          src="contact"
          searchInput={companyName}
          onInputChange={(e) => {
            setCompanyName(e.target.value);
          }}
          onSelect={(selected: CompanySuggestion) => {
            setCompanyName(selected.name);
          }}
        />
        { errors?.company_name?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.department')}</Label>
        <Input type="text" {...register('department')} />
      </Field>

      <Field>
        <Label required>{t('contact.add_contact_modal.job_title')}</Label>
        <Input type="text" {...register('job_title', { required: true })} />
        { errors?.job_title?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      <Field>
        <Label required>{t('contact.add_contact_modal.email')}</Label>
        <Input type="email" {...register('email', { required: true })} />
        { errors?.email?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
        { errors?.email?.type === 'contact_already_exists' && (
        <ErrorMessage>{t('company.add_company_modal.contact_already_exists')}</ErrorMessage>
        )}
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.tel')}</Label>
        <Input type="tel" {...register('tel')} />
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.mobile')}</Label>
        <Input type="tel" {...register('mobile')} />
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.address')}</Label>
        <Input type="text" {...register('address')} />
      </Field>

      <SubTitle>{t('contact.add_contact_modal.contact_notes')}</SubTitle>

      <Field>
        <Label>{t('contact.add_contact_modal.life_cycle_stage')}</Label>
        <SelectContainer>
          <Select {...register('life_cycle_stage')}>
            <option value="approach">{t('common.lifecycle_stages.approach')}</option>
            <option value="lead">{t('common.lifecycle_stages.lead')}</option>
            <option value="pre_proposal">{t('common.lifecycle_stages.pre_proposal')}</option>
            <option value="proposal">{t('common.lifecycle_stages.proposal')}</option>
            <option value="contract">{t('common.lifecycle_stages.contract')}</option>
            <option value="complete">{t('common.lifecycle_stages.complete')}</option>
            <option value="postpone">{t('common.lifecycle_stages.postpone')}</option>
            <option value="cancel">{t('common.lifecycle_stages.cancel')}</option>
          </Select>
        </SelectContainer>
      </Field>

      <Field>
        <Label>{t('contact.add_contact_modal.memo')}</Label>
        <Input type="text" {...register('memo')} />
      </Field>
    </FormModal>
  );
};

AddOrEditContactModal.defaultProps = {
  contact: null,
};

AddOrEditContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  contact: ContactPropTypes,
};

export default AddOrEditContactModal;
