import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import closeImg from './images/close.svg';

export const MODAL_HEADER_HEIGHT = 40;

const HeaderContainer = styled.header`
  position: relative;
  height: ${MODAL_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #01AAC1;
  color: #fff;
`;

const CloseButton = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
  background-image: url(${closeImg});
  background-size: contain;
  &:hover {
    opacity: 0.6;
  }
`;

interface IProps {
  className?: string;
  title: string;
  onClose: () => any;
}

const ModalHeader: React.FC<IProps> = ({
  className,
  title,
  onClose,
}) => {
  return (
    <HeaderContainer className={className}>
      {title}
      <CloseButton onClick={onClose} />
    </HeaderContainer>
  );
};

ModalHeader.defaultProps = {
  className: null,
};

ModalHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalHeader;
