import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';
import closeImg from '../../images/close.svg';
import { fromNow } from '../../util/datetime';
import { Insight, InsightPropTypes } from '../../dataTypes';
import SaveButton from '../common/SaveButton';
import chevronImg from './images/chevron-right.svg';
import NonBlurryImage from '../common/NonBlurryImage';

const COVER_IMAGE_WIDTH = 560;
const COVER_IMAGE_HEIGHT = 244;

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  insight: Insight;
  // eslint-disable-next-line no-unused-vars
  onSaveButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const Container = styled.div`
  padding: 20px;
`;

const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${closeImg});
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  justify-items: flex-end;
  margin-left: auto;
  &:hover {
    opacity: 0.8;
  }
`;

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Description = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #1B262C;
  opacity: 0.6;
`;

const StyledCoverImage = styled(NonBlurryImage)`
  width: ${COVER_IMAGE_WIDTH}px;
  height: ${COVER_IMAGE_HEIGHT}px;
  object-fit: contain;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const Body = styled.p`
  padding: 0;
  font-size: 15px;
  line-height: 24px;
  color: #1B262C;
  margin: 12px 0 12px;
`;

const Link = styled.a`
  color: #01AAC1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
`;

const Chevron = styled.div`
  width: 24px;
  height: 24px;
  background: center/contain no-repeat url(${chevronImg});
`;

const InsightModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  insight,
  onSaveButtonClick,
}) => {
  const { t } = useTranslation();
  const {
    category,
    img_url: imgUrl,
    is_saved: isSaved,
    link,
    provider,
    published_dt: publishedDt,
    summary,
    title,
  } = insight;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Container>
        <CloseButton onClick={onRequestClose} />
        <Title>{title}</Title>
        <SubTitle>
          <Description>
            {provider}
            ・
            {t(`insight.topics.${category}`)}
            ・
            {fromNow(publishedDt, t)}
          </Description>
          <SaveButton isSaved={isSaved} onClick={onSaveButtonClick} />
        </SubTitle>
        <StyledCoverImage
          src={imgUrl}
          alt="Cover image"
          minNaturalHeight={COVER_IMAGE_HEIGHT}
          minNaturalWidth={COVER_IMAGE_WIDTH}
        />
        <Body>{summary}</Body>
        <Link href={link} target="_blank" rel="noreferrer">
          {t('insight.modal.read_original_article')}
          <Chevron />
        </Link>
      </Container>
    </Modal>
  );
};

InsightModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  insight: InsightPropTypes.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
};

export default InsightModal;
