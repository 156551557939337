import { configureStore } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import authReducer from './slices/authSlice';
import meReducer from './slices/meSlice';
import enterpriseAuthReducer from './slices/enterpriseAuthSlice';
import modalSlice from './slices/modalSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    me: meReducer,
    enterpriseAuth: enterpriseAuthReducer,
    modals: modalSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
