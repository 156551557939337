import { updateCompanyModal } from '../../slices/modalSlice';
import { useAppDispatch } from '../store';

const useOpenCompanyModal = () => {
  const dispatch = useAppDispatch();
  return (uid: string) => {
    dispatch(updateCompanyModal({ isOpen: true, uid }));
  };
};

export default useOpenCompanyModal;
