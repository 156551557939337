/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';

interface EnterpriseAuthState {
  name: string;
  uid: string;
  status: string;
}

const initialState: EnterpriseAuthState = {
  name: null,
  uid: null,
  status: null,
};

export const slice = createSlice({
  name: 'enterpriseAuth',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<EnterpriseAuthState>) => {
      const { name, uid, status } = action.payload;
      state.name = name;
      state.uid = uid;
      state.status = status;
    },
  },
});

export const { update } = slice.actions;

export const getIsEnterprise = (state: RootState) => state.enterpriseAuth.status === 'approved';

export default slice.reducer;
