import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import BaseNotice from './BaseNotice';
import contactImg from './images/contact.svg';
import { Notice, NoticePropTypes } from '../../dataTypes';
import { getLocalizedName } from '../../util/i18n';
import useOpenCompanyModal from '../../hooks/modals/useOpenCompanyModal';
import useReadNoticeAPI from '../../hooks/api/notices/useReadNoticeAPI';
import useOpenContactModal from '../../hooks/modals/useOpenContactModal';
import LinkButton from '../common/LinkButton';

type Props = {
  className?: string;
  notice: Notice;
};

const NewContactNotice: React.FC<Props> = ({
  className,
  notice,
}) => {
  const { producer, company, contact } = notice.data;
  const openCompanyModal = useOpenCompanyModal();
  const openContactModal = useOpenContactModal();

  const { mutateAsync: readNoticeAPI } = useReadNoticeAPI();

  const producerName = getLocalizedName(producer.first_name, producer.last_name);
  const companyName = company.name;
  const contactName = getLocalizedName(contact.first_name, contact.last_name);

  return (
    <BaseNotice
      className={className}
      uid={notice.uid}
      iconSrc={contactImg}
      timestamp={notice.create_dt}
      onClick={() => {
        readNoticeAPI({ uid: notice.uid });
        openContactModal(contact.uid);
      }}
    >
      <Trans i18nKey="notices.add_client">
        <LinkButton>
          {{ producerName }}
        </LinkButton>
        {' '}
        just connects with
        {' '}
        <LinkButton
          onClick={(e) => {
            e.stopPropagation();
            readNoticeAPI({ uid: notice.uid });
            openCompanyModal(company.uid);
          }}
        >
          {{ companyName }}
        </LinkButton>
        /
        <LinkButton onClick={(e) => {
          e.stopPropagation();
          readNoticeAPI({ uid: notice.uid });
          openContactModal(contact.uid);
        }}
        >
          {{ contactName }}
        </LinkButton>
      </Trans>
    </BaseNotice>
  );
};

NewContactNotice.defaultProps = {
  className: null,
};

NewContactNotice.propTypes = {
  className: PropTypes.string,
  notice: NoticePropTypes.isRequired,
};

export default NewContactNotice;
