import styled from 'styled-components';

interface IProps {
  required?: boolean;
}

const Label = styled.label<IProps>`
  font-size: 14px;
  line-height: 24px;
  color: #444150;
  margin-bottom: 8px;
  &::after {
    ${(props) => props.required && "content: ' *';"}
    color: #01AAC1;
  }
`;

export default Label;
