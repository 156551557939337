import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import addImg from './images/add.svg';

interface IProps {
  className?: string;
  onClick: () => any;
}

const AddButtonContainer = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
  background: linear-gradient(180deg, #00DBE7 0%, #00649F 100%);
  &:hover {
    background: linear-gradient(180deg, #00649F 0%, #00DBE7 100%);
  }
`;

const AddImg = styled.div`
  width: 28px;
  height: 28px;
  background-image: url(${addImg});
  background-position: center;
  background-size: 14px 14px;
  background-repeat: no-repeat;
`;

const AddButton: React.FC<IProps> = ({
  className,
  onClick,
}) => {
  return (
    <AddButtonContainer className={className} onClick={onClick}>
      <AddImg />
    </AddButtonContainer>
  );
};

AddButton.defaultProps = {
  className: null,
};

AddButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default AddButton;
