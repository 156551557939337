/* eslint-disable camelcase */
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

type UpdateClientData = {
  name: string;
  description?: string;
  industry?: string;
  sub_industry?: string;
  address?: string;
  phone?: string;
  website: string;
  photo_uid?: string;
  photo_url?: string;
};

const useUpdateClientAPI = (uid: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: UpdateClientData) => {
      return api.patch(`/clients/${uid}`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        queryClient.invalidateQueries('search');
      },
    },
  );

  return mutation;
};

export default useUpdateClientAPI;
