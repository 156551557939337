import * as React from 'react';
import useLoginRequired from '../../hooks/auth/useLoginRequired';
import HeaderLayout from '../layouts/HeaderLayout';
import NoteList from './NoteList';

const MainNoteList = () => <NoteList observeBrowserViewport />;

const NoteScreen: React.FC = () => {
  useLoginRequired();

  return (
    <HeaderLayout mainComponent={MainNoteList} />
  );
};

export default NoteScreen;
