import * as React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import searchImg from './images/search.svg';
import searchHoverImg from './images/search-hover.svg';
import bookmarkImg from './images/bookmark.svg';
import bookmarkHoverImg from './images/bookmark-hover.svg';
import notificationImg from './images/notification.svg';
import notificationHoverImg from './images/notification-hover.svg';
import { useAppSelector } from '../../../hooks/store';
import { getLocalizedName } from '../../../util/i18n';
import Menu from './Menu';
import SearchInput from './SearchInput';
import UserThumbnail from '../../common/UserThumbnail';
import useGetMyProfileAPI from '../../../hooks/api/profile/useGetMyProfileAPI';

export const HEADER_BAR_HEIGHT = 88;

const Container = styled.div`
  height: ${HEADER_BAR_HEIGHT}px;
  display: flex;
  align-items: center;
  background: #F0F0F0;
`;

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  width: 64px;
  height: 24px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0 20px;
  &::after {
    position: absolute;
    content: '';
    width: 1px;
    height: 12px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    background: #1B262C;
    opacity: 0.3;
  }
`;

const SearchNavLink = styled(StyledNavLink)`
  background-image: url(${searchImg});
  &:hover, &.active {
    background-image: url(${searchHoverImg});
  }
`;

const BookmarkNavLink = styled(StyledNavLink)`
  background-image: url(${bookmarkImg});
  &:hover, &.active {
    background-image: url(${bookmarkHoverImg});
  }
`;

const NotificationNavLink = styled(StyledNavLink)`
  background-image: url(${notificationImg});
  &:hover, &.active {
    background-image: url(${notificationHoverImg});
  }
`;

const UserContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
`;

const UserButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Username = styled.div`
  margin-right: 12px;
`;

const StyledMenu = styled(Menu)`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
`;

type Props = {
  className?: string;
}

const HeaderBar: React.FC<Props> = ({ className }) => {
  const { firstName, lastName } = useAppSelector((state) => state.me);
  const [menuExpanded, setMenuExpanded] = React.useState(false);
  const { data: profile } = useGetMyProfileAPI();

  return (
    <Container className={className}>
      <StyledSearchInput />
      <SearchNavLink
        to={(location) => {
          if (location.pathname === '/search') return location;
          return '/search';
        }}
      />
      <BookmarkNavLink to="/saved" />
      <NotificationNavLink to="/notifications" />
      <UserContainer>
        <UserButton onClick={() => setMenuExpanded((expanded) => !expanded)}>
          <Username>{getLocalizedName(firstName, lastName)}</Username>
          <UserThumbnail url={profile?.photo_url} />
        </UserButton>
        { menuExpanded && <StyledMenu onClose={() => setMenuExpanded(false)} profilePhotoUrl={profile?.photo_url} /> }
      </UserContainer>
    </Container>
  );
};

HeaderBar.propTypes = {
  className: PropTypes.string.isRequired,
};

export default HeaderBar;
