import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckboxDropDownMenu, { CheckboxDropDownMenuItem } from '../common/CheckboxDropDownMenu';

interface Props {
  values: any[];
  // eslint-disable-next-line no-unused-vars
  onChange: (newValue: any) => any;
  className?: string;
}

const LifeCycleStagesFilter: React.FC<Props> = ({
  values,
  onChange,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <CheckboxDropDownMenu
      className={className}
      name={t('common.lifecycle')}
      values={values}
      onChange={onChange}
    >
      <CheckboxDropDownMenuItem value="approach" name={t('common.lifecycle_stages.approach')} />
      <CheckboxDropDownMenuItem value="lead" name={t('common.lifecycle_stages.lead')} />
      <CheckboxDropDownMenuItem value="pre_proposal" name={t('common.lifecycle_stages.pre_proposal')} />
      <CheckboxDropDownMenuItem value="proposal" name={t('common.lifecycle_stages.proposal')} />
      <CheckboxDropDownMenuItem value="contract" name={t('common.lifecycle_stages.contract')} />
      <CheckboxDropDownMenuItem value="complete" name={t('common.lifecycle_stages.complete')} />
      <CheckboxDropDownMenuItem value="postpone" name={t('common.lifecycle_stages.postpone')} />
      <CheckboxDropDownMenuItem value="cancel" name={t('common.lifecycle_stages.cancel')} />
    </CheckboxDropDownMenu>
  );
};

LifeCycleStagesFilter.defaultProps = {
  className: null,
};

LifeCycleStagesFilter.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default LifeCycleStagesFilter;
