/* eslint-disable camelcase */
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../util/api';

type PostData = {
  base: 'USD' | 'JPY';
  target: 'USD' | 'JPY';
  base_amount: number;
  target_amount: number;
};

const useCreateExchangeRateAlertAPI = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: PostData) => {
      return api.post('/exchange_rates/alerts', data);
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries('exchange_rates/alerts');
      },
    },
  );

  return mutation;
};

export default useCreateExchangeRateAlertAPI;
