import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '../common/Card';
import useGetCallingNoteTaskStatusAPI from '../../hooks/api/calling-notes/useGetCallingNoteTaskStatusAPI';
import TaskStatusPieChart from './TaskStatusPieChart';
import Loading from '../common/status/Loading';
import NoData from '../common/status/NoData';
import GeneralError from '../common/status/GeneralError';
import doneImg from '../../images/done.svg';
import notDoneImg from '../../images/not-done.svg';

const Container = styled(Card)``;

const Title = styled.div`
  height: 32px;
  font-weight: bold;
  font-size: 15px;
  color: #1B262C;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const List = styled.div`
  padding: 12px 10px;
`;

const ListItemContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const ListItemName = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #1B262C;
`;

const ListItemValue = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #1B262C;
`;

const ListItemIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 4px;
`;

type ListItemType = {
  name: string;
  value: number;
  iconSrc: string;
}

const ListItem: React.FC<ListItemType> = ({ name, value, iconSrc }) => {
  return (
    <ListItemContainer>
      <ListItemName>{name}</ListItemName>
      <ListItemValue>{`${Math.round(value * 100)}%`}</ListItemValue>
      <ListItemIcon src={iconSrc} role="presentation" />
    </ListItemContainer>
  );
};

ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  iconSrc: PropTypes.string.isRequired,
};

const TaskStatusWidget: React.FC = () => {
  const { t } = useTranslation();
  const { data, status, refetch } = useGetCallingNoteTaskStatusAPI();

  let body;
  if (status === 'loading') {
    body = <Loading />;
  } else if (status === 'success' && (!data || !data.item)) {
    body = <NoData />;
  } else if (status === 'error') {
    body = <GeneralError onButtonClick={() => refetch()} />;
  } else {
    const tasks = [{ name: 'done', value: data.item.done }, { name: 'not_done', value: data.item.not_done }];
    body = (
      <>
        <TaskStatusPieChart data={tasks} />
        <List>
          <ListItem name={t('home.task_status.not_done')} value={data.item.not_done} iconSrc={notDoneImg} />
          <ListItem name={t('home.task_status.done')} value={data.item.done} iconSrc={doneImg} />
        </List>
      </>
    );
  }

  return (
    <Container>
      <Title>
        {t('home.task_status.title')}
      </Title>
      {body}
    </Container>
  );
};

export default TaskStatusWidget;
