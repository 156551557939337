/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import type { AppThunk, RootState } from '../store';
import { update as updateMe } from './meSlice';
import { update as updateEnterpriseAuth } from './enterpriseAuthSlice';
import { isTokenExpired, loadAuthFromLocalStorage, saveAuthToLocalStorage } from '../util/authUtil';
import api from '../util/api';

export interface AuthState {
  accessToken: string;
  refreshToken: string;
  email: string;
  status: string;
}

export const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  email: null,
  status: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuth: (state, action: PayloadAction<AuthState>) => {
      const {
        email, status, accessToken, refreshToken,
      } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.email = email;
      state.status = status;
    },
  },
});

export const { updateAuth } = slice.actions;

// TODO: error handling
export const login = (data: AuthState): AppThunk => async (dispatch) => {
  if (isTokenExpired(data.accessToken) || isTokenExpired(data.refreshToken)) return;

  dispatch(updateAuth(data));
  saveAuthToLocalStorage(data);
  api.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;

  const profile = await api.get('/profile/me');
  const profileBody = profile.data;

  dispatch(updateMe({
    firstName: profileBody.first_name,
    lastName: profileBody.last_name,
    username: profileBody.username,
    email: profileBody.reg_email,
  }));

  Sentry.setUser({
    username: profileBody.username,
    email: profileBody.reg_email,
  });

  const enterprise = await api.get('/profile/enterprise_auth');
  const enterpriseBody = enterprise.data;

  dispatch(updateEnterpriseAuth({
    name: enterpriseBody.ent_name,
    uid: enterpriseBody.ent_uid,
    status: enterpriseBody.auth_status,
  }));
};

export const logout = (): AppThunk => () => {
  window.localStorage.clear();
  window.location.href = '/login';
};

export const refreshAccessToken = (refreshToken: string): AppThunk<Promise<string>> => async (dispatch) => {
  if (isTokenExpired(refreshToken)) return null;

  try {
    const res = await api.post('/refresh_token', {}, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });

    return res.data.access_token;
  } catch (err) {
    if (err?.response?.status !== 401) {
      // Unexpected error
      Sentry.captureException(err);
    }
    dispatch(logout());
    return null;
  }
};

export const restoreAndRefreshAuthState = (): AppThunk => async (dispatch) => {
  const auth = loadAuthFromLocalStorage();
  if (!isTokenExpired(auth.refreshToken)) {
    const newAccessToken = await dispatch(refreshAccessToken(auth.refreshToken));
    if (newAccessToken) {
      await dispatch(login({ ...auth, accessToken: newAccessToken }));
    }
  }
};

export const getAccessToken = (state: RootState) => state.auth.accessToken;
export const getLogin = (state: RootState) => !isTokenExpired(getAccessToken(state));

export default slice.reducer;
