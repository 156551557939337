import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { Corp } from '../../../dataTypes';

const useGetFavoriteCorpsAPI = () => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q = QueryString.stringify({
      per_page: 10,
      page: pageParam,
    });

    const res = await api.get(`/favorites/corps?${q}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<Corp>>(
    'favorites/corps',
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
    },
  );
};

export default useGetFavoriteCorpsAPI;
