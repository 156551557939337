import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';

type Params = {
  children: React.ReactNode;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => any;
  // eslint-disable-next-line no-unused-vars
  renderActiveItem: (value: string, name: string) => React.ReactNode;
  className?: string;
  menuAlignLeft?: boolean;
  menuAlignRight?: boolean;
}

const Container = styled.div`
  position: relative;
  height: 28px;
  display: flex;
  border-radius: 8px;
`;

interface MenuProps {
  alignLeft: boolean;
  alignRight: boolean;
}

const Menu = styled.div<MenuProps>`
  position: absolute;
  ${(props) => props.alignLeft && 'left: 0;'}
  ${(props) => props.alignRight && 'right: 0;'}
  top: calc(100% + 8px);
  padding: 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  z-index: 1;
`;

interface ItemProps {
  isActive: boolean;
}

const MenuItem = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 60px;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    background: rgba(1, 170, 193, 0.12);
  }

  ${(props) => props.isActive && 'background: rgba(1, 170, 193, 0.12);'}
`;

type ItemParams = {
  children: React.ReactNode;
  value: string;
  name: string;
}

export const DropDownMenuItem: React.FC<ItemParams> = () => {
  return null;
};

const DropDownMenu: React.FC<Params> = ({
  children,
  value,
  onChange,
  renderActiveItem,
  className,
  menuAlignLeft,
  menuAlignRight,
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const rootElemRef = useClickOutsideHandler(() => setIsMenuOpen(false));

  return (
    <Container
      ref={rootElemRef}
      onClick={() => setIsMenuOpen((open) => !open)}
      className={className}
    >
      {
        React.Children.map(children, (child: any) => {
          if (child.props.value !== value) return null;
          return renderActiveItem(child.props.value, child.props.name);
        })
      }

      {
        isMenuOpen && (
          <Menu
            alignLeft={menuAlignLeft}
            alignRight={menuAlignRight}
          >
            {
              React.Children.map(children, (child: any) => {
                return (
                  <MenuItem
                    onClick={() => {
                      onChange(child.props.value);
                    }}
                    isActive={value === child.props.value}
                  >
                    {child.props.children}
                  </MenuItem>
                );
              })
            }
          </Menu>
        )
      }
    </Container>
  );
};

DropDownMenu.defaultProps = {
  className: null,
  menuAlignLeft: true,
  menuAlignRight: false,
};

DropDownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderActiveItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  menuAlignLeft: PropTypes.bool,
  menuAlignRight: PropTypes.bool,
};

export default DropDownMenu;
