import * as React from 'react';
import styled from 'styled-components';

import useLoginRequired from '../../hooks/auth/useLoginRequired';
import HeaderLayout from '../layouts/HeaderLayout';
import CompanyList from './CompanyList';
import LeadList from './LeadList';

const CompanyScreen: React.FC = () => {
  useLoginRequired();

  return (
    <HeaderLayout
      mainComponent={CompanyList}
      sideComponent={LeadList}
    />
  );
};

export default CompanyScreen;
