import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { Client } from '../../../dataTypes';

export type FilterType = 'my' | 'all' | 'ai';
export type RankByType = 'company_name' | 'last_touch_dt' | 'ai'

export type Query = {
  filter?: FilterType,
  rankBy?: RankByType,
}

const useGetClientsAPI = (query: Query) => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q = QueryString.stringify({
      filter: query.filter,
      rankby: query.rankBy,
      per_page: 10,
      page: pageParam,
    });

    const res = await api.get(`/clients?${q}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<Client>>(
    ['clients', query.filter, query.rankBy],
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
    },
  );
};

export default useGetClientsAPI;
