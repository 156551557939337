/* eslint-disable camelcase */
import { useMutation } from 'react-query';
import api from '../../../util/api';

type PostData = {
  email: string;
};

const useResetPasswordAPI = () => {
  const mutation = useMutation(
    (data: PostData) => {
      return api.post('/reset_password', data);
    },
  );

  return mutation;
};

export default useResetPasswordAPI;
