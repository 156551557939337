import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: inline-flex;
  height: 28px;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  padding: 0 20px;
  color: #1B262C;
  opacity: 0.6;
  cursor: pointer;
  position: relative;

  &.active {
    color: #00649F;
    opacity: 1;

    &::after {
      position: absolute;
      content: '';
      top: calc(100% + 4px);
      left: 0;
      right: 0;
      height: 4px;
      background: #01AAC1;
      border-radius: 4px 4px 0px 0px;
    }
  }
`;

interface ITabItem {
  children: React.ReactNode;
  src: string;
}

const TabItem: React.FC<ITabItem> = ({ children, src }) => {
  return (
    <StyledNavLink
      to={(location) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('src', src);
        return { ...location, search: searchParams.toString() };
      }}
      isActive={(match, location) => {
        if (!match) return false;
        const searchParams = new URLSearchParams(location.search);
        return (!searchParams.get('src') && src === 'all') || (src === searchParams.get('src'));
      }}
    >
      {children}
    </StyledNavLink>
  );
};

TabItem.propTypes = {
  children: PropTypes.node.isRequired,
  src: PropTypes.string.isRequired,
};

const TabBar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <TabItem src="all">{t('common.all')}</TabItem>
      <TabItem src="client">{t('common.companies')}</TabItem>
      <TabItem src="news">{t('common.insight')}</TabItem>
      <TabItem src="contact">{t('common.contacts')}</TabItem>
      <TabItem src="calling_note">{t('common.notes')}</TabItem>
    </Container>
  );
};

TabBar.defaultProps = {};

TabBar.propTypes = {};

export default TabBar;
