import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { fromNow } from '../../util/datetime';
import InsightModal from './InsightModal';
import { Insight, InsightPropTypes } from '../../dataTypes';
import SaveButton from '../common/SaveButton';
import useClickNewsAPI from '../../hooks/api/insight/useClickNewsAPI';
import clockImg from '../../images/clock.svg';
import HoverCard from '../common/HoverCard';
import { getLineClampStyles } from '../../util/styles';
import useToggleFavoriteNewsAPI from '../../hooks/api/favorites/useToggleFavoriteNewsAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import NonBlurryImage from '../common/NonBlurryImage';

type Params = {
  className?: string;
  insight: Insight;
  showDescription?: boolean;
}

const Container = styled(HoverCard)`
  padding: 12px 20px;
  cursor: pointer;
`;

const Header = styled.div`
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  color: #1B262C;
  opacity: 0.6;
  margin-bottom: 8px;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
`;

const COVER_IMAGE_SIDE_LENGTH = 88;

const ThumbnailImg = styled(NonBlurryImage)`
  width: ${COVER_IMAGE_SIDE_LENGTH}px;
  height: ${COVER_IMAGE_SIDE_LENGTH}px;
  border-radius: 8px;
  margin-right: 8px;
  object-fit: cover;
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  margin: 0 0 4px;
  ${getLineClampStyles(2)}
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
  opacity: 0.6;
  margin: 0 0 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Footer = styled.div`
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TimestampContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ClockImg = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${clockImg});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 4px;
`;

const Timestamp = styled.div`
  color: #1B262C;
  opacity: 0.6;
`;

const InsightCard: React.FC<Params> = ({
  className,
  insight,
  showDescription,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {
    category,
    img_url: imgUrl,
    is_saved: isSaved,
    provider,
    published_dt: publishedDt,
    summary,
    title,
    uid,
  } = insight;

  const { mutateAsync: toggleFavoriteNewsAPI } = useToggleFavoriteNewsAPI(uid, !insight.is_saved);
  const { enqueueGeneralErrorSnackbar } = useGeneralSnackbar();
  const clickNews = useClickNewsAPI();

  const onSaveButtonClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    try {
      await toggleFavoriteNewsAPI();
    } catch (err) {
      enqueueGeneralErrorSnackbar();
      throw err;
    }
  };

  const onCardClick = () => {
    clickNews(insight);
    setIsModalOpen(true);
  };

  return (
    <>
      <Container onClick={onCardClick} className={className}>
        <Header>
          {provider}
          ・
          {t(`insight.topics.${category}`)}
        </Header>
        <Body>
          <ThumbnailImg
            src={imgUrl}
            alt={title}
            minNaturalWidth={COVER_IMAGE_SIDE_LENGTH}
            minNaturalHeight={COVER_IMAGE_SIDE_LENGTH}
          />
          <TextContainer>
            <Title>{title}</Title>
            { showDescription && <Description>{summary}</Description> }
          </TextContainer>
        </Body>
        <Footer>
          <TimestampContainer>
            <ClockImg />
            <Timestamp>{fromNow(publishedDt, t)}</Timestamp>
          </TimestampContainer>
          <SaveButton isSaved={isSaved} onClick={onSaveButtonClick} />
        </Footer>
      </Container>
      <InsightModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        insight={insight}
        onSaveButtonClick={onSaveButtonClick}
      />
    </>
  );
};

InsightCard.defaultProps = {
  className: null,
  showDescription: true,
};

InsightCard.propTypes = {
  className: PropTypes.string,
  insight: InsightPropTypes.isRequired,
  showDescription: PropTypes.bool,
};

export default InsightCard;
