import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useLoginRequired from '../../hooks/auth/useLoginRequired';
import HeaderLayout from '../layouts/HeaderLayout';
import ProfileHeader from './ProfileHeader';
import editImg from './images/edit.svg';
import { getCardStyles } from '../../util/styles';
import settingsImg from './images/settings.svg';
import useGetMyProfileAPI from '../../hooks/api/profile/useGetMyProfileAPI';
import ProfileCard from './ProfileCard';
import Loading from '../common/status/Loading';
import GeneralError from '../common/status/GeneralError';
import Card from '../common/Card';
import companyImg from './images/company.svg';
import EditProfileModal from './EditProfileModal';

const Container = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonImg = styled.div`
  width: 18px;
  height: 18px;
  background: center/contain no-repeat url(${editImg});
  margin-right: 4px;
`;

const SettingsImg = styled(ButtonImg)`
  background: center/contain no-repeat url(${settingsImg});
`;

const EditImg = styled(ButtonImg)`
  background: center/contain no-repeat url(${editImg});
`;

const Button = styled.button`
  ${getCardStyles()}
  height: 28px;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
`;

interface IButton {
  onClick: () => void;
}

const SettingsButton = () => {
  const { t } = useTranslation();
  return (
    <Button>
      <SettingsImg />
      {t('common.settings')}
    </Button>
  );
};

const EditButton: React.FC<IButton> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick}>
      <EditImg />
      {t('profile.edit_profile')}
    </Button>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const Title = styled.div`
  font-size: 18px;
  color: #1B262C;
`;

const CompanyCard = styled(Card)`
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
  padding: 0 12px;
`;

const CompanyIcon = styled.div`
  width: 28px;
  height: 28px;
  background: center/contain no-repeat url(${companyImg});
  margin-right: 6px;
`;

const ProfileScreen: React.FC = () => {
  useLoginRequired();
  const { t } = useTranslation();
  const { data: profile, status, refetch } = useGetMyProfileAPI();
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  if (status === 'loading') {
    return (
      <HeaderLayout>
        <Loading />
      </HeaderLayout>
    );
  }

  if (status === 'error') {
    return (
      <HeaderLayout>
        <GeneralError onButtonClick={() => refetch()} />
      </HeaderLayout>
    );
  }

  return (
    <>
      <HeaderLayout>
        <Container>
          <ProfileHeader thumbnailUrl={profile?.photo_url || profile?.bizcard_front_url || profile?.bizcard_back_url} />
          <ButtonContainer>
            {/* <SettingsButton /> */}
            <EditButton onClick={() => setIsEditModalOpen(true)} />
          </ButtonContainer>

          <ProfileCard profile={profile} />

          { profile?.company_name && (
            <>
              <Title>
                {t('profile.company_account')}
              </Title>
              <CompanyCard>
                <CompanyIcon />
                {profile.company_name}
              </CompanyCard>
            </>
          )}
        </Container>
      </HeaderLayout>
      <EditProfileModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        profile={profile}
      />
    </>
  );
};

export default ProfileScreen;
