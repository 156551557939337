import {
  initialState, AuthState, login, refreshAccessToken,
} from '../slices/authSlice';
import { store } from '../store';

const KEY_AUTH = 'auth';

export const loadAuthFromLocalStorage = (): AuthState => {
  try {
    const auth = window.localStorage.getItem(KEY_AUTH);
    if (!auth) return initialState;

    return JSON.parse(auth);
  } catch (e) {
    return initialState;
  }
};

export const saveAuthToLocalStorage = (data: AuthState) => {
  window.localStorage.setItem(KEY_AUTH, JSON.stringify(data));
};

const decodeToken = (token: string) => {
  try {
    const uri = token.split('.')[1];
    const b64 = decodeURIComponent(
      atob(uri)
        .split('')
        .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
        .join(''),
    );
    const jwt = JSON.parse(b64);
    return jwt;
  } catch (e) {
    return null;
  }
};

export const isTokenExpired = (token: string) => {
  if (!token) {
    return true;
  }

  const jwt = decodeToken(token);
  if (!jwt) {
    return true;
  }

  return (Date.now() / 1000 > jwt.exp);
};
