/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import * as React from 'react';
import * as PropTypes from 'prop-types';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormModal from '../common/form-modal/FormModal';
import SubTitle from '../common/form-modal/SubTitle';
import Field from '../common/form-modal/Field';
import Label from '../common/form-modal/Label';
import Textarea from '../common/form-modal/Textarea';
import Input from '../common/form-modal/Input';
import Select from '../common/form-modal/Select';
import AutoCompleteCompanyInput from '../common/form-modal/AutoCompleteCompanyInput';
import { CompanySuggestion, Note, NotePropTypes } from '../../dataTypes';
import ErrorMessage from '../common/form-modal/ErrorMessage';
import AutoCompleteContactInput from '../common/form-modal/AutoCompleteContactInput';
import { getLocalizedName } from '../../util/i18n';
import useCreateCallingNoteAPI from '../../hooks/api/calling-notes/useCreateCallingNoteAPI';
import { toDatetimeLocalFormat } from '../../util/datetime';
import useUpdateCallingNoteAPI from '../../hooks/api/calling-notes/useUpdateCallingNoteAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';

interface IFormValues {
  summary: string;
  tags_text?: string;
  activity_type: 'call' | 'email' | 'meeting' | 'others';
  due_dt: string;
  contact_uid?: string;
  is_public: string;
  client_uid: string;
  task_content?: string;
  task_due_dt?: string;
  task_is_done: string;
}

interface IProps {
  isOpen: boolean;
  onRequestClose: () => any;
  note?: Note;
}

const AddOrEditNoteModal: React.FC<IProps> = ({
  isOpen,
  onRequestClose,
  note,
}) => {
  const { t } = useTranslation();

  const [companySearchInput, setCompanySearchInput] = React.useState(note?.client_name || '');
  const [contactSearchInput, setContactSearchInput] = React.useState(note ? getLocalizedName(note.contact_first_name, note.contact_last_name) : '');

  const {
    register, handleSubmit, formState: { errors, isSubmitSuccessful }, reset, setValue, clearErrors, watch,
  } = useForm<IFormValues>();
  const watchClientUid = watch('client_uid');
  const watchContactUid = watch('contact_uid');

  // Reset form after note changes or closing modal
  React.useEffect(() => {
    if (!isOpen) {
      reset({
        activity_type: note?.activity_type,
        client_uid: note?.client_uid,
        contact_uid: note?.contact_uid,
        due_dt: toDatetimeLocalFormat(note?.due_dt),
        is_public: note?.is_public ? 'true' : 'false',
        summary: note?.summary,
        tags_text: note?.tags ? note.tags.join(' ') : '',
        task_content: note?.task_content,
        task_due_dt: toDatetimeLocalFormat(note?.task_due_dt),
        task_is_done: note?.task_is_done ? 'true' : 'false',
      });
      setCompanySearchInput(note?.client_name || '');
      setContactSearchInput(getLocalizedName(note?.contact_first_name, note?.contact_last_name) || '');
    }
  }, [note, reset, isOpen]);

  // Reset form after adding contact
  React.useEffect(() => {
    if (isSubmitSuccessful && !note) {
      reset();
      setCompanySearchInput('');
      setContactSearchInput('');
      setValue('client_uid', null);
      setValue('contact_uid', null);
    }
  }, [isSubmitSuccessful, note, reset, setValue]);

  const { mutateAsync: createCallingNoteAPI } = useCreateCallingNoteAPI();
  const { mutateAsync: updateCallingNoteAPI } = useUpdateCallingNoteAPI(note?.uid);

  const { enqueueGeneralSuccessSnackbar, enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    const toISOString = (localDateTime: string) => {
      const date = new Date(localDateTime);
      return date.toISOString();
    };

    const data = {
      ...formData,
      is_public: formData.is_public === 'true',
      task_is_done: formData.task_is_done === 'true',
      due_dt: toISOString(formData.due_dt),
    };

    if (formData.task_due_dt) {
      data.task_due_dt = toISOString(formData.task_due_dt);
    }

    try {
      if (note?.uid) {
        await updateCallingNoteAPI(data);
      } else {
        await createCallingNoteAPI(data);
      }

      onRequestClose();
      enqueueGeneralSuccessSnackbar();
    } catch (err) {
      switch (err?.response?.data?.code) {
        default:
          enqueueGeneralErrorSnackbar();
          throw err;
      }
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      title={note ? t('notes.modal.edit_title') : t('notes.modal.add_title')}
    >
      <SubTitle>{t('common.calling_note')}</SubTitle>

      <Field>
        <Label required>{t('common.notes')}</Label>
        <Textarea {...register('summary', { required: true })} maxLength={500} />
        { errors?.summary?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      <Field>
        <Label>{t('common.tags')}</Label>
        <Input type="text" {...register('tags_text')} maxLength={100} />
      </Field>

      <SubTitle>{t('notes.modal.note_info')}</SubTitle>
      <Field>
        <Label required>{t('common.type')}</Label>
        <Select<IFormValues> label="activity_type" register={register}>
          <option value="call">{t('notes.activity_type.call')}</option>
          <option value="email">{t('notes.activity_type.email')}</option>
          <option value="meeting">{t('notes.activity_type.meeting')}</option>
          <option value="others">{t('notes.activity_type.others')}</option>
        </Select>
      </Field>

      <Field>
        <Label required>
          {t('notes.modal.contact_date_time')}
        </Label>
        <Input type="datetime-local" {...register('due_dt', { required: true })} />
        { errors?.due_dt?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      <Field>
        <Label required>
          {t('notes.modal.associated_company')}
        </Label>
        <AutoCompleteCompanyInput
          src="contact"
          searchInput={companySearchInput}
          onInputChange={(e) => {
            setCompanySearchInput(e.target.value);
            setValue('client_uid', null);
            setValue('contact_uid', null);
          }}
          onSelect={(selected: CompanySuggestion) => {
            setCompanySearchInput(selected?.name || '');
            setValue('client_uid', selected?.client_uid);
            clearErrors('client_uid');
          }}
          onDismiss={() => {
            if (!watchClientUid) {
              setCompanySearchInput('');
              setContactSearchInput('');
            }
          }}
        />
        <Input type="hidden" {...register('client_uid', { required: true })} />
        { errors?.client_uid?.type === 'required' && <ErrorMessage>{t('common.required')}</ErrorMessage>}
      </Field>

      <Field>
        <Label>
          {t('notes.modal.associated_contact')}
        </Label>
        <AutoCompleteContactInput
          clientUid={watchClientUid}
          searchInput={contactSearchInput}
          onInputChange={(e) => {
            setContactSearchInput(e.target.value);
            setValue('contact_uid', null);
          }}
          onSelect={(selected) => {
            setContactSearchInput(selected ? getLocalizedName(selected.contact_first_name, selected.contact_last_name) : '');
            setValue('contact_uid', selected?.contact_uid);
            clearErrors('contact_uid');
          }}
          onDismiss={() => {
            if (!watchContactUid) {
              setContactSearchInput('');
            }
          }}
          disabled={!watchClientUid}
        />
        <Input type="hidden" {...register('contact_uid')} />
      </Field>

      <SubTitle>
        {t('notes.modal.to_do_task')}
      </SubTitle>

      <Field>
        <Label>{t('common.task')}</Label>
        <Textarea {...register('task_content')} maxLength={500} />
      </Field>

      <Field>
        <Label>
          {t('notes.modal.task_due')}
        </Label>
        <Input type="datetime-local" {...register('task_due_dt')} />
      </Field>

      <SubTitle>
        {t('notes.modal.share_settings')}
      </SubTitle>
      <Field>
        <Label required>
          {t('notes.modal.who_can_see')}
        </Label>
        <Select<IFormValues> label="is_public" register={register}>
          <option value="true">
            {t('notes.modal.all_colleagues')}
          </option>
          <option value="false">{t('common.private')}</option>
        </Select>
      </Field>

      <Input type="hidden" {...register('task_is_done')} />
    </FormModal>
  );
};

AddOrEditNoteModal.defaultProps = {
  note: null,
};

AddOrEditNoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  note: NotePropTypes,
};

export default AddOrEditNoteModal;
