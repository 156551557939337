import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import welcomeImg from './images/welcome.svg';
import { useAppSelector } from '../../hooks/store';
import { getLocalizedName } from '../../util/i18n';

const Container = styled.div`
  position: relative;
  height: 228px;
  background: #FFFFFF;
  box-shadow: 0px 3px 5px rgba(0, 100, 159, 0.08);
  border-radius: 10px;
  padding: 16px 20px;
`;

const BannerImg = styled.div`
  position: absolute;
  width: 317px;
  height: 210.93px;
  top: 19.64px;
  right: 14.52px;
  background: center/contain no-repeat url(${welcomeImg});
`;

const Title = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 44px;
  color: #000000;
  position: relative;
  z-index: 1;
`;

const Name = styled.span`
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  color: #000000;
`;

interface IProps {
  className?: string;
}

const WelcomeCard: React.FC<IProps> = ({
  className,
}) => {
  const { firstName, lastName } = useAppSelector((state) => state.me);
  const fullName = getLocalizedName(firstName, lastName);

  return (
    <Container className={className}>
      <BannerImg />
      <Title>
        <Trans i18nKey="home.welcome_back">
          Welcome Back,
          <br />
          <Name>{{ fullName }}</Name>
        </Trans>
      </Title>
    </Container>
  );
};

WelcomeCard.defaultProps = {
  className: null,
};

WelcomeCard.propTypes = {
  className: PropTypes.string,
};

export default WelcomeCard;
