import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { Note } from '../../../dataTypes';

export type FilterType = 'my' | 'all' | 'ai';
export type RankByType = 'create_dt_asc' | 'create_dt_dsc';

interface Query {
  filter: FilterType;
  rankBy: RankByType;
  client?: string;
  contact? : string;
}

interface Options {
  enabled: boolean;
}

const useGetCallingNotesAPI = (query: Query, options?: Options) => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q: any = {
      filter: query.filter,
      rankby: query.rankBy,
      per_page: 10,
      page: pageParam,
    };

    if (query.client) {
      q.client = query.client;
    }

    if (query.contact) {
      q.contact = query.contact;
    }

    const queryStr = QueryString.stringify(q);

    const res = await api.get(`/calling_notes?${queryStr}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<Note>>(
    ['calling_notes', query.filter, query.rankBy, query.client, query.contact],
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
      enabled: options?.enabled,
    },
  );
};

export default useGetCallingNotesAPI;
