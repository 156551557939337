import * as React from 'react';
import useGetClientAPI from '../../hooks/api/clients/useGetClientAPI';
import useGetContactAPI from '../../hooks/api/contacts/useGetContactAPI';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import {
  getCompanyModal, getContactModal, updateCompanyModal, updateContactModal,
} from '../../slices/modalSlice';
import CompanyModal from '../company/CompanyModal';
import ContactModal from '../contacts/ContactModal';

const ModalRoot: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isOpen: isCompanyModalOpen, uid: companyUid } = useAppSelector(getCompanyModal);
  const { data: company, status: companyStatus } = useGetClientAPI(companyUid, {
    enabled: Boolean(isCompanyModalOpen && companyUid),
    retry: false,
  });

  const { isOpen: isContactModalOpen, uid: contactUid } = useAppSelector(getContactModal);
  const { data: contact, status: contactStatus } = useGetContactAPI(contactUid, {
    enabled: Boolean(isContactModalOpen && contactUid),
    retry: false,
  });

  return (
    <>
      <CompanyModal
        isOpen={isCompanyModalOpen}
        onRequestClose={() => dispatch(updateCompanyModal({ isOpen: false, uid: null }))}
        client={company}
        status={companyStatus}
      />
      <ContactModal
        isOpen={isContactModalOpen}
        onRequestClose={() => dispatch(updateContactModal({ isOpen: false, uid: null }))}
        contact={contact}
        status={contactStatus}
      />
    </>
  );
};

export default ModalRoot;
