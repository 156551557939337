import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      projectId: '96ca856e-11fd-4ab5-ab37-e04e06df41de',
      apiKey: 'a3334e63-d76e-42df-aee2-9d29bcdf961c',
      referenceLng: 'en',
    },
    fallbackLng: 'en', // use en if detected lng is not available
    saveMissing: true,
  });

export default i18n;

export const getLocalizedName = (firstName: string, lastName: string) => {
  if (!firstName && !lastName) return '';
  return `${firstName} ${lastName}`;
};
