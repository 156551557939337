/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type Modal = {
  isOpen: boolean;
  uid?: string;
}

interface ModalState {
  companyModal: Modal;
  contactModal: Modal;
}

const initialModalState: Modal = {
  isOpen: false,
  uid: null,
};

const initialState: ModalState = {
  companyModal: initialModalState,
  contactModal: initialModalState,
};

export const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    updateCompanyModal: (state, action: PayloadAction<Modal>) => {
      const { isOpen, uid } = action.payload;
      state.companyModal.isOpen = isOpen;
      if (typeof uid !== 'undefined') state.companyModal.uid = uid;
    },
    updateContactModal: (state, action: PayloadAction<Modal>) => {
      const { isOpen, uid } = action.payload;
      state.contactModal.isOpen = isOpen;
      if (typeof uid !== 'undefined') state.contactModal.uid = uid;
    },
  },
});

export const { updateCompanyModal, updateContactModal } = slice.actions;

export const getCompanyModal = (state: RootState) => state.modals.companyModal;
export const getContactModal = (state: RootState) => state.modals.contactModal;

export default slice.reducer;
