import { useQuery } from 'react-query';
import * as QueryString from 'query-string';
import api from '../../../util/api';

export type SrcType = 'all' | 'news' | 'client' | 'contact' | 'calling_note' | 'place'

interface Query {
  q: string;
  src: SrcType;
  lat?: number;
  lng?: number;
}

interface Options {
  enabled: boolean;
}

const useSearchAPI = (query: Query, options?: Options) => {
  const queryString = QueryString.stringify(query);

  const fetchFunction = async () => {
    const res = await api.get(`/search?${queryString}`);
    return {
      ...res.data,
    };
  };

  return useQuery(
    ['search', query.q, query.src, query.lat, query.lng],
    fetchFunction,
    { enabled: options?.enabled },
  );
};

export default useSearchAPI;
