import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAppSelector } from '../../hooks/store';
import UserThumbnail from '../common/UserThumbnail';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const Username = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1B262C;
`;

const Email = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #1B262C;
`;

interface IProps {
  className?: string;
  thumbnailUrl?: string;
}

const ProfileHeader: React.FC<IProps> = ({
  className,
  thumbnailUrl,
}) => {
  const { email, username } = useAppSelector((state) => state.me);

  return (
    <HeaderContainer className={className}>
      <UserThumbnail url={thumbnailUrl} />
      <RightColumn>
        <Username>{username}</Username>
        <Email>{email}</Email>
      </RightColumn>
    </HeaderContainer>
  );
};

ProfileHeader.defaultProps = {
  className: null,
  thumbnailUrl: null,
};

ProfileHeader.propTypes = {
  className: PropTypes.string,
  thumbnailUrl: PropTypes.string,
};

export default ProfileHeader;
