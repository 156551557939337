import { useInfiniteQuery } from 'react-query';
import * as QueryString from 'query-string';
import api, { getNextPageParam, getPreviousPageParam, PageData } from '../../../util/api';
import { ContactSuggestion } from '../../../dataTypes';

export type Query = {
  client: string;
  q: string;
}

interface Options {
  enabled: boolean;
}

const useContactsAutoCompleteAPI = (query: Query, options?: Options) => {
  const fetchFunction = async ({ pageParam = 1 }) => {
    const q = QueryString.stringify({
      client: query.client,
      q: query.q,
      per_page: 10,
      page: pageParam,
    });

    const res = await api.get(`/contacts/autocomplete?${q}`);
    return {
      ...res.data,
      pageParam,
    };
  };

  return useInfiniteQuery<PageData<ContactSuggestion>>(
    ['contacts/autocomplete', query.client, query.q],
    fetchFunction,
    {
      getNextPageParam,
      getPreviousPageParam,
      enabled: options?.enabled,
    },
  );
};

export default useContactsAutoCompleteAPI;
