import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DropDownMenu, { DropDownMenuItem } from './DropDownMenu';
import chevronDownImg from './images/chevron-down.svg';
import filterIconImg from './images/filter-icon.svg';
import HoverCard from './HoverCard';

interface Param {
  className?: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => any;
  children: React.ReactNode;
  title?: string;
}

const StyledDropDownMenu = styled(DropDownMenu)`
  display: inline-flex;
  background: #fff;
`;

const ActiveItemContainer = styled(HoverCard)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
`;

const ActiveItemLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActiveItemIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 16px;
  margin-right: 4px;
`;

const Title = styled.div`
  font-size: 12px;
  color: #01AAC1;
  margin-right: 4px;
  white-space: nowrap;
`;

const Name = styled.div`
  font-size: 12px;
  color: #1B262C;
  white-space: nowrap;
`;

const Chevron = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${chevronDownImg});
  background-size: contain;
  margin-left: 8px;
  margin-right: 12px;
`;

const Filter: React.FC<Param> = ({
  className,
  value,
  onChange,
  children,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <StyledDropDownMenu
      className={className}
      value={value}
      onChange={onChange}
      renderActiveItem={(currentValue, name) => (
        <ActiveItemContainer>
          <ActiveItemLeftContainer>
            <ActiveItemIcon src={filterIconImg} role="presentation" />
            <Title>{title || t('common.filter')}</Title>
            <Name>{name}</Name>
          </ActiveItemLeftContainer>
          <Chevron />
        </ActiveItemContainer>
      )}
    >
      {children}
    </StyledDropDownMenu>
  );
};

Filter.defaultProps = {
  className: null,
  title: null,
};

Filter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Filter;

export { DropDownMenuItem as FilterItem };
