import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import checkedImg from './images/checkbox-checked.svg';
import uncheckedImg from './images/checkbox-unchecked.svg';

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  height: 20px;
  cursor: pointer;

  & > input ~ div {
    background: center/contain no-repeat url(${uncheckedImg});
  }

  & > input:checked ~ div {
    background: center/contain no-repeat url(${checkedImg});
  }
`;

const Input = styled.input<{ref: any}>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CustomCheckbox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;

  &:hover {
    opacity: 0.6;
  }
`;

interface IProps {
  children: React.ReactNode;
  className?: string;
  value: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: React.FC<IProps> = React.forwardRef(({
  children,
  className,
  value,
  onChange,
}, ref) => {
  return (
    <Label className={className}>
      <Input
        ref={ref}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <CustomCheckbox />
      { children }
    </Label>
  );
});

Checkbox.defaultProps = {
  className: null,
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
