import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DropDownMenu, { DropDownMenuItem } from './DropDownMenu';
import sortImg from './images/sort.svg';
import chevronGrayImg from './images/chevron-down-gray.svg';

interface IProps {
  className?: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => any;
  children: React.ReactNode;
}

const StyledDropDownMenu = styled(DropDownMenu)`
  display: inline-flex;
`;

const ActiveItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0.6;
  font-size: 12px;
`;

const SortImg = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${sortImg});
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 8px;
`;

const ChevronImg = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${chevronGrayImg});
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 8px;
`;

const RankBy: React.FC<IProps> = ({
  className,
  value,
  onChange,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <StyledDropDownMenu
      className={className}
      value={value}
      onChange={onChange}
      renderActiveItem={() => (
        <ActiveItemContainer>
          <SortImg />
          {t('common.sorting')}
          <ChevronImg />
        </ActiveItemContainer>
      )}
      menuAlignLeft={false}
      menuAlignRight
    >
      {children}
    </StyledDropDownMenu>
  );
};

RankBy.defaultProps = {
  className: null,
};

RankBy.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default RankBy;

export { DropDownMenuItem as RankByItem };
