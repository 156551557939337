import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import TitledModal from '../common/TitledModal';
import { Note, NotePropTypes } from '../../dataTypes';
import curveImg from './images/curve.svg';
import taskDoneImg from './images/task-done.svg';
import taskUndoneImg from './images/task-undone.svg';
import checkedImg from './images/checked.svg';
import clockRedImg from './images/clock-red.svg';
import clockGrayImg from './images/clock-gray.svg';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';
import useUpdateCallingNoteAPI from '../../hooks/api/calling-notes/useUpdateCallingNoteAPI';
import Card from '../common/Card';
import SummaryCard from './SummaryCard';

const StyledCard = styled(Card)`
  padding: 12px 20px;
`;

const StyledSummaryCard = styled(SummaryCard)`
  margin-bottom: 12px;
`;

const TaskContainer = styled.div`
  display: flex;
`;

const Shape = styled.div`
  width: 36px;
  height: 84px;
  margin-left: 32px;
  margin-right: 12px;
  background: center/contain no-repeat url(${curveImg});
`;

interface ITaskProps {
  isDone: boolean;
}

const TaskCard = styled(StyledCard)<ITaskProps>`
  position: relative;
  overflow: hidden;
  flex: 1;

  &::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    background: ${(props) => (props.isDone ? '#BDBDBD' : '#01AAC1')};
  }
`;

const TaskIcon = styled.div<ITaskProps>`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  background: center/contain no-repeat url(${(props) => (props.isDone ? taskDoneImg : taskUndoneImg)});
`;

const CheckedImg = styled.div<ITaskProps>`
  width: 24px;
  height: 24px;
  border: 2px solid #01AAC1;
  border-radius: 50%;
  cursor: pointer;
  ${(props) => props.isDone && `background: center/contain no-repeat url(${checkedImg});`}
`;

const TaskHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const TaskTitle = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
`;

const TaskDueDt = styled.div<ITaskProps>`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 12px;
  color: ${(props) => (props.isDone ? '#828282' : '#EB5757')};
  &::before {
    display: block;
    content: '';
    width: 18px;
    height: 18px;
    margin-right: 4px;
    background: center/contain no-repeat url(${(props) => (props.isDone ? clockGrayImg : clockRedImg)});
  }
`;

const TaskContent = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #1B262C;
  p {
    margin: 0;
  }
`;

interface IProps {
  isOpen: boolean;
  onRequestClose: () => any;
  note: Note;
}

const NoteModal: React.FC<IProps> = ({
  isOpen,
  onRequestClose,
  note,
}) => {
  const { t } = useTranslation();

  const {
    activity_type: activityType,
    client_uid: clientUid,
    contact_uid: contactUid,
    due_dt: dueDt,
    is_public: isPublic,
    tags,
    task_content: taskContent,
    task_due_dt: taskDueDt,
    task_is_done: taskIsDone,
    summary,
    uid,
  } = note;

  const { mutateAsync: updateCallingNoteAPI } = useUpdateCallingNoteAPI(uid);
  const { enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  return (
    <>
      <TitledModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={t('common.notes')}
      >
        <StyledSummaryCard note={note} onDelete={onRequestClose} />

        {
          taskContent && (
            <TaskContainer>
              <Shape />
              <TaskCard isDone={taskIsDone}>
                <TaskHeader>
                  <TaskIcon isDone={taskIsDone} />
                  <TaskTitle>
                    {t('common.tasks')}
                  </TaskTitle>
                  <CheckedImg
                    isDone={taskIsDone}
                    onClick={async () => {
                      try {
                        await updateCallingNoteAPI({
                          summary,
                          tags_text: tags ? tags.join(' ') : '',
                          activity_type: activityType,
                          due_dt: dueDt,
                          contact_uid: contactUid,
                          is_public: isPublic,
                          client_uid: clientUid,
                          task_content: taskContent,
                          task_due_dt: taskDueDt,
                          task_is_done: !taskIsDone,
                        });
                      } catch (err) {
                        enqueueGeneralErrorSnackbar();
                        throw err;
                      }
                    }}
                  />
                </TaskHeader>
                <TaskDueDt isDone={taskIsDone}>{dayjs(taskDueDt).format(t('format.datetime.llll'))}</TaskDueDt>
                <TaskContent>
                  {taskContent.split('\n').map((p) => <p key={p}>{p}</p>)}
                </TaskContent>
              </TaskCard>
            </TaskContainer>
          )
        }
      </TitledModal>
    </>
  );
};

NoteModal.defaultProps = {};

NoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  note: NotePropTypes.isRequired,
};

export default NoteModal;
