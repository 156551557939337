import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HoverCard from '../common/HoverCard';
import BizcardList from '../common/BizcardList';
import chevronImg from './images/chevron.svg';

const Container = styled.div``;

const Button = styled(HoverCard)`
  background: #fff;
  cursor: pointer;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #01AAC1;
  box-shadow: none;
  border: none;
  outline: none;
`;

interface IExpandable {
  isExpanded: boolean;
}

const Panel = styled.div<IExpandable>`
  max-height: ${(props) => (props.isExpanded ? '1000px' : 0)};
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
`;

const ChevronImg = styled.div<IExpandable>`
  width: 24px;
  height: 24px;
  background: center/contain no-repeat url(${chevronImg});
  ${(props) => props.isExpanded && 'transform: rotate(180deg);'}
  transition: transform 0.2s ease-in-out;
`;

interface IProps {
  bizcardFrontUrl?: string;
  bizcardBackUrl?: string;
}

const BizcardAccordion: React.FC<IProps> = ({
  bizcardFrontUrl,
  bizcardBackUrl,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Container>
      <Button onClick={() => setIsExpanded(!isExpanded)}>
        {t('profile.my_business_card')}
        <ChevronImg isExpanded={isExpanded} />
      </Button>
      <Panel isExpanded={isExpanded}>
        { (bizcardFrontUrl || bizcardBackUrl) && (
          <BizcardList bizcardFrontUrl={bizcardFrontUrl} bizcardBackUrl={bizcardBackUrl} />
        )}
      </Panel>
    </Container>
  );
};

BizcardAccordion.defaultProps = {
  bizcardFrontUrl: null,
  bizcardBackUrl: null,
};

BizcardAccordion.propTypes = {
  bizcardFrontUrl: PropTypes.string,
  bizcardBackUrl: PropTypes.string,
};

export default BizcardAccordion;
