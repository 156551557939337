import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Lead, LeadPropTypes } from '../../dataTypes';
import companyThumbImg from './images/company-thumbnail.svg';
import StockPrice from '../common/StockPrice';
import LeadModal from './LeadModal';
import HoverCard from '../common/HoverCard';
import SaveButton from '../common/SaveButton';
import useToggleFavoriteCorpAPI from '../../hooks/api/favorites/useToggleFavoriteCorpAPI';
import useGeneralSnackbar from '../../hooks/useGeneralSnackbar';

type Param = {
  className?: string;
  lead: Lead;
}

const Container = styled(HoverCard)`
  position: relative;
  padding: 12px;
  display: flex;
  cursor: pointer;
  white-space: nowrap;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: contain;
  margin-right: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0; // HACK: to make text overflow works
`;

const Name = styled.h1`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledStockPrice = styled(StockPrice)`
  margin-bottom: 4px;
`;

const IndustryContainer = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #1B262C;
  opacity: 0.6;
`;

const StyledSaveButton = styled(SaveButton)`
  align-self: flex-end;
`;

const LeadCard: React.FC<Param> = ({
  className,
  lead,
}) => {
  const {
    industry,
    is_saved: isSaved,
    logo,
    name,
    price,
    price_change: priceChange,
    price_change_percent: priceChangePercent,
    sec_code: secCode,
    uid,
  } = lead;

  const [isCompanyModalOpen, setIsCompanyModalOpen] = React.useState(false);
  const { mutateAsync: toggleFavoriteCorpAPI } = useToggleFavoriteCorpAPI(uid, !isSaved);
  const { enqueueGeneralErrorSnackbar } = useGeneralSnackbar();

  return (
    <>
      <Container onClick={() => setIsCompanyModalOpen(true)} className={className}>
        <Logo src={logo || companyThumbImg} />
        <TextContainer>
          <Name>{name}</Name>
          { price && (
            <StyledStockPrice
              price={price}
              priceChange={priceChange}
              priceChangePercent={priceChangePercent}
              secCode={secCode}
            />
          )}
          <IndustryContainer>{industry}</IndustryContainer>
        </TextContainer>
        <StyledSaveButton
          isSaved={isSaved}
          onClick={async (e) => {
            e.stopPropagation();
            try {
              await toggleFavoriteCorpAPI();
            } catch (err) {
              enqueueGeneralErrorSnackbar();
              throw err;
            }
          }}
        />
      </Container>
      <LeadModal
        isOpen={isCompanyModalOpen}
        onRequestClose={() => setIsCompanyModalOpen(false)}
        client={lead}
      />
    </>
  );
};

LeadCard.defaultProps = {
  className: null,
};

LeadCard.propTypes = {
  className: PropTypes.string,
  lead: LeadPropTypes.isRequired,
};

export default LeadCard;
