import * as React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Note, NotePropTypes } from '../../dataTypes';
import callThumbnailImg from './images/thumbnail-call.svg';
import emailThumbnailImg from './images/thumbnail-email.svg';
import meetingThumbnailImg from './images/thumbnail-meeting.svg';
import othersThumbnailImg from './images/thumbnail-others.svg';
import lockImg from './images/lock.svg';
import doneImg from './images/done.svg';
import undoneImg from './images/undone.svg';
import { getLocalizedName } from '../../util/i18n';
import NoteModal from './NoteModal';
import HoverCard from '../common/HoverCard';
import CompanyJobTitle from '../common/CompanyJobTitle';
import { getLineClampStyles } from '../../util/styles';

const Container = styled(HoverCard)`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  cursor: pointer;
`;

const Thumbnail = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
`;

const CallThumbnail = styled(Thumbnail)`
  background-image: url(${callThumbnailImg});
`;

const EmailThumbnail = styled(Thumbnail)`
  background-image: url(${emailThumbnailImg});
`;

const MeetingThumbnail = styled(Thumbnail)`
  background-image: url(${meetingThumbnailImg});
`;

const OthersThumbnail = styled(Thumbnail)`
  background-image: url(${othersThumbnailImg});
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1B262C;
  ${getLineClampStyles(2)}
`;

const Private = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 20px;
  color: #01AAC1;
  margin: 0 10px;

  &::before {
    display: block;
    content: url(${lockImg});
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
`;

const ClientCompanyJobTitle = styled(CompanyJobTitle)`
  color: #1B262C;
  opacity: 0.6;
`;

const Done = styled.div`
  width: 20px;
  height: 20px;
  background: center/contain no-repeat url(${doneImg});
`;

const Undone = styled.div`
  width: 20px;
  height: 20px;
  background: center/contain no-repeat url(${undoneImg});
`;

interface IProps {
  className?: string;
  note: Note;
}

const NoteCard: React.FC<IProps> = ({
  className,
  note,
}) => {
  const { t } = useTranslation();
  const [isNoteModalOpen, setIsNoteModalOpen] = React.useState(false);

  const {
    activity_type: activityType,
    client_name: clientName,
    contact_first_name: contactFirstName,
    contact_last_name: contactLastName,
    contact_job_title: contactJobTitle,
    is_public: isPublic,
    task_content: taskContent,
    task_is_done: taskIsDone,
  } = note;

  const contactName = getLocalizedName(contactFirstName, contactLastName);

  return (
    <>
      <Container onClick={() => setIsNoteModalOpen(true)} className={className}>
        {activityType === 'call' && <CallThumbnail />}
        {activityType === 'email' && <EmailThumbnail />}
        {activityType === 'meeting' && <MeetingThumbnail />}
        {activityType === 'others' && <OthersThumbnail />}
        <Body>
          <Row>
            <Name>{contactName || clientName}</Name>
            {!isPublic && <Private>{t('common.private')}</Private>}
          </Row>
          <ClientCompanyJobTitle companyName={clientName} jobTitle={contactJobTitle} />
        </Body>
        {taskContent && (
          taskIsDone ? <Done /> : <Undone />
        )}
      </Container>
      <NoteModal
        isOpen={isNoteModalOpen}
        onRequestClose={() => setIsNoteModalOpen(false)}
        note={note}
      />
    </>
  );
};

NoteCard.defaultProps = {
  className: null,
};

NoteCard.propTypes = {
  className: PropTypes.string,
  note: NotePropTypes.isRequired,
};

export default NoteCard;
